<template>
    <div>
       

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>

    
        <v-row>
            <v-col>
                <table-items v-for="(item, index) in modules" :key="index" :props="item.props" @methodHandler="method_handler" :items="item.submodules">
                    <template v-slot:item.all="props">
                        <div class="table-item">
                            <v-checkbox
                                :key="updateTable"
                                v-if="notIn(props, 'all')"
                                :input-value="checkAllValue(props.item.key, props.item.notIn)"
                                @click="() => {selectAll(props.item.key, props.item.notIn)}"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.create="props" >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn(props, 'create')"
                                @click="update()"
                                v-model="values.permissions[props.item.key].create"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.show="props">
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn(props, 'show')"
                                @click="update()"
                                v-model="values.permissions[props.item.key].show"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.update="props"  >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn(props, 'update')"
                                @click="update()"
                                v-model="values.permissions[props.item.key].update"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.delete="props"  >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn(props, 'delete')"
                                @click="update()"
                                v-model="values.permissions[props.item.key].delete"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.cancel="props"  >
                        <div class="table-item">
                            <!-- <p>{{props.item.key}}</p> -->
                            <v-checkbox
                                v-if="notIn(props, 'cancel')"
                                @click="update()"
                                v-model="values.permissions[props.item.key].cancel"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.update_menu="props" >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn(props, 'update_menu')"
                                @click="update()"
                                v-model="values.permissions[props.item.key].update_menu"
                            ></v-checkbox>
                        </div>
                    </template>
                </table-items>
                
            </v-col>
        </v-row>


    </div>
</template>

<script>
import TableItems from '../../incomes/TableItems.vue';
import modules from './modules';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
    components: {
       'table-items': TableItems
    },
    computed: {
        allItem: function(test){
            console.log(this.values, test);
            
            return true;
        },
       
       
    },
    data(){
        return {
            modules:modules,
            updateTable: 0,
            actions: ['show', 'create', 'update', 'delete', 'cancel', 'update_menu'],
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                 permissions: [
                    (value) =>
                    (value && value.length > 0) || "Debes agregar permisos al rol",
                ],
               
				
				
            },
          
        }
    },
   
    mounted(){
        
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        update()
        {
            this.updateTable = this.updateTable + 1;
        },
        checkAllValue(key, notIn = [])
        {
            // console.log("called")
            let yesAll = true;
            // const actions = ['show', 'create', 'update', 'delete', "copy"];
            for (let i = 0; i < this.actions.length; i++) {
                const action = this.actions[i];
                if(!notIn.includes(action)){
                    if(!this.values.permissions[key][action]){
                        yesAll = false;
                        break;
                    }
                }
            }
            // if(this.values.permissions[key].show &&
            //     this.values.permissions[key].store &&
            //     this.values.permissions[key].edit &&
            //     this.values.permissions[key].delete )
            //     return true;
            
            return yesAll
        },
        selectAll(key, notIn = [])
        {
            // const actions = ['show', 'create', 'update', 'delete', "copy"];
            if (Object.hasOwnProperty.call(this.values.permissions, key)) {
                console.log(this.checkAllValue(key, notIn), key, notIn)
                if (this.checkAllValue(key, notIn)) {

                    for (let i = 0; i < this.actions.length; i++) {
                        const action = this.actions[i];
                        if(!notIn.includes(action)){
                            console.log("applied false to", action)
                            this.values.permissions[key][action] = false;
                        }
                    }
                    
                    // this.values.permissions[key] = {
                    //     show: false,
                    //     create: false,
                    //     update: false,
                    //     delete: false,
                    // };
                } else {
                    for (let i = 0; i < this.actions.length; i++) {
                        const action = this.actions[i];
                        if(!notIn.includes(action)){
                            console.log("applied true to", action)
                            this.values.permissions[key][action] = true;
                        }
                    }
                    // this.values.permissions[key] = {
                    //     show: true,
                    //     create: true,
                    //     update: true,
                    //     delete: true,
                    // };
                }
            } else {
                for (let i = 0; i < this.actions.length; i++) {
                    const action = this.actions[i];
                    if(!notIn.includes(action)){
                        console.log("applied true to else", action)
                        this.values.permissions[key][action] = true;
                    }
                }
                // this.values.permissions[key] = {
                // show: true,
                // create: true,
                // update: true,
                // delete: true,
                // };
            }
            this.updateTable = this.updateTable + 1;
        },

        notIn(props, action)
        {

            // console.log(props)
            if(props.item.notIn.includes(action))
                return false;
            

            return true;
        }
       
    }
}
</script>

<style>

</style>