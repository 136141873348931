<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
                    <div class="custom-btns-container-history">
                        <div class="filters-container-history">

                            <alternativeAutocompleteOne
                                class="filters-container-history-item-1"
                                :items="clients"
                                :valueInput="selectedClient"
                                :loading="clientsLoading"
                            
                                @valueChange="(v) => {selectedClient = v}"
                                @onChange="(v) => {index()}"
                            
                                :label="'Cliente'"
                                classesContainer="width-auto"
                            ></alternativeAutocompleteOne>

                            <alternativeDatePickerRangeVCalendar
                                class="filters-container-history-item-2"
                                :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                                @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                                label="Fechas"
                            ></alternativeDatePickerRangeVCalendar>

                            <!-- <v-col > -->
                                <alternativeAutocompleteOne
                                class="filters-container-history-item-3"
                                    :items="currencies"
                                    :valueInput="selectedCurrency"
                                    @valueChange="(v) => {selectedCurrency = v}"
                                    @onChange="(v) => {index()}"
                                    :label="'Divisa'"
                                    classesContainer="width-auto"
                                ></alternativeAutocompleteOne>
                            <!-- </v-col> -->

                            <!-- <alternativeDateRangePicker
                                class="filters-container-history-item-2"
                                label="Fecha inicio"
                                :valueInput="dateRange.start_date"
                                :rule="[]"
                                @valueChange="(v) => {dateRange.start_date = v; index(); delete serverErrors['date']}"
                                :errorMessages="serverErrors['date']"
                                classesContainer="width-auto"
                            ></alternativeDateRangePicker>
                            
                            <alternativeDateRangePicker
                                 class="filters-container-history-item-3"
                                label="Fecha fin"
                                :valueInput="dateRange.end_date"
                                :rule="[]"
                                @valueChange="(v) => {dateRange.end_date = v; index(); delete serverErrors['date']}"
                                :errorMessages="serverErrors['date']"
                                classesContainer="width-auto"
                            ></alternativeDateRangePicker> -->

                            
                        </div>
                        <div class="btns-container-history" :key="updateBtns">
                            <iconButton  v-permission="'reports_customers_payment_history.show'" :props="pdfBtnData" class="mr-2 mr-md-2 ">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton v-permission="'reports_customers_payment_history.show'" :props="exportBtnData" class=""></iconButton>
                        </div>
                    </div>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            selectedCurrency: PageStateManager.getState(this.$router.history.current.path, 'selectedCurrency')??"MXN",
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
            updateBtns:0,
           
            clientsLoading: false,
            clients: [],
            selectedClient: PageStateManager.getState(this.$router.history.current.path, 'selectedClient')??'',

            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')??new Date().toISOString().split('T')[0]
            },
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Fecha',
						align: 'left',
						value: 'due_date',
						
						class: 'table-b-border black--text'
					},
					{
						text: 'Factura',
						align: 'center',
						value: 'folio',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cuenta',
						align: 'left',
						value: 'concept_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Total',
						align: 'right',
						value: 'total',
                        
						class: 'table-b-border black--text'
					},
                    // {
					// 	text: 'Divisa',
					// 	align: 'left',
                        
					// 	value: 'currency',
					// 	class: 'table-b-border black--text'
					// },
                    {
						text: 'Pagos',
						align: 'right  white-space-pre-always',
                        
						value: 'payments',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'center',
                        
						value: 'status',
						class: 'table-b-border black--text'
					},
                    
				],
				items: [],
				search: '',
				loading: PageStateManager.getState(this.$router.history.current.path, 'selectedClient') ? true : false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				block: false,
                loading: false,
                disabled:true,
                click: ()=>{this.export();}
            },
            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
                disabled:true,
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },
            currencies:[
                {value: "USD", text:"USD"},
                {value: "MXN", text:"MXN"}
            ]
            
			
		}
	},

    watch: {
        [`selectedClient`]() {
            this.watchSelectedClient();
        },
        [`dateRange.start_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
         
        }
    },

	mounted(){
		this.getClients();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.watchSelectedClient();
        this.index();
        if(this.selectedClient != "" && this.selectedClient != null && this.selectedClient != undefined ){
            this.exportBtnData.disabled = false;
            this.pdfBtnData.disabled = false;
        }
	},

    methods: {
        watchSelectedClient()
        {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'selectedClient', this.selectedClient);
            this.exportBtnData.disabled = true;
            this.pdfBtnData.disabled = true;
            console.log( this.dateRange.start_date)
            if(this.selectedClient != "" && this.selectedClient != null && this.selectedClient != undefined ){
                this.exportBtnData.disabled = false;
                this.pdfBtnData.disabled = false;
            }
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
            if(this.selectedClient == '' || this.selectedClient == null || this.dateRange.start_date == '' || this.dateRange.end_date == '' || this.selectedCurrency == null || this.selectedCurrency == '')
                return;

			this.tableProps.loading = true;
          
            this.tableProps.items = [];
			//call api
			this.$api.report.reportPaymentHistory(this.$store.state.business, this.dateRange.start_date, this.dateRange.end_date, this.selectedClient, this.selectedCurrency)
				.then((resp) =>{
					//console.log(resp);
                   
                    
					this.tableProps.items = resp.data.data.map((x) => {
						
						x.total = Utils.currencyFormat(x.total??0, x.currency);
                        x.due_date = Utils.globalDateFormat(x.due_date);

                        let paymentsTexts = '';
                        x.incomes.forEach(pay => {
                            paymentsTexts = paymentsTexts+`${Utils.globalDateFormat(pay.date)} ${Utils.currencyFormat(pay.pivot.amount, "")}\n`;
                        });
                       

                        x.payments = paymentsTexts;
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getClients()
        {
            this.clientsLoading = true;
             this.$api.customer.getSelectCustomers(this.$store.state.business)
                .then((resp) => {
                    this.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.clientsLoading = false;
                })
            ;
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportPaymentHistory(this.$store.state.business, this.dateRange.start_date, this.dateRange.end_date, this.selectedClient, this.selectedCurrency)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `payments_history_${this.selectedClient}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.PdfReportPaymentHistory(this.$store.state.business, this.dateRange.start_date, this.dateRange.end_date, this.selectedClient, this.selectedCurrency)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `payments_history_${this.selectedClient}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

      

    }
}
</script>

<style lang="scss">


    .custom-btns-container-history{
        display: flex;
        width: -webkit-fill-available;
        gap: 10px;
        @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
            flex-wrap: wrap;
            
        }
    }

    .filters-container-history{
        display: flex;
        flex: .65;
        gap: 5px;
        

        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            flex: 1 100%;

        }
         @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            flex-wrap: wrap;

        }

        //.body-1{
        //    font-size: 0.8rem !important;

        //}
        //.v-input__control{
        //    font-size: 0.8rem !important;
        //}

        &-item-1{
            flex: .5;
            @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
                flex: .4;
            }
            @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
                flex: .4;
            }
           
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
        &-item-2{
            flex: .25;

            @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
                flex: .4;
            }
            @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
                flex: .4;
            }
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
        &-item-3{
            flex: .25;
            @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
                flex: .2;
            }
            @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
                flex: .3;
            }
             @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                flex: 1 100%;
            }
        }
      
    }

    .btns-container-history{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: .4;
        text-align: end;
        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            flex: 1 100%;

        }
    }

    .upload-btn{
        margin-top: -2px;
    }
</style>