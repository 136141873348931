<template>
    <div>
       
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Banco"
                    :loading="loadingBanks"
                    :valueInput="values.bank_id"
                    :rule="rules.required"
                    :items="serverItems.banks"
                    :errorMessages="serverErrors.bank_id"
                    @valueChange="(v) => {values.bank_id = v; delete serverErrors.bank_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Divisa"
                    :valueInput="values.currency"
                    :rule="rules.required"
                    :items="items.currencies"
                    :errorMessages="serverErrors.currency"
                    @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.RFC = v; delete serverErrors.RFC}" 
                    :valueInput="values.RFC" 
                    :rule="[...rules.rfc]" 
                    label="RFC"
                    :errorMessages="serverErrors.RFC"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.number = v; delete serverErrors.number}" 
                    :valueInput="values.number" 
                    :rule="[...rules.required]" 
                    label="Número de cuenta"
                    :errorMessages="serverErrors.number"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.CLABE = v; delete serverErrors.CLABE}" 
                    :valueInput="values.CLABE" 
                    :rule="[...rules.required]" 
                    label="Clabe"
                    :errorMessages="serverErrors.CLABE"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.initial_balance = v; delete serverErrors.initial_balance}" 
                    :valueInput="values.initial_balance" 
                    :rule="[...rules.required, ...rules.currencyNumber]" 
                    label="Saldo inicial"
                    :prefix="values.initial_balance == '' ? '' : '$'"
                    :errorMessages="serverErrors.initial_balance"
                ></textFieldForm>
            </v-col>
        </v-row>

       
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingBanks: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                rfc: [
                    (v) => !!v || "El campo requerido",
                    (v) => v.length <= 13 || "Debe de ser entre 12 y 13 caracteres",
                    (v) => v.length >= 12 || "Debe de ser entre 12 y 13 caracteres",
                ],

                currencyNumber: [
					v => /^[0-9]+(\.[0-9]{1,2})?$/.test(v) || 'El precio debe ser numérico'
				],
				
				
            },

            items: {
                currencies: [
                    { text: "MXN", value: "MXN" },
                    { text: "USD", value: "USD" },
                ]
            },

            serverItems:{
                banks:[]
            }
            

          
        }
    },
   
    mounted(){
        this.tryGetBanks();
    },
    methods: {
        tryGetBanks()
        {
            this.loadingBanks = true;
            this.$api.bank.getSelect()
                .then((resp) => {
                    this.serverItems.banks = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBanks = false;
                })
            ;
        }
       
    }
}
</script>

<style>

</style>