<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Agente aduanal"
                    :loading="loadingAgents"
                    :valueInput="values.customs_agent_id"
                    :rule="rules.required"
                    :items="serverItems.agents"
                    :errorMessages="serverErrors.customs_agent_id"
                    @valueChange="(v) => {values.customs_agent_id = v; getAdvances(v, values.customer_id); delete serverErrors.customs_agent_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v;  getAdvances(values.customs_agent_id, v); delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Anticipo a reembolsar"
                    :loading="loadingClients"
                    :valueInput="values.advance_id"
                    :rule="rules.required"
                    :items="serverItems.advances"
                    :errorMessages="serverErrors.advance_id"
                    @valueChange="(v) => {values.advance_id = v; delete serverErrors.advance_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha de pago"
                    :valueInput="values.payment_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.payment_date = v; delete serverErrors['payment_date']}"
                    :errorMessages="serverErrors['payment_date']"
                ></datePickerForm>
            </v-col>
        </v-row>
        

      

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta bancaria"
                    :loading="loadingBankAccounts"
                    :valueInput="values.bank_account_id"
                    :rule="rules.required"
                    :items="serverItems.bankAccounts"
                    :errorMessages="serverErrors.bank_account_id"
                    @valueChange="(v) => {values.bank_account_id = v; delete serverErrors.bank_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="rules.amount" 
                    label="Monto"
                    :errorMessages="serverErrors.amount"
                    :prefix="values.amount === '' ? '' : '$'"
                   
                ></textFieldForm>
            </v-col>
           
        </v-row>


    
        
                
            
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingBankAccounts: false,
            loadingClients: false,
            loadingAgents: false,
            loadingAccounts: false,
            loadingAdvances: false,
           
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
			
                
               
             
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                
               
                
				
            },
            items:{
               
            },
            serverItems: {
               
                bankAccounts: [],
                customers: [],
                advances: [],
                agents: [],
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        
        this.getBankAccounts();
        this.getCustomers();
        this.getAgents();

        if(this.edit){
            this.getAdvances(this.values.customs_agent_id, this.values.customer_id);
        }
    },
    methods: {

        
        getAgents()
        {
            this.loadingBankAccounts = true;
            this.$api.customAgent.getSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    this.serverItems.agents = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
      
        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
     
         
        getCustomers()
        {
            this.loadingClients = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingClients = false;
				})
			;
        },

        getAdvances(customAgentId, customerId)
        {
            this.serverItems.advances = [];

            if(customAgentId == "" || customAgentId == undefined)
                return;
            if(customerId == "" || customerId == undefined)
                return;

            this.loadingAdvances = true;
            this.$api.advance.getByCustomAgentClient(this.$store.state.business, customAgentId, customerId)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.advances = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingAdvances = false;
				})
			;
        },


    }
}
</script>

<style>

</style>