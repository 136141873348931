<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col class="py-2">
                        <datePickerForm
                            label="Fecha"
                            :valueInput="values.date"
                            :rule="rules.required"
                            @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                        ></datePickerForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Cliente"
                            :loading="loadingCustomer"
                            :valueInput="values.customer_id"
                            :rule="rules.required"
                            :items="serverItems.customers"
                            :errorMessages="serverErrors.customer_id"
                            @valueChange="(v) => {values.customer_id = v; this.clear(); getCreditNotes(v); values.credit_note_id = ''; delete serverErrors.customer_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
               
                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Forma de pago"
                            :loading="loadingMethods"
                            :valueInput="values.payment_method_id"
                            :rule="rules.required"
                            :items="serverItems.methods"
                            :errorMessages="serverErrors.payment_method_id"
                            @valueChange="(v) => {
                                values.payment_method_id = v;
                                resetCurrency(v);
                                paymentMethodChanged(v); 
                                currencyUpdated();
                                exchangeUpdate(); 
                                delete serverErrors.payment_method_id
                            }"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row v-if="values.payment_method_id == 'descuento'">
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Divisa"
                            :valueInput="values.currency"
                            :rule="rules.required"
                            :items="items.currency"
                            :errorMessages="serverErrors.currency"
                            @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                            
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Nota de crédito"
                            :loading="loadingCreditNotes"
                            :valueInput="values.credit_note_id"
                            :rule="values.payment_method_id == 'notas-de-credito' ? rules.required:[]"
                            :items="serverItems.creditNotes"
                            :errorMessages="serverErrors.credit_note_id"
                            @valueChange="(v) => {values.credit_note_id = v; setAmountToTable(v); selectCurrency(null);  delete serverErrors.credit_note_id}"
                            :disabled="values.payment_method_id == 'notas-de-credito' ? false:true"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            :disabled="(values.payment_method_id === 'notas-de-credito' || values.payment_method_id === 'descuento')"
                            label="Cuenta bancaria"
                            :loading="loadingBankAccounts"
                            :valueInput="values.bank_account_id"
                            :rule="rules.bank"
                            :items="serverItems.bankAccounts"
                            :errorMessages="serverErrors.bank_account_id"
                            @valueChange="(v) => {
                                values.bank_account_id = v; 
                                bankAccountUpdated(v); 
                                
                                delete serverErrors.bank_account_id
                            }"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.commentary = v; delete serverErrors.commentary}" 
                            :valueInput="values.commentary" 
                            :rule="[]" 
                            label="Comentarios"
                            :errorMessages="serverErrors.commentary"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {
                                values.exchange_rate = v; 
                                currencyUpdated();
                                exchangeUpdate(); 
                                delete serverErrors.exchange_rate
                            }" 
                            :valueInput="values.exchange_rate" 
                            :rule="rules.current_mxn_per_usd" 
                            label="Tipo de cambio"
                            :errorMessages="serverErrors.exchange_rate"
                            :prefix="values.exchange_rate === '' ? '' : '$'"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <fileInputForm
                            
                            label="Adjunto"
                            :multiple="false"
                            :valueInput="values.vaucher_file"
                            :url="values.vaucher"
                            :rule="[]"
                            @valueChange="(v) => {values.vaucher_file = v; delete serverErrors.vaucher_file}"
                            :errorMessages="serverErrors.vaucher_file"
                            v-on:update:url="values.vaucher = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="pt-1 pb-0">
                        <checkboxForm
                            
                            label="Esta transacción se usará para pagar un invoice"
                            :valueInput="values.add_invoices"
                            
                            @valueChange="(v) => {values.add_invoices = v; this.clear(); delete serverErrors[`add_invoices`];}"
                            :errorMessages="serverErrors[`add_invoices`]"
                        ></checkboxForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="pt-1 pb-3">
                        <checkboxForm
                            
                            label="Esta transacción incluye el pago de comisiones"
                            :valueInput="values.commissions"
                            
                            @valueChange="(v) => {values.commissions = v; this.updateTablaMontoComission(); delete serverErrors[`commissions`];}"
                            :errorMessages="serverErrors[`commissions`]"
                        ></checkboxForm>
                    </v-col>
                </v-row>


                <v-row v-if="values.commissions">
                    <v-col class="pt-0 pb-4">
                        <fileInputForm
                    
                            label="Factura XML"
                            :multiple="false"
                            :valueInput="file"
                            
                            :accept="'text/xml'"
                            @valueChange="(v) => {this.readXML(v)}"
                            :errorMessages="serverErrors.file"
                            @cleared:file="clearInputs"
                        ></fileInputForm>
                    </v-col>
                </v-row>

                <v-row v-if="values.commissions">
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {
                                values.commissions_total = v; 
                                
                                this.updateTablaMontoComission();
                                delete serverErrors.commissions_total
                            }" 
                            :valueInput="values.commissions_total" 
                            :rule="rules.amount" 
                            label="Total de comisión"
                            :errorMessages="serverErrors.commissions_total"
                            :prefix="values.commissions_total === '' ? '' : '$'"
                            :disabled="xml === 1"
                        ></textFieldForm>
                    </v-col>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Divisa"
                            :valueInput="values.commissions_currency"
                            :rule="rules.required"
                            :items="items.currency"
                            :errorMessages="serverErrors.commissions_currency"
                            @valueChange="(v) => {values.commissions_currency = v;this.updateTablaMontoComission(); delete serverErrors.commissions_currency}"
                            :disabled="xml === 1"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row v-if="values.commissions">
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.commissions_uuid = v; delete serverErrors.commissions_uuid}" 
                            :valueInput="values.commissions_uuid" 
                            :rule="[]" 
                            label="UUID"
                            :errorMessages="serverErrors.commissions_uuid"
                            :disabled="xml === 1"
                        ></textFieldForm>
                    </v-col>
                </v-row>



                <v-row>
                    <v-col class="py-0">
                        <h4>Cuentas por cobrar/invoices</h4>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col class="py-2">
                        <autocompleteOneForm
                            
                            label="Cuenta por cobrar/invoice"
                            :loading="loadingReceivableAccounts"
                            :valueInput="values.receivable_account_id"
                            :rule="[]"
                            :items="serverItems.receivableAccounts"
                            :errorMessages="cxcSelectorError"
                            @valueChange="(v) => {values.receivable_account_id = v; cxcSelectorError = ''; delete serverErrors.receivable_account_id}"
                        ></autocompleteOneForm>
                    </v-col>
                    <v-col cols="4" sm="3" class="pl-0" >
                        
                        <primary-button :props="addBtnData" class=""></primary-button>
                       
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <table-items  :props="tableProps" @methodHandler="method_handler" :items="values.balanceables">
                            <template v-slot:item.folio="props">
                                <span class="body-2  break-words">{{props.item.folio}}</span>
                            </template>
                            <template v-slot:item.total_text="props">
                                <span class="body-2">{{ut.currencyFormat(props.item.total,props.item.currency)}}</span>
                            </template>
                            <template v-slot:item.amount="props">
                                <div class="table-item">
                                    <v-text-field  :rules="rules.amount"  prefix="$" :suffix="values.currency? values.currency : props.item.currency" v-model="props.item.amount[values.currency? values.currency : props.item.currency]" @keyup="() => {updateTableItem(props.item); clearError(props.item); updateTablaMontoComission()}"></v-text-field>
                            
                                </div>
                            </template>
                            <template v-slot:item.balance_due="props">
                                <span class="body-2">{{ut.currencyFormat(props.item.balance_due[values.currency? values.currency : props.item.currency],props.item.currency)}}</span>
                            </template>
                            <template v-slot:footer>
                                
                                <span v-if="values.commissions == 1">Monto neto a ingresar considerando comisiones ${{commission_result_label}}</span>
                            </template>
                        </table-items>
                        <v-input class="warning--text caption" :error-messages="serverErrors.balanceables" v-model="values.balanceables" :rules="rules.balanceables"></v-input>
                    </v-col>
                </v-row>
               
                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>
import TableItems from "./TableItems.vue";
import Utils from '../../helpers/Utils';

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit', 'inputCreditNotes'],
    components: {
       'table-items': TableItems
    },
    data(){
        return {
            file: [],
            xml: 0,
            updateTable: 0,
            ut: Utils,
            cxcSelectorError: "",
            loadingCustomer: false,
            loadingMethods: false,
            loadingBankAccounts: false,
            loadingReceivableAccounts: false,
            loadingCreditNotes: false,

            commission_result_label: '0',
            commission_result_object: {MXN: 0, USD: 0},

            originalCurrency: '',

            actions: [
				{
					name: 'Borrar',
					icon: {
						color: 'warning',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteTableItem'
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left',
						value: 'folio',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					{
						text: 'Adeudo',
						align: 'left',
						value: 'total_text',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'left',
						value: 'amount',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo',
						align: 'left',
						value: 'balance_due',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '5%',
						class: 'table-b-border black--text'
					}
				],
				items: this.values.balanceables,
				search: '',
				loading: false,
                keyValue: 0,
			},
            addBtnData: {
                text: "Agregar",
                	
                click: this.addItem,
                block:true,
                loading: false,
            },
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
				
              
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                balanceables: [
                    v => {
                        if(v.length == 0)
                            return 'Al menos una cuenta por cobrar';
                        return true;
                    }
                ],
                
                bank: [(v) => !!(this.values.payment_method_id === 'notas-de-credito' || this.values.payment_method_id === 'descuento'? true:v) || "Banco es requerido"],
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
				
            },
            items:{
                types:[ 
                    {text: "NORMAL", value: "NORMAL"},
                    {text: "ANTICIPADA", value: "ANTICIPADA"}
                ],
                currency: [
                    {text: "MXN", value: "MXN"},
                    {text: "USD", value: "USD"}
                ],
                credit_period:[
                    {text: "0 DÍAS", value: '0'},
                    {text: "7 DÍAS", value: 7},
                    {text: "15 DÍAS", value: 15},
                    {text: "30 DÍAS", value: 30},
                    {text: "60 DÍAS", value: 60},
                    {text: "90 días", value: 90}
                ],
                receivableBackup: [],
            },
            serverItems: {
                customers: [],
                methods: [],
                bankAccounts: [],
                receivableAccounts: [],
                creditNotes: [],
            },

          
        }
    },
    computed: {
        
    },
    watch: { 
        'values.customer_id': function(v) {
            console.log(this.values.add_invoices);
            if(this.values.add_invoices == 1)
                this.getInvoices(v);
            else
                this.getReceivableAccounts(v);  
        },
        'values.add_invoices': function(v) {
            this.clear();
            if(v == 1)
                this.getInvoices(this.values.customer_id);
            else
                this.getReceivableAccounts(this.values.customer_id);  
        }
    },
    mounted(){
        this.loadSavedCreditNotes();
        this.getCustomers();
        this.getPaymentMethods();
        this.getBankAccounts();
        this.getReceivableAccounts(this.values.customer_id);
        // //console.log(this.values);
        this.updateTablaMontoComission(false);
        this.getExchange();

        this.getCreditNotes(this.values.customer_id);
        this.selectCurrency()
        this.exchangeUpdate();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        bankAccountUpdated(v)
        {
            const bank_account = this.serverItems.bankAccounts.find( x => x.value === v);
            if(bank_account){
                this.values.currency = bank_account.currency;
            }
            const pastCurrency = this.values.currency;
            

            this.selectCurrency(v); 
            if(pastCurrency == this.values.currency)
                return;
            this.currencyUpdated();
            this.exchangeUpdate(); 

        },
        clearError(item)
        {
            let index =  Utils.getIndexArrayObject(this.values.balanceables, 'id', item.id);
            // console.log(index, item);
            delete this.serverErrors[`balanceables.${index}.id`];
        },
        getExchange()
        {
            if(this.edit)
                return;
            this.$api.utils.getExchange()
                .then((resp) => {
                    this.values.exchange_rate =  parseFloat(resp.data.usd_to_mxn).toFixed(2);
                })
                .catch((error) => {
                    // console.log(error);
                    this.values.exchange_rate = '';
                })
            ;
        },
        paymentMethodChanged(value)
        {
            if(value === 'notas-de-credito' || value === 'descuento')
            {
                this.values.bank_account_id = '';
                // this.values.currency = "";
            }
        },
        deleteTableItem(properties)
        {
            //let pos = Utils.getIndexArrayObject(this.tableProps.items,'id',  properties.id);
            
            //this.tableProps.items.splice(pos, 1);
            let pos =  Utils.getIndexArrayObject(this.values.balanceables, 'id', properties.id);
            this.values.balanceables.splice(pos, 1);

            let ret = this.items.receivableBackup.find((itm) => itm.value == properties.id);
            if(ret != undefined)
                this.serverItems.receivableAccounts.push(ret);

            this.updateTablaMontoComission();
            
        },

        updateCommissionsLabel()
        {
            let value = this.commission_result_object[this.values.currency? this.values.currency : this.values.balanceables[0].currency];
            if(isNaN(value))
                value = "0.0000";
            this.commission_result_label = value;
            
        },

        updateTablaMontoComission(updateExchange = true)
        {
            if(this.values.commissions == 0)
                return;
            if(this.values.balanceables.length == 0)
                return;


            const element = this.values.balanceables[0];

            if(this.values.commissions_currency == "" || this.values.commissions_total == ""){
                this.commission_result_object.MXN = parseFloat(''+parseFloat(element.amount.MXN)).toFixed(4);
                this.commission_result_object.USD = parseFloat(''+parseFloat(element.amount.USD)).toFixed(4);
                this.updateCommissionsLabel();
            
                return;
                
            }

            
            const result = this.updateAmount(parseFloat(this.values.commissions_total), this.values.commissions_currency, this.values.exchange_rate??1);
           
            //set values 
           
            //this.values.balanceables[0].amount = (this.updateAmount((element.total), element.currency, this.values.exchange_rate??1));

            this.commission_result_object.MXN = parseFloat(''+parseFloat(element.amount.MXN) - parseFloat(result.MXN)).toFixed(4);
            this.commission_result_object.USD = parseFloat(''+parseFloat(element.amount.USD) - parseFloat(result.USD)).toFixed(4);
            this.updateCommissionsLabel();

            if(updateExchange)
                this.exchangeUpdate();
        },

        currencyUpdated()
        {
            if(this.values.exchange_rate == "" || this.values.exchange_rate == null || this.values.exchange_rate == undefined)
                return null;
            // let response = {MXN: 0, USD: 0};
            for (let i = 0; i < this.values.balanceables.length; i++) {
                const element = this.values.balanceables[i];
                
                // response = ;
                if(element.total != 0)
                this.values.balanceables[i].amount = (this.updateAmount((element.total), element.currency, this.values.exchange_rate??1));
            }
            this.updateTablaMontoComission();
            if(this.values.credit_note_id != ""){
                console.log("yes, if");
                this.setAmountToTable(this.values.credit_note_id)
            }
        },
        
        updateTableItem(item)
        {
            console.log(item);
            //console.log(this.serverErrors)
            let mxn = item.total - item.amount.MXN;
            let usd = item.total - item.amount.USD;
            if(item.currency === "USD"){
                usd = (item.total - item.amount.USD).toFixed(4);
                mxn = (item.total - (item.amount.MXN / this.values.exchange_rate)).toFixed(4);
            }

            if(item.currency === "MXN"){
                usd = (item.total - (item.amount.USD * this.values.exchange_rate)).toFixed(4);
                mxn = (item.total - item.amount.MXN).toFixed(4);
            }

            if(mxn == 0){
                mxn = Math.abs(mxn)
            }

            if(usd == 0){
                // console.log("gere");
                usd = Math.abs(usd)
            }
            const new_balance_due = {MXN: mxn, USD: usd};
            console.log(new_balance_due)
            // this.$emit("updateExchange", {key, balance_due : new_balance_due});
            const ind = Utils.getIndexArrayObject(this.values.balanceables,'id', item.id);
            //const ind2 = Utils.getIndexArrayObject(this.tableProps.items, 'id', item.id);
            this.values.balanceables[ind].balance_due = new_balance_due;
            //this.tableProps.items[ind2].balance_due = new_balance_due;
            // this.values.balanceables[ind].price = props.item.price;
            this.updateCommissionsLabel();
            return new_balance_due;
        },
        addItem()
        {
            if(this.values.add_invoices)
            {
                this.addInvoice();
            }else{
                this.addCXC();
            }
        },
        addInvoice()
        {
            if(this.values.receivable_account_id === '' || this.values.receivable_account_id == undefined)
                this.cxcSelectorError = "Campo requerido";
            
            let cxc = this.values.receivable_account_id;
            if(cxc == null || cxc == '' || cxc == undefined)
                return;
            // console.log(this.serverItems.receivableAccounts, 'itemss');
            let found = this.serverItems.receivableAccounts.filter((itm) => itm.value == cxc)[0];
            let item = {
                id: found.slug,
                folio: found.invoice_number,
                amount: this.updateAmount((found.balance), found.currency, this.values.exchange_rate??1),
                balance_due: {MXN: 0.00, USD: 0.00},
                total: found.balance,
                currency: found.currency,
                current_mxn_per_usd: this.values.exchange_rate??1,
                actions : JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: found.slug },
            };

            this.originalCurrency = found.currency;

            // console.log(item);
            this.values.balanceables.push(item);
            //this.tableProps.items.push(item);
            const pos = Utils.getIndexArrayObject(this.serverItems.receivableAccounts,'value', cxc);
            this.serverItems.receivableAccounts.splice(pos, 1);
            this.values.receivable_account_id = '';
            delete this.serverErrors.balanceables;

            //update by credit
            if(this.values.credit_note_id != ""){
                console.log("yes, if");
                this.setAmountToTable(this.values.credit_note_id)
            }

            this.updateTablaMontoComission();
        },

        addCXC()
        {
            
            if(this.values.receivable_account_id === '' || this.values.receivable_account_id == undefined)
                this.cxcSelectorError = "Campo requerido";
            
            let cxc = this.values.receivable_account_id;
            if(cxc == null || cxc == '' || cxc == undefined)
                return;
            // console.log(this.serverItems.receivableAccounts, 'itemss');
            let found = this.serverItems.receivableAccounts.filter((itm) => itm.value == cxc)[0];
            let item = {
                id: found.slug,
                folio: found.folio??found.invoice_number,
                amount: this.updateAmount((found.total - found.balance), found.currency, this.values.exchange_rate??1),
                balance_due: {MXN: 0.00, USD: 0.00},
                total: found.total - found.balance,
                currency: found.currency,
                current_mxn_per_usd: this.values.exchange_rate??1,
                actions : JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: found.slug },
            };
            this.originalCurrency = found.currency;
            // console.log(item);
            this.values.balanceables.push(item);
            //this.tableProps.items.push(item);
            const pos = Utils.getIndexArrayObject(this.serverItems.receivableAccounts,'value', cxc);
            this.serverItems.receivableAccounts.splice(pos, 1);
            this.values.receivable_account_id = '';
            delete this.serverErrors.balanceables;

            //update by credit
            if(this.values.credit_note_id != ""){
                // console.log("yes, if");
                this.setAmountToTable(this.values.credit_note_id)
            }

            this.updateTablaMontoComission();
            
        },

        updateAmount(total, item_currency, current_mxn_per_usd){
            let mxn = total;
            let usd = total;
            if(item_currency === "USD"){
                usd = (total).toFixed(4);
                mxn = (total * current_mxn_per_usd).toFixed(4);
            }
            if(item_currency === "MXN"){
                usd = (total / current_mxn_per_usd).toFixed(4);
                mxn = (total).toFixed(4);
            }
            const amount = {MXN: mxn, USD: usd};
            return amount;
        },

        

        setAmountToTable(idNote)
        {
            if(this.values.payment_method_id != 'notas-de-credito')
                return;
            const noteSelected = this.serverItems.creditNotes.find((item) => item.value == idNote);
            // console.log(noteSelected, "noteselcted");
            
            //set value in table
            if(this.values.balanceables.length > 0){
                //const element = this.values.balanceables[0];
                this.values.balanceables[0].amount = (this.updateAmount((noteSelected.amount), noteSelected.currency, this.values.exchange_rate??1))
            }

            // console.log(this.values.balanceables);
            this.exchangeUpdate();

            // console.log(this.values.currency, "currency");
        },

        resetCurrency()
        {
            this.values.currency = this.originalCurrency;
            
        },

        selectCurrency(value){
            const pastCurrency = this.values.currency;
            this.values.currency = "MXN";
            const bank_account = this.serverItems.bankAccounts.find( x => x.value === value);
            if(bank_account){
                this.values.currency = bank_account.currency;
            }
            if(this.values.payment_method_id == 'notas-de-credito'){
                const nota = this.serverItems.creditNotes.find( x => x.value === this.values.credit_note_id);
                if(nota){
                    this.values.currency = nota.currency;
                    console.log("enter nota", this.values.currency)
                }
            }
            if(pastCurrency == this.values.currency)
                return;
            for (let i = 0; i < this.values.balanceables.length; i++) {
                const element = this.values.balanceables[i];
                this.updateTableItem(element);
            }
            this.values.currency = pastCurrency;
            console.log(this.values.currency, 'ok')
        },
        exchangeUpdate()
        {
            
            for (let i = 0; i < this.values.balanceables.length; i++) {
                const element = this.values.balanceables[i];
                this.updateTableItem(element);
            }
        },

       
        getCustomers()
        {
            this.loadingCustomer = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					// //console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					// console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCustomer = false;
				})
			;
        },

        loadSavedCreditNotes()
        {
            if(this.inputCreditNotes == null || this.inputCreditNotes == undefined)
                return;

           
            this.inputCreditNotes.forEach(cn => {

                this.serverItems.creditNotes.push(
                    {
                        value: cn.slug, 
                        text: `${cn.folio} ${parseFloat(""+cn.total).toFixed(4)} ${cn.currency}`,
                        amount: cn.total,
                        currency: cn.currency
                    }
                )
            });

            
        },

        getCreditNotes(customer)
        {
            // this.values.credit_note_id = ''; 
            if(customer == "" || customer == null || customer == undefined)
                return;

            this.loadingCreditNotes = true;
            this.$api.ncIncome.getSelectCreditNotesByCustomer(this.$store.state.business, customer)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.creditNotes = resp.data.map((item) => {
                        return {value: item.value, text:item.text, amount:item.total, currency: item.currency};
                    });
                    this.loadSavedCreditNotes();
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCreditNotes = false;
				})
			;
        },

        getPaymentMethods()
        {
            this.loadingMethods = true;
            this.$api.paymentMethod.getSelect()
                .then((resp) => {
                    this.serverItems.methods = resp.data;
                })
                .catch((error) => {
                    // console.log(error);
                })
                .finally(() => {
                    this.loadingMethods = false;
                })
            ;
        },

        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    // //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                            currency: element.currency,
                        });
                    });
                })
                .catch((error) => {
                    // console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
        
        getReceivableAccounts(customer)
        {
            if(customer == '' || customer == null || customer == undefined)
                return null;
            this.loadingReceivableAccounts = true;
            this.$api.receivableAccount.byCustomer(this.$store.state.business, customer)
                .then((resp) => {
                    // //console.log(resp.data);
                    resp.data.forEach(itm => {
                        this.serverItems.receivableAccounts.push({
                            ...itm,
                            text: itm.folio,
                            value: itm.slug
                        });
                        this.items.receivableBackup.push({
                            ...itm,
                            text: itm.folio,
                            value: itm.slug
                        });
                    });
                })
                .catch((error) => {
                    // console.log(error);
                })
                .finally(() => {
                    this.loadingReceivableAccounts = false;
                })
            ;
        },
        getInvoices(customer)
        {
            if(customer == '' || customer == null || customer == undefined)
                return null;
            this.loadingReceivableAccounts = true;
            this.$api.invoice.byCustomer(this.$store.state.business, customer)
                .then((resp) => {
                    //console.log(resp.data);
                    resp.data.forEach(itm => {
                        this.serverItems.receivableAccounts.push({
                            ...itm,
                            text: itm.invoice_number,
                            value: itm.slug
                        });
                        this.items.receivableBackup.push({
                            ...itm,
                            text: itm.invoice_number,
                            value: itm.slug
                        });
                    });
                })
                .catch((error) => {
                    // console.log(error);
                })
                .finally(() => {
                    this.loadingReceivableAccounts = false;
                })
            ;
        },
        clear()
        {
            this.tableProps.items = [];
            this.items.receivableBackup = [];
            this.values.balanceables = [];
            this.serverItems.receivableAccounts = [];
        },


        readXML(file) {
            if (file !== null) {
                let formdata = new FormData();
                formdata.append("xml_archive", file);
                this.$api.balance.readXMLCommission(formdata)
                .then((response) => {
                    console.log(response);
                    this.values.commissions_total = response.data.total;
                    this.values.commissions_uuid = response.data.uuid;
                    this.values.commissions_currency = response.data.currency;
                   
                    this.xml = 1;
                    this.updateTablaMontoComission();
                })
                .catch((error) => {
                    alert(error);
                });
            }
        },

        clearInputs() {
            this.xml = 0;
            // this.values.taxes = 0;
            this.values.commissions_total = 0;
            this.values.commissions_uuid = "";
            this.values.commissions_currency = "";
            this.updateTablaMontoComission();
            // console.log(this.values, file)
        },

    }
}
</script>

<style>

</style>