<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingCustomers"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; this.getAccounts(v); delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Obra"
                    :loading="loadingPA"
                    :valueInput="values.receivable_account_id"
                    :rule="rules.required"
                    :items="serverItems.cxc"
                    :errorMessages="serverErrors.receivable_account_id"
                    @valueChange="(v) => {values.receivable_account_id = v; delete serverErrors.receivable_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



       
        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha de aplicación"
                    :valueInput="values.application_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.application_date = v; delete serverErrors['application_date']}"
                    :errorMessages="serverErrors['application_date']"
                ></datePickerForm>
            </v-col>
        </v-row>


       


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="[...rules.amount, ...rules.required]" 
                    label="Monto"
                    :errorMessages="serverErrors.amount"
                    :prefix="values.amount == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xla,.xlw,.xlr,.csv,.ods'"
                    label="Cotización"
                    :multiple="false"
                    :valueInput="values.quotation_file"
                    :url="values.quotation_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.quotation_file = v; delete serverErrors.quotation_file}"
                    :errorMessages="serverErrors.quotation_file"
                    v-on:update:url="values.quotation_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Observaciones"
                    :errorMessages="serverErrors.comments"
                ></textFieldForm>
            </v-col>
        </v-row>

        
       
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ['edit', 'values', 'serverErrors'],
    
   
    data(){
        return {
            loadingCustomers: false,
            loadingPA: false,
            
            rules: {
				required: [
					v => !!v || 'El campo requerido',
                   
				],
                
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                number: [
                    v => {
                        // if(v === null || v.toString().trim() === '')
                        //     return true;
                        if(v!= null && !v.toString().match("^[0-9]{0,99999}$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                items: [
                    v => {
                        if(v.length == 0)
                            return 'Al menos un producto o servicio';
                        return true;
                    }
                ]
               
				
				
            },
            serverItems: {
                customers: [],
                references: [],
                cxc:[]
            },

            

          
        }
    },
   
    mounted(){
        this.getCustomers();
        // this.getReferences();
        if(this.edit){
            this.getAccounts(this.values.customer_id);
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        getCustomers()
        {
            this.loadingCustomers = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCustomers = false;
				})
			;
        },

        getAccounts(customer)
        {
            if(customer == "" || customer == undefined)
                return;
            // this.payableAccounts = [];
            this.loadingPA = true;
            this.$api.customer.constructionReceivableAccount(this.$store.state.business, this.$route.params.construction, customer)
                .then((resp) => {
                    // this.payableAccounts = resp.data;
                    this.serverItems.cxc = resp.data.map(item => {
                        return {value: item.value, text: item.text}
                    });
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPA = false;
                })
            ;
        },
       
    }
}
</script>

<style>

</style>