<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Nueva Cuenta de Gastos
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <payable-accounts-form ref="PayableAccountsForm" :values="form" @save="save" :serverErrors="serverErrors"></payable-accounts-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import Object2Form from '../../../helpers/Object2Form';
import PayableAccountsForm from "./Form.vue";
export default {
    components: {
        'payable-accounts-form': PayableAccountsForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                customs_agent_id:"",
                bill_date_emitted:"",
                traffic_number:"",
                pedimento:"",
                pedimento_key:"", 
                container_number:"",
                folio:"",
                has_advances:false,
                customer_id:"",
                concept_id: "", //concepto

                //* xml part
                fee:0, //honorarios
                validation:0,
                considerations:0,
                iva_fee:0,
                iva_validation:0,
                iva_considerations:0,
                //total honorarios es la suma
                total_consideration: 0, //no es para mandar, solo suma
                currency:"", //divisa
                uuid:"",
                fiscal_padlock:0,
                extra_services:0,
                customs_examination:0, //reconocimiento aduanero
                taxes_additional_services:0,
                american_account:"",
                taxes_pedimento:0,
                damages_in_containers:0,
                warranties:0,
                demoras:0,
                manoeuvres:0,
                iva_manoeuvres:0,
                total_cg:0,
                total:0,
                credit_period:"",
                current_mxn_per_usd:0,
                comments: "",
                total_fee:0,
                advance_id:"",
                compensation_id:"",
                ///compensation_i:"",
                warranty_id: "",
                demora_id: "",

                invoice_pdf_file:[],
                invoice_pdf_path: "",

                files:[]
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "PayableAccounts",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
		validate () {
           
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
            //this.testValida();
			return this.$refs.form.validate();
            // return true;
		},
        testValida()
        {
            if(this.form.has_advances){
                if(this.form.advance_id == '')
                    this.serverErrors.advance_id = "no";
            }
        },
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        save(){
            
            console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                console.log(form, this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.agentPayableAccount.store(this.$store.state.business ,form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'PayableAccounts'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));


            //files (array)
            data.files = [];
            request.files.forEach(file => {
                data.files.push({file: file});
            });
            

            if(data.has_advances){
                data.advance_id = data.advance_id.value;
                data.compensation_id = data.compensation_id.value;
            }else{
                delete data.advance_id;
                delete data.compensation_id;
            }

            data.invoice_pdf_file = request.invoice_pdf_file;

            data.has_advances = data.has_advances ? 1 : 0;

            if(data.warranty_id == ""){
                delete data.warranty_id;
                delete data.warranties;
            }
            else
                data.warranty_id = data.warranty_id.value;


            if(data.demora_id == ""){
                delete data.demora_id;
                delete data.demoras;    
            }
            else
                data.demora_id = data.demora_id.value;

            if(data.american_account == ""){
                delete data.american_account;
            }

            
            let objectData =  Object2Form.objectToFormData(data,"",[]);
            // objectData.append("files", []);
          
            return objectData;
        }

        
    },
}
</script>

<style>

</style>