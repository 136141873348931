<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
               

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="[...rules.required, ...rules.max]" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            @valueChange="(v) => {values.trade_name = v; delete serverErrors.trade_name}"
                            :valueInput="values.trade_name"
                            :rule="rules.required"
                            label="Nombre comercial"
                            :errorMessages="serverErrors.trade_name"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.rfc = v; delete serverErrors.rfc}" 
                            :valueInput="values.rfc" 
                            :rule="rules.rfc" 
                            label="RFC"
                            :errorMessages="serverErrors.rfc"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.entry = v; delete serverErrors.entry}" 
                            :valueInput="values.entry" 
                             :rule="[...rules.required, ...rules.max]"
                            label="Rubro"
                            :errorMessages="serverErrors.entry"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <fileInputForm
                            :accept="'.jpg,.jpeg,.JPG,.PNG,.png'"
                            label="Logo"
                            :multiple="false"
                            :valueInput="values.logo_file"
                            :url="values.logo_path"
                            :rule="[]"
                            @valueChange="(v) => {values.logo_file = v; delete serverErrors.logo_file}"
                            :errorMessages="serverErrors.logo_file"
                            v-on:update:url="values.logo_path = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row>

               

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="País"
                            
                            :valueInput="values.country"
                            :rule="[]"
                            :items="items.countries"
                            :errorMessages="serverErrors.country"
                            :returnObject="false"
                            @valueChange="(v) => {values.country = v; delete serverErrors.country}"
                            :disabled="values.origin == 'NACIONAL' ? true : false"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.address = v; delete serverErrors.address}" 
                            :valueInput="values.address" 
                           
                            label="Dirección"
                            :errorMessages="serverErrors.address"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.neighborhood = v; delete serverErrors.neighborhood}" 
                            :valueInput="values.neighborhood" 
                            
                            label="Colonia"
                            :errorMessages="serverErrors.neighborhood"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.city = v; delete serverErrors.city}" 
                            :valueInput="values.city" 
                           
                            label="Municipio"
                            :errorMessages="serverErrors.city"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.state = v; delete serverErrors.state}" 
                            :valueInput="values.state" 
                            
                            label="Estado"
                            :errorMessages="serverErrors.state"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.zip_code = v; delete serverErrors.zip_code}" 
                            :valueInput="values.zip_code" 
                            
                            label="Código postal"
                            :errorMessages="serverErrors.zip_code"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                         <textFieldForm 
                            @valueChange="(v) => {values.phone = v; delete serverErrors.phone}" 
                            :valueInput="values.phone" 
                            :rule="[...rules.phone]" 
                            label="Teléfono"
                            :errorMessages="serverErrors.phone"
                        ></textFieldForm>
                    </v-col>
                </v-row>


                 

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.email = v; delete serverErrors.email}" 
                            :valueInput="values.email" 
                            :rule="[...rules.required, ...rules.email]" 
                            label="Correo"
                            :errorMessages="serverErrors.email"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row v-if="edit">
                    <v-col class="py-2">
                        <switchForm
                            title="Estatus"
                            leftLabel=""
                            :rightLabel="values.status ? 'Activo': 'Inactivo'"
                            :valueInput="values.status"
                            :errorMessages="serverErrors.status"
                            @valueChange="(v) => {values.status = v; delete serverErrors.status}"
                        ></switchForm>
                    </v-col>
                </v-row>


                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>
import json from "@/assets/json/countries.json";
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                rfc: [
                    (v) => !!v || "El campo requerido",
                    (v) => v.length <= 13 || "Debe de ser entre 12 y 13 caracteres",
                    (v) => v.length >= 12 || "Debe de ser entre 12 y 13 caracteres",
                ],
                max: [
                    (v) => v.length <= 100 || "Máximo 100 caracteres"
                ],
                phone: [
                    (v) => !!v || " El Telefono es requerido",
                    // (v) => v.length == 10 || "Debe ser un telefono valido",
                    (v) => {
                        if(v!= null && !v.toString().match("^[0-9]{10}$"))
                            return 'Debe ser un telefono valido';
                        return true;
                    }
                ],
                email: [
                    (v) => {
                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
				
				
            },

            items: {
                origins: [
                    { text: "NACIONAL", value: "NACIONAL" },
                    { text: "INTERNACIONAL", value: "INTERNACIONAL" },
                ],
                countries: []
            }
            

          
        }
    },
   
    mounted(){
        this.getCountries();
    },
    methods: {
        getCountries()
        {
            json.forEach(c => {
                this.items.countries.push({text: c.name, value: c.code});
            });
        },
        originCountry() {
            if (this.values.origin === "NACIONAL") {
                this.values.country_code = {text: "MÉXICO", value:"MX"};
                
            } else {
                
                this.values.country_code = null;
            }
        },
       
       
    }
}
</script>

<style>

</style>