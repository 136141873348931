<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
               

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="[...rules.required]" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row v-if="edit">
                    <v-col class="py-2">
                        <switchForm
                            title="Estatus"
                            leftLabel=""
                            :rightLabel="values.status ? 'Activo': 'Inactivo'"
                            :valueInput="values.status"
                            
                            @valueChange="(v) => {values.status = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
				
				
            },
            

          
        }
    },
   
    mounted(){
        
    },
    methods: {

       
    }
}
</script>

<style>

</style>