<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" xl="2" class="mx-0 px-0 pt-sm-0 mr-md-2 pb-sm-1">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>
						
					</v-col>
                   
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" class="mx-0 pt-md-0 pt-4 pb-4 pb-md-1">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary">
                                        <v-col class="py-1 pb-0 text-sm-center text-center">
                                            <span>TOTAL</span><br>
                                            <span>{{summary.due_balance.mxn}}</span><br>
                                            <span>{{summary.due_balance.usd}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-center text-center">
                                           
                                            <span>TOTAL VENCIDO</span><br>
                                            <span>{{summary.total_balance.mxn}}</span><br>
                                            <span>{{summary.total_balance.usd}}</span>
                                            
                                        </v-col>
                                       
                                    </v-row>
                                    
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
            summary: {due_balance: {mxn: '$0.00', usd: '$0.00'}, total_balance:{mxn: '$0.00', usd: '$0.00'}, },
            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')??new Date().toISOString().split('T')[0]
            },
			serverErrors: {},
			tableProps: {
				headers: [
					
                    {
						text: 'Cliente',
						align: 'left',
						value: 'customer_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Total',
						align: 'right',
						value: 'total_balance',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Vencido',
						align: 'right',
						value: 'due_balance',
						class: 'table-b-border black--text'
					},
                    
                    // {
					// 	text: 'Divisa',
					// 	align: 'left',
                        
					// 	value: 'currency',
					// 	class: 'table-b-border black--text'
					// },
					
				],
				items: [],
				search: '',
				loading: true,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading:false,
                click: ()=>{this.export();}
            },
            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },

			
		}
	},

	mounted(){
		this.index();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
	},

    watch: {
        [`dateRange.start_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
         
        }
    },

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },

       
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
            if(this.dateRange.start_date == '' || this.dateRange.end_date == '')
                return;
                
			this.tableProps.loading = true;
            this.summary.due_balance.mxn = this.summary.due_balance.usd = Utils.currencyFormat(0, '');
            this.summary.total_balance.mxn = this.summary.total_balance.usd = Utils.currencyFormat(0, '');
            this.tableProps.items = [];
			//call api
			this.$api.report.reportReceivableAccByCustomer(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
				.then((resp) =>{
					//console.log(resp);
                    this.summary.due_balance.mxn = Utils.currencyFormat(resp.data.summary.due_balance.mxn, 'MXN');
                    this.summary.due_balance.usd = Utils.currencyFormat(resp.data.summary.due_balance.usd, 'USD');
                    this.summary.total_balance.mxn = Utils.currencyFormat(resp.data.summary.total_balance.mxn, 'MXN');
                    this.summary.total_balance.usd = Utils.currencyFormat(resp.data.summary.total_balance.usd, 'USD');
                  
					this.tableProps.items = resp.data.data.map((x) => {
						
						x.total_balance = Utils.currencyFormat(x?.total_balance??0, x.currency);
						x.due_balance = Utils.currencyFormat(x?.due_balance??0, x.currency);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportReceivableAccByCustomer(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `receivable_accounts_customers_${this.dateRange.start_date}_${this.dateRange.end_date}.xls`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportReceivableAccByCustomer(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `receivable_accounts_customers_${this.dateRange.start_date}_${this.dateRange.end_date}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>