<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
               

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="[...rules.required]" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.email = v; delete serverErrors.email}" 
                            :valueInput="values.email" 
                            :rule="[...rules.required, ...rules.email]" 
                            label="Correo"
                            :errorMessages="serverErrors.email"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <autocompleteMultipleForm
                        
                        label="Empresas"
                        :valueInput="values.business"
                        :rule="rules.business"
                        :items="serverItems.business"
                        :loading="loadingBusiness"
                        @valueChange="(v) => {values.business = v; delete serverErrors.business}"
                        :errorMessages="serverErrors.business"
                        ></autocompleteMultipleForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Rol"
                            :loading="loadingRoles"
                            :valueInput="values.role_id"
                            :rule="rules.required"
                            :items="serverItems.roles"
                            :errorMessages="serverErrors.role_id"
                            @valueChange="(v) => {values.role_id = v; delete serverErrors.role_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row v-if="!edit">
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.password = v; delete serverErrors.password}" 
                            :valueInput="values.password" 
                            :rule="[...rules.required, ...rules.length8]" 
                            label="Contraseña"
                            :errorMessages="serverErrors.password"
                            :count="true"
                            :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass ? 'text' : 'password'"
                            @clickAppend="() => {showPass = !showPass}"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row v-if="!edit">
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.password_confirmation = v; delete serverErrors.password_confirmation}" 
                            :valueInput="values.password_confirmation" 
                            :rule="[...rules.required, ...rules.samePassword, ...rules.length8]" 
                            label="Confirmar contraseña"
                            :errorMessages="serverErrors.password_confirmation"
                            :count="true"
                            :type="showPass ? 'text' : 'password'"
                            @click:append="showPass = !showPass"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row v-if="edit">
                    <v-col class="py-2">
                        <switchForm
                            title="Estatus"
                            leftLabel=""
                            :rightLabel="values.status ? 'Activo': 'Inactivo'"
                            :valueInput="values.status"
                            
                            @valueChange="(v) => {values.status = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showPass: false,
            loadingBusiness: false,
            loadingRoles: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                email: [
                    (v) => {
                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
                business: [
					v => v.length > 0 || 'El campo requerido'
				],

                samePassword:[
                    (v) => v == this.values.password || "Las contraseñas no coinciden",
                ],
                length8:[
                    v => !!v || 'El campo requerido',
                    v => (!!v && v.length >= 8) || 'Al menos de 8 caracteres'
                ]
				
				
            },

            serverItems:{
                business: [],
                roles: [],
            }
            

          
        }
    },
   
    mounted(){
        this.getBusiness();
        this.getRoles();
    },
    methods: {
        getBusiness()
        {
            this.loadingBusiness = true;
            this.$api.business.getSelectAll()
                .then((resp) => {
                    this.serverItems.business = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBusiness = false;
                })
            ;
        },

        getRoles()
        {
            this.loadingRoles = true;
            this.$api.rol.getSelect()
                .then((resp) => {
                    this.serverItems.roles = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingRoles = false;
                })
            ;
        }
       
    }
}
</script>

<style>

</style>