const headers = [
    {
        text: 'Mostrar',
        align: 'center',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    
]

const modules = [
  // {label:"Root", submodules:[ {key:"root", label: "Administrador"} ]},
    {
        props: {
            headers: [
                {
                    text: 'Sección',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "Sección",
        submodules: [
            
            { key: "dashboard", label: "Dashboard", align: 'center', },
            { key: "income_statement", label: "Estado de resultados", align: 'center', },
            { key: "cxp", label: "Cuentas por pagar" },
            { key: "cxc", label: "Cuentas por cobrar" },
            { key: "loans", label: "Préstamos Inter." },
            { key: "invoices", label: "Invoices" },
            { key: "customs_agents", label: "Agentes aduanales" },
            { key: "constructions_dynamic", label: "Obras" },
            { key: "contractors", label: "Egresos x obra" },
            { key: "constructions", label: "Ingresos x obra" },
            { key: "attributes", label: "Atributos" },
            { key: "catalogs", label: "Catálogos" },
            { key: "reports", label: "Reportes" },
            { key: "payroll", label: "Nómina" },
        ],
    },

];

export default modules;
