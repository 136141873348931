const permissions = {
    dashboard: false,
    income_statement: false, //estado de resultados
    cxp: false, //cuentas x pagar
    cxc: false, //cuentas x cobrar
    invoices: false, //invoices
    customs_agents: false, //agentes aduanales
    contractors: false, //egresos x obra
    constructions: false, //ingresos x obra
    attributes: false, //atributos
    catalogs: false, //catálogos
    reports: false, //reportes
    payroll: false, //nomina
    loans:false, //prestamos inter
    // constructions_dynamic:false, //obras (menu dinamico)
};

export default permissions;
