<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalle Demora
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from "../../../helpers/Utils";
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
           
            items: [
            ],
            
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                click: () => {this.backPage()}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.delay.get(this.$store.state.business, this.slug)
			.then((response) => {
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                this.items= [
                    { concepto: 'Agente aduanal', index: 'slug', valor: response.data.customs_agent?.legal_name??"" },
                    { concepto: 'Cliente', index: 'slug', valor: response.data.customer?.name??'' },
                    { concepto: 'Trafico', index: 'slug', valor: response.data.traffic_number },
                    { concepto: 'Contenedor', index: 'slug', valor: response.data.container_number??"" },
                    { concepto: 'Fecha de pago', index: 'due_date', valor: Utils.globalDateFormat(response.data.payment_date)},
                    // { concepto: 'Cuenta', index: 'slug', valor: response.data.account?.name??"" },
                    { concepto: 'Cuenta', index: 'slug', valor: response.data?.concept?.name??'' },
                    { concepto: 'Cuenta Bancaria', index: 'slug', valor: `${response.data.bank_account?.name??""} - ${response.data.bank_account?.currency??""}` },
                    { concepto: 'Monto del anticipo', index: 'slug', valor: Utils.currencyFormat(response.data.amount, response.data.bank_account?.currency??"") },


                ];

                if(response.data.support_file_url != '' && response.data.support_file_url != null){
                    this.items.push(
                        {concepto: 'Adjunto', index: 'support_file_url', valor: response.data.support_file_url, isFile:true}
                    );
                }else{
                    this.items.push(
                        { concepto: 'Adjunto', index: 'slug', valor: '' },
                    );
                }

              
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        backPage()
        {
            
            this.$router.push({name: 'Delays'});
            
          
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 