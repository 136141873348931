<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles del usuario
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';

export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
           
            items: [
               
                
            ],
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Users",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.user.get(this.slug)
			.then((response) => {
                console.log(response);
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                //relation
                let business = '';
                response.data.business.forEach(pr => {
                    business = business+`${pr.name}\n`;
                });
               


               
                this.items= [
                    { concepto: 'Nombre', index: 'name', valor: response.data.name },
                    { concepto: 'Correo', index: 'email', valor:response.data.email},
                    { concepto: 'Empresa', index: 'business', valor: business },
                    { concepto: 'Rol', index: 'role.name', valor: response.data.role.name },
                    { concepto: 'Estatus', index: 'status', valor: response.data.status == 1 ? 'Activo': 'Inactivo'},
                ];

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 