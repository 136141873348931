<template>
    <div>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.legal_name = v; delete serverErrors.legal_name}" 
                    :valueInput="values.legal_name" 
                    :rule="[...rules.required, ...rules.max]" 
                    label="Razón social"
                    :errorMessages="serverErrors.legal_name"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.rfc = v; delete serverErrors.rfc}" 
                    :valueInput="values.rfc" 
                    :rule="rules.rfc" 
                    label="RFC"
                    :errorMessages="serverErrors.rfc"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.contact = v; delete serverErrors.contact}" 
                    :valueInput="values.contact" 
                    :rule="rules.required" 
                    label="Contacto"
                    :errorMessages="serverErrors.contact"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.state = v; delete serverErrors.state}" 
                    :valueInput="values.state" 
                    :rule="rules.required" 
                    label="Estado"
                    :errorMessages="serverErrors.state"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.city = v; delete serverErrors.city}" 
                    :valueInput="values.city" 
                    :rule="rules.required" 
                    label="Municipio"
                    :errorMessages="serverErrors.city"
                ></textFieldForm>
            </v-col>
        </v-row>

        
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.neighborhood = v; delete serverErrors.neighborhood}" 
                    :valueInput="values.neighborhood" 
                    :rule="rules.required" 
                    label="Colonia"
                    :errorMessages="serverErrors.neighborhood"
                ></textFieldForm>
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.address = v; delete serverErrors.address}" 
                    :valueInput="values.address" 
                    :rule="rules.required" 
                    label="Domicilio"
                    :errorMessages="serverErrors.address"
                ></textFieldForm>
            </v-col>
        </v-row>

      

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.zip_code = v; delete serverErrors.zip_code}" 
                    :valueInput="values.zip_code" 
                    :rule="rules.zip"
                    label="Código postal"
                    :errorMessages="serverErrors.zip_code"
                ></textFieldForm>
            </v-col>
        </v-row>


    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            disableSelect: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                rfc: [
                    (v) => !!v || "El campo requerido",
                    (v) => v.length <= 13 || "Debe de ser entre 12 y 13 caracteres",
                    (v) => v.length >= 12 || "Debe de ser entre 12 y 13 caracteres",
                    
                ],
                max: [
                    (v) => v.length <= 100 || "Máximo 100 caracteres"
                ],
                zip: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]{5}?$"))
                            return 'Al menos 5 dígitos ';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
				
				
            },

            items: {
                
                countries: []
            }
            

          
        }
    },
   
    mounted(){
        
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>