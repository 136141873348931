<template>
    <div>
      
        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.date"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                ></datePickerForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proveedor"
                    :loading="loadingProvider"
                    :valueInput="values.provider_id"
                    :rule="rules.provider_id"
                    :items="serverItems.providers"
                    :errorMessages="serverErrors.provider_id"
                    @valueChange="(v) => {values.provider_id = v; delete serverErrors.provider_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        
        

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Referencia"
                    :loading="loadingReferences"
                    :valueInput="values.reference_id"
                    :rule="rules.reference_id"
                    :items="serverItems.references"
                    :errorMessages="serverErrors.reference_id"
                    @valueChange="(v) => {values.reference_id = v; delete serverErrors.reference_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.folio = v; delete serverErrors.folio}" 
                    :valueInput="values.folio" 
                    :rule="rules.folio" 
                    label="Folio"
                    :errorMessages="serverErrors.folio"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    
                    label="Factura XML"
                    :multiple="false"
                    :valueInput="file"
                    
                    :accept="'text/xml'"
                    @valueChange="(v) => {this.readXML(v)}"
                    :errorMessages="serverErrors.photo_file"
                    @cleared:file="clearInputs"
                ></fileInputForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.subtotal = v; this.total();delete serverErrors.subtotal}" 
                    :valueInput="values.subtotal" 
                    :rule="rules.amount" 
                    label="Subtotal"
                    :errorMessages="serverErrors.subtotal"
                    :prefix="values.subtotal === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.total = v;delete serverErrors.total}" 
                    :valueInput="values.total" 
                    :rule="[]" 
                    label="Total"
                    :errorMessages="serverErrors.total"
                    :prefix="values.total === '' ? '' : '$'"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.discounts = v; this.total();delete serverErrors.discounts}" 
                    :valueInput="values.discounts" 
                    :rule="rules.amount" 
                    label="Descuentos"
                    :errorMessages="serverErrors.discounts"
                    :prefix="values.discounts === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.ieps = v; this.total();delete serverErrors.ieps}" 
                    :valueInput="values.ieps" 
                    :rule="rules.amount" 
                    label="IEPS"
                    :errorMessages="serverErrors.ieps"
                    :prefix="values.ieps === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva = v; this.total();delete serverErrors.iva}" 
                    :valueInput="values.iva" 
                    :rule="rules.amount" 
                    label="IVA"
                    :errorMessages="serverErrors.iva"
                    :prefix="values.iva === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.isr_withholdings = v; this.total();delete serverErrors.isr_withholdings}" 
                    :valueInput="values.isr_withholdings" 
                    :rule="rules.amount" 
                    label="Retención ISR"
                    :errorMessages="serverErrors.isr_withholdings"
                    :prefix="values.isr_withholdings === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva_withholdings = v; this.total();delete serverErrors.iva_withholdings}" 
                    :valueInput="values.iva_withholdings" 
                    :rule="rules.amount" 
                    label="Retención IVA"
                    :errorMessages="serverErrors.iva_withholdings"
                    :prefix="values.iva_withholdings === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Divisa"
                    :valueInput="values.currency"
                    :rule="rules.currency"
                    :items="items.currency"
                    :errorMessages="serverErrors.currency"
                    @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                    :disabled="xml === 1"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
      

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.uuid = v; this.total();delete serverErrors.uuid}" 
                    :valueInput="values.uuid" 
                    :rule="[]" 
                    label="UUID"
                    :errorMessages="serverErrors.uuid"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>


        

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :loading="loadingConcepts"
                    label="Cuenta"
                    :valueInput="values.concept_id"
                    :rule="rules.concept_id"
                    :items="serverItems.concepts"
                    :errorMessages="serverErrors.concept_id"
                    @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; this.total();delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Observaciones"
                    :errorMessages="serverErrors.comments"
                    
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF'"
                    label="Factura adjunta (pdf)"
                    :multiple="false"
                    :valueInput="values.invoice_file"
                    :url="values.invoice_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.invoice_file = v; delete serverErrors.invoice_file}"
                    :errorMessages="serverErrors.invoice_file"
                    v-on:update:url="values.invoice_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

                
                
            
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingProvider: false,
            loadingReferences: false,
            loadingConcepts: false,
            file: [],
            xml: 0,
            rules: {
				date: [
					v => !!v || 'El campo requerido'
				],
				provider_id: [
                    v => !!v || 'El campo es requerido'
				],
                type: [
                    v => !!v || 'El campo es requerido'
                ],
                reference_id: [
                    v => !!v || 'El campo es requerido'
                ],
                folio: [
                    v => !!v || 'El campo es requerido'
                ],
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                currency: [
                    v => !!v || 'El campo es requerido'
                ],
                
                concept_id: [
                    v => !!v || 'El campo es requerido'
                ]
				
            },
            items:{
               
                currency: [
                    {text: "MXN", value: "MXN"},
                    {text: "USD", value: "USD"}
                ],
                
            },
            serverItems: {
                providers: [],
                references: [],
                concepts: []
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        this.getProviders();
        this.getReferences();
        this.getConcepts();
        //this.getExchange();
    },
    methods: {

       

       
        getProviders()
        {
            this.loadingProvider = true;
            this.$api.supplier.getSelectSuppliers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.providers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingProvider = false;
				})
			;
        },

        getReferences()
        {
            this.loadingReferences = true;
            this.$api.reference.getSelectReferences(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.references = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingReferences = false;
                })
            ;
        },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },

        total() {
            const taxes = ((parseFloat(this.values.iva) + parseFloat(this.values.ieps)) - (parseFloat(this.values.iva_withholdings)  + parseFloat(this.values.isr_withholdings) + parseFloat(this.values.discounts)));
            this.values.total =  parseFloat(this.values.subtotal) + parseFloat(taxes);
            delete this.serverErrors.total;
        },

        readXML(file) {
            if (file !== null) {
                let formdata = new FormData();
                formdata.append("xml_archive", file);
                this.$api.balance.readXML(formdata)
                .then((response) => {
                    // this.values.taxes = response.data.taxes;
                    this.values.discounts = response.data.discounts;
                    this.values.ieps = response.data.ieps;
                    this.values.iva = response.data.iva;
                    this.values.iva_withholdings = response.data.iva_withholdings;
                    this.values.isr_withholdings = response.data.isr_withholdings;
                    this.values.subtotal = response.data.subtotal;
                    this.values.total = response.data.total;
                    this.values.currency = response.data.currency;
                    this.values.uuid = response.data.uuid;
                    this.values.taxes_items = response.data.taxes_items;
                    this.xml = 1;
                })
                .catch((error) => {
                    alert(error);
                });
            }
        },

        clearInputs() {
            this.xml = 0;
            // this.values.taxes = 0;
            this.values.subtotal = 0;
            this.values.total = 0;

            this.values.discounts = 0;
            this.values.ieps = 0;
            this.values.iva = 0;
            this.values.iva_withholdings = 0;
            this.values.isr_withholdings = 0;

            this.values.currency = "";
            this.values.uuid = "";

            console.log(this.values, file)
        },
    }
}
</script>

<style>

</style>