<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}"
                            @onClear="(v) => {trySearch(v);}"
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            :clearable="true"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'contractor_payable_accounts.create'" cols="12" md="3" lg="3" xl="2" class="mx-0 px-0">
                        <primaryButton :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatablePagination :options="options" :total="total"  :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                        </datatablePagination>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
    watch: {
        ['$route.params.construction'](){
            
            this.construction = this.$route.params.construction;
            this.index();
        }
    },
    data(){
		return {
            construction: this.$route.params.construction,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
			actions: [
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'contractor_payable_accounts.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'contractor_payable_accounts.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'contractor_payable_accounts.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Obra',
						align: 'left',
						value: 'folio',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'left',
						value: 'providers_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Tipo de obra',
						align: 'left',
						value: 'construction_type_name',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto contratado',
						align: 'left',
                       
						value: 'amount',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo',
						align: 'left',
						value: 'balance',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Vigencia',
						align: 'left',
                        
						value: 'end_ejecution',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
                        
						class: 'table-b-border black--text'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: false,
                sortBy: 'created_at',
                sortDesc: true,
			},
			
			
            createBtnData: {
                text: "Nuevo Contrato",
                icon: "mdi-plus",
				to: "ContractorsCXPCreate",
				block: true,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
       
	},

    methods: {
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.search = val;
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            this.index();
        },
		index() {
            this.tableProps.items = [];
			this.tableProps.loading = true;
			let filter = Utils.createFilter(this.options, this.tableProps.headers);
			//call api
			this.$api.contractorCXP.list(this.$store.state.business, this.construction, filter)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
						x.actions[0].action = { name: 'ContractorsCXPDetails', params: { slug: x.slug, construction: this.construction } }
                        x.actions[1].action = { name: 'ContractorsCXPEdit', params: { slug: x.slug, construction: this.construction } }
						

						x['parameters'] = { id: x.slug, name: x.folio}
                       
                        x.end_ejecution = Utils.globalDateFormat(x.end_ejecution);
                       
                        x.amount = Utils.currencyFormat(x.amount, x.currency);
                        x.balance = Utils.currencyFormat(x.balance, x.currency);
						return x
					});
                    this.total = resp.data.total
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},


        getStatus(status)
        {
            switch (status) {
                case 'pending':
                    return 'Pendiente'

                case 'active':
                    return 'Activa';
                default:
                    return 'Vencida'
            }
        },


		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.contractorCXP.delete(this.$store.state.business,this.construction, this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>