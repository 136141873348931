<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalles del préstamo
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >
                        <separator title="Datos generales"></separator>

                        <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center" v-if="payments.length > 0">
                    <v-col cols="12" md="6" >
                        <separator title="Pagos"></separator>
                        
                        <details-item class="mb-10" v-for="(item, index) in payments" :key="index" :detailsItems="getPaymentItem(item)"></details-item>


                    </v-col>
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../components/DetailsItem.vue';
import Utils from "../../helpers/Utils";
import Separator from "../../components/Separator.vue";
export default {
    components: {
        DetailsItem,
        Separator
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
            payments: [],

            items: [
               
                
            ],
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "",
                click: () => {this.backPage()}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.loanOutCome.get(this.$store.state.business, this.slug)
			.then((response) => {
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                const data = response.data;
                this.payments = response.data.payments;
                this.items= [
                    { concepto: 'Fecha', index: 'due_date', valor: Utils.globalDateFormat(data.date)},
                    { concepto: 'Acreedor', index: 'slug', valor: data.creditorable?.name },
                    { concepto: 'Cuenta Bancaria del acreedor', index: 'slug', valor: data.creditor_bank_account?.name },
                    { concepto: 'Referencia del acreedor', index: 'slug', valor: data?.credito_reference?.name??""},
                    { concepto: 'Deudor', index: 'slug', valor: data.debtorable?.name },
                    { concepto: 'Cuenta bancaria del deudor', index: 'slug', valor: data.debtor_bank_account.name },
                    { concepto: 'Referencia del deudor', index: 'slug', valor: data.debtor_reference?.name },
                    { concepto: 'Cuenta', index: 'slug', valor: data.concept.name },
                    { concepto: 'Monto del préstamo', index: 'slug', valor: Utils.currencyFormat(data.amount, '') },
                    { concepto: 'Saldo', index: 'slug', valor: Utils.currencyFormat(data.balance, '') },
                    { concepto: 'Comentarios', index: 'slug', valor: data.comments },
                    { concepto: 'Adjunto', index: 'slug', valor: data.file_url, isFile:true },
                ];

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
        getPaymentItem(payment)
        {
           return [
                { concepto: 'Fecha', index: 'due_date', valor: Utils.globalDateFormat(payment.date)},
                { concepto: 'Forma de pago', index: 'slug', valor: payment?.payment_method?.name??"" },
                { concepto: 'Cuenta Bancaria ', index: 'slug', valor: payment?.debtor_bank_account?.name??"" },
                { concepto: 'Monto', index: 'slug', valor: Utils.currencyFormat(payment.amount, '') },
                { concepto: 'Adjunto', index: 'slug', valor: payment.file_url, isFile:true },
            ];
        },
        backPage()
        {
            this.$router.push({name: 'LoansOutcome'});
          
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 