<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalle pago
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center" >
                    <v-col cols="12" md="6" class="py-0">
                        <span class="font-weight-bold normalText--text">Cuentas de gastos</span>
                        <table-items  :props="tableProps" :items="balanceables">
                            <template v-slot:item.folio="props">
                                <span class="body-2">{{props.item.folio}}</span>
                            </template>
                            <template v-slot:item.total_text="props">
                                <span class="body-2">{{ut.currencyFormat(props.item.total,props.item.currency)}}</span>
                            </template>
                            <template v-slot:item.amount="props">
                                <div class="table-item">
                                    <span class="body-2">{{ut.currencyFormat(props.item.amount[values.currency? values.currency : props.item.currency], props.item.currency) }}</span>
                                </div>
                            </template>
                            <template v-slot:item.balance_due="props">
                                <span class="body-2">{{ut.currencyFormat(props.item.balance_due[values.currency? values.currency : props.item.currency],props.item.currency)}}</span>
                            </template>
                        </table-items>
                    </v-col>
                        <!-- <details-item :detailsItems="observations"></details-item> -->
                    

                    
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from "../../../helpers/Utils";
import TableItems from "../../incomes/TableItems.vue";
export default {
    components: {
        DetailsItem,
        'table-items': TableItems
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
            values: null,
            ut: Utils,
            balanceables: [],
            items: [
            ],
            
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                click: () => {this.backPage()}
            },

            tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left',
						value: 'folio',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					{
						text: 'Adeudo',
						align: 'left',
						value: 'total_text',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'left',
						value: 'amount',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo',
						align: 'left',
						value: 'balance_due',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
				],
				
			},
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
			//this.$store.state.overlay = true
            console.log(this.slug);
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.payment.get(this.$store.state.business, this.slug)
			.then((response) => {
                this.values = response.data;
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                this.items= [
                    { concepto: 'Fecha', index: 'due_date', valor: Utils.globalDateFormat(response.data.date)},
                    { concepto: 'Agente aduanal', index: 'slug', valor: response.data.customs_agent?.legal_name??"" },
                    { concepto: 'Forma de pago', index: 'slug', valor: response.data.payment_method?.name??"" },
                    { concepto: 'Cuenta bancaria', index: 'slug', valor: response.data.bank_account?.name??"" },
                    { concepto: 'Comentarios', index: 'slug', valor: `${response.data.comments??""}` },
                    { concepto: 'Tipo de cambio', index: 'slug', valor: Utils.currencyFormat(response.data.exchange_rate??"0", response.data.bank_account?.currency??"") },
                    

                ];

                if(response.data.vaucher_path != null){
                    this.items.push(
                        {concepto: `Adjunto`, index: 'vaucher', valor: response.data.vaucher_path??null, isFile:true},

                    );
                }


                response.data.payable_accounts.forEach(element => {
                    this.balanceables.push({
                        folio: element.folio,
                        id: element.slug,
                        amount: {MXN: element.pivot.amount, USD: element.pivot.amount},
                        balance_due: {MXN: element.total - element.balance, USD: element.total - element.balance},
                        total: element.total - element.balance,
                        currency: element.currency, //resp.data.bank_account.currency, //?????
                        current_mxn_per_usd: element.current_mxn_per_usd,
                       
                        
                    })
                });

              
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},


        

        backPage()
        {
            
            this.$router.push({name: 'Payments'});
            
          
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 