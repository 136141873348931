<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="2" lg="2" xl="2" class="mx-0 px-0 mr-md-2 py-lg-0">
						<alternativeAutocompleteOne
                            :items="years"
                            :valueInput="selectedYear"
                           
                            @valueChange="(v) => {selectedYear = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Año'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
                   
                    
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" class="mx-0 py-lg-0 ">
                        <v-row justify="end">
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
    data(){
		return {
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
			search: '',
            selectedYear: PageStateManager.getState(this.$router.history.current.path, 'selectedYear')??'',
            years: [
                
            ],
            
			serverErrors: {},
            
			tableProps: {
				headers: [
					{
						text: 'Empresa',
						align: 'left',
						value: 'company_name',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Ene',
						align: 'left',
						value: 'january',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Feb',
						align: 'left',
						value: 'february',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Mar',
						align: 'left',
                        
						value: 'march',
						class: 'table-b-border black--text'
					},
                    {
                        text: 'Abr',
						align: 'left',
                        
						value: 'april',
						class: 'table-b-border black--text'
					},
                    {
                        text: 'May',
                        align: 'left',
                        
                        value: 'may',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Jun',
                        align: 'left',
                        
                        value: 'june',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Jul',
                        align: 'left',
                        
                        value: 'july',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Ago',
                        align: 'left',
                        
                        value: 'august',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Sep',
                        align: 'left',
                        
                        value: 'september',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Oct',
                        align: 'left',
                        
                        value: 'october',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Nov',
                        align: 'left',
                        
                        value: 'november',
                        class: 'table-b-border black--text'
                    },
                    {
                        text: 'Dic',
                        align: 'left',
                        
                        value: 'december',
                        class: 'table-b-border black--text'
                    },
					
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable') ? true : false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				block: false,
                loading: false,
                disabled: false,
                click: ()=>{this.export();}
            },

            





			
		}
	},

  
    watch: {
        /**
         * save change
         */
        [`selectedYear`]: function () {
            // console.log(this.selectedYear, "changed")
            PageStateManager.setState(this.$router.history.current.path, 'selectedYear', this.selectedYear);
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
        },
       
       
    },
    

	mounted(){
        this.getYears();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.index();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
            }
        },
        method_handler(object){
            // console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			console.log(object.options, "optionss");
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
       
		index() {
            // // console.log(this.$router)
            if(this.selectedYear == '' || this.selectedYear == null || this.selectedYear == undefined)
                return;
			this.tableProps.loading = true;
           
            this.tableProps.items = [];
           
			//call api
			this.$api.payroll.annualBudget(this.selectedYear)
				.then((resp) =>{
					console.log(resp);
                   
                   

					this.tableProps.items = resp.data.map((x) => {
                        

						x.january = Utils.currencyFormat(x.january??0, "");
						x.february = Utils.currencyFormat(x.february??0, "");
						x.march = Utils.currencyFormat(x.march??0, "");
						x.april = Utils.currencyFormat(x.april??0, "");
						x.may = Utils.currencyFormat(x.may??0, "");
						x.june = Utils.currencyFormat(x.june??0, "");
						x.july = Utils.currencyFormat(x.july??0, "");
						x.august = Utils.currencyFormat(x.august??0, "");
						x.september = Utils.currencyFormat(x.september??0, "");
						x.october = Utils.currencyFormat(x.october??0, "");
						x.november = Utils.currencyFormat(x.november??0, "");
						x.december = Utils.currencyFormat(x.december??0, "");

						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
           
		},

        
        getYears()
        {
            this.years = [];
            let now = new Date();
            now.setHours(0,0,0,0)
            const year = now.getFullYear();
            console.log(year);
            if(this.selectedYear == "")
                this.selectedYear = ""+year;
            for (let i = 2022; i <= year; i++) {
                this.years.push({text: ""+i, value: ""+i});
            }
        },
        

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.payroll.exportAnnualBudget(this.selectedYear)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `annual_budget_${this.selectedYear}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },


       

    }
}
</script>


<style lang="scss">
    .table-line-height{
        line-height: 1.2rem !important;
    }
</style>