<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col class="py-2">
                       <textFieldForm
                            label="Acreedor"
                            :loading="false"
                            :valueInput="values.creditor"
                            :rule="[]"
                            :disabled="true"
                            :errorMessages="serverErrors.creditor_id"
                            
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                       <textFieldForm
                            label="Deudor"
                            :loading="false"
                            :valueInput="values.debtor"
                            :rule="[]"
                            :disabled="true"
                            :errorMessages="serverErrors.creditor_id"
                            
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                       <textFieldForm
                            label="Saldo Actual"
                            :loading="false"
                            :valueInput="values.balance"
                            :rule="[]"
                            :disabled="true"
                            :errorMessages="serverErrors.creditor_id"
                            
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <datePickerForm
                            label="Fecha"
                            :valueInput="values.date"
                            :rule="rules.required"
                            @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                        ></datePickerForm>
                    </v-col>
                </v-row>

              
<!-- 
                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Cuenta bancaria del acreedor"
                            :loading="loadingBankAccountsCreditor"
                            :valueInput="values.creditor_bank_account_id"
                            :rule="rules.required"
                            :items="serverItems.bankAccountsCreditor"
                            :errorMessages="serverErrors.creditor_bank_account_id"
                            @valueChange="(v) => {values.creditor_bank_account_id = v; delete serverErrors.creditor_bank_account_id}"
                            :disabled="true"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row> -->


                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Cuenta bancaria del deudor"
                            :loading="loadingBankAccountsDebtor"
                            :valueInput="values.debtor_bank_account_id"
                            :rule="rules.required"
                            :items="serverItems.bankAccountsDebtor"
                            :errorMessages="serverErrors.debtor_bank_account_id"
                            @valueChange="(v) => {values.debtor_bank_account_id = v; delete serverErrors.debtor_bank_account_id}"

                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Forma de pago"
                            :loading="loadingMethods"
                            :valueInput="values.payment_method_id"
                            :rule="rules.required"
                            :items="serverItems.methods"
                            :errorMessages="serverErrors.payment_method_id"
                            @valueChange="(v) => {values.payment_method_id = v; delete serverErrors.payment_method_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                            :valueInput="values.amount" 
                            :rule="rules.amount" 
                            label="Monto del pago"
                            :errorMessages="serverErrors.amount"
                            :prefix="(values.amount == '' || values.amount == null) ? '' : '$'"
                            :hint="showUds && convertUsd!=false ? 'usd: '+convertUsd : ''"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.comments = v;delete serverErrors.comments}" 
                            :valueInput="values.comments" 
                            :rule="[]" 
                            label="Comentarios"
                            :errorMessages="serverErrors.comments"
                            
                        ></textFieldForm>
                    </v-col>
                </v-row>
                        
                
                <v-row>
                    <v-col class="py-2">
                        <fileInputForm
                            :accept="'.pdf,.PDF'"
                            label="Adjunto"
                            :multiple="false"
                            :valueInput="values.file"
                            :url="values.file_url"
                            :rule="[]"
                            @valueChange="(v) => {values.file = v; delete serverErrors.file}"
                            :errorMessages="serverErrors.file"
                            v-on:update:url="values.file_url = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row>
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>
import Utils from '../../../helpers/Utils';

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
    computed: {
            showUds: {
                get: function() {
                    let found = this.serverItems.bankAccountsDebtor.find((item) => item.value == this.values.debtor_bank_account_id);
                    console.log(found, "foun")
                    if(found != undefined){
                        if(found.currency == "USD")
                            return true;
                    }
                   
                    return false;
                }
               
            },

            convertUsd: {
                get: function() {
                    try {
                        const valuesr = this.$banxicoSIE.banxico.convertCurrencyTo(this.currenciesValues, 'mxn', 'usd', this.values.amount??0);
                        console.log(valuesr);
                        return Utils.currencyFormat(valuesr, "");
                    } catch (error) {
                        
                        return false
                    }
                }
               
            },
            
        
    },
    data(){
        return {
            loadingCreditorReferences: false,
            loadingBankAccountsCreditor: false,

            loadingDebtorReferences: false,
            loadingBankAccountsDebtor: false,
            loadingConcepts: false,
            loadingMethods: false,

            usdPrice: null,
            currenciesValues: null,
            file: [],
            
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
				
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
              
				
            },
           
            serverItems: {
                businesses: [],
                creditorReferences: [],
                bankAccountsCreditor: [],

                debtorReferences: [],
                bankAccountsDebtor: [],

                concepts: [],
                methods: []
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        this.loadBusinesses();
        this.getConcepts();
        this.getPaymentMethods();
        // this.getBankAccounts(this.values.creditor_id, 'loadingBankAccountsCreditor', 'bankAccountsCreditor');
        if(this.values.debtor_is_construction)
            this.getBankAccounts("merca-construcciones-del-centro-sa-de-cv", 'loadingBankAccountsDebtor', 'bankAccountsDebtor');
        else
            this.getBankAccounts(this.values.debtor_id, 'loadingBankAccountsDebtor', 'bankAccountsDebtor');
        this.getUsdPrice();
    },
    methods: {
        resetDebtor()
        {
             
            this.values.debtor_bank_account_id = this.values.debtor_reference_id = "";
        },
        resetCreditor()
        {
            this.values.creditor_bank_account_id = this.values.creditor_reference_id = "";
        },

        loadBusinesses()
        {
            let found = this.$store.state.userData.business.filter((item) => item.slug == this.$store.state.business);
            console.log(found);
            this.serverItems.businesses.push({
                text: found.trade_name, value: found.slug
            });
            // this.serverItems.businesses = this.$store.state.userData.business.map((item) => {
            //     return {text: item.trade_name, value: item.slug}
            // });
        },

        getUsdPrice()
        {
            // this.$banxicoSIE.banxico.getCurrencyDataMXN()
            //     .then((resp) => {
            //     //    console.log(resp, "baxi")
            //         const values = this.$banxicoSIE.banxico.processInformation(resp.data);
            //         console.log(values, "vaaalues")
            //         this.currenciesValues = values;
            //         this.usdPrice = values.usd??null;

                    this.currenciesValues = {cad: 12.16, usd: this.values.exchange_rate_usd_mxn, eur: 18.08};
                    this.usdPrice = this.currenciesValues.usd??null;
                    // this.usdPrice = Utils.currencyFormat(values.usd??null, "");
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
                   
            //     })
            // ;
        },

        getReferences(business, loading, item)
        {
            if(business  == "" || business == undefined || business == null)
                return;
            if(item  == "" || item == undefined || item == null)
                return;
            this[`${loading}`] = true;
            this.serverItems[`${item}`] = []
            this.$api.reference.getSelectReferences(business)
                .then((resp) => {
                    this.serverItems[`${item}`] = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this[`${loading}`] = false;
                })
            ;
        },

        getBankAccounts(business, loading, item)
        {
            if(business  == "" || business == undefined || business == null)
                return;
            if(item  == "" || item == undefined || item == null)
                return;
            this[`${loading}`] = true;
             this.serverItems[`${item}`] = []
            this.$api.bank.accountGetSelect(business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems[`${item}`].push({
                            text: element.text,
                            value: element.value,
                            currency: element.currency??""
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this[`${loading}`] = false;
                })
            ;
        },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },

        getPaymentMethods()
        {
           this.loadingMethods = true;
            this.$api.paymentMethod.getSelect()
                .then((resp) => {
                    this.serverItems.methods = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingMethods = false;
                })
            ;
        },

       
    }
}
</script>

<style>

</style>