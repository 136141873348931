<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
        </v-row>


       
       


        <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.fiscal_salary = v; delete serverErrors.fiscal_salary}" 
                    :valueInput="values.fiscal_salary" 
                    :rule="[...rules.amount]" 
                    label="Sueldo fiscal"
                    :errorMessages="serverErrors.fiscal_salary"
                    :prefix="values.fiscal_salary == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.integrated_salary = v; delete serverErrors.integrated_salary}" 
                    :valueInput="values.integrated_salary" 
                    :rule="[...rules.amount]" 
                    label="Sueldo integradora"
                    :errorMessages="serverErrors.integrated_salary"
                    :prefix="values.integrated_salary == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.ims_cost = v; delete serverErrors.ims_cost}" 
                    :valueInput="values.ims_cost" 
                    :rule="[...rules.amount]" 
                    label="Costo IMSS"
                    :errorMessages="serverErrors.ims_cost"
                    :prefix="values.ims_cost == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.infonavit_cost = v; delete serverErrors.infonavit_cost}" 
                    :valueInput="values.infonavit_cost" 
                    :rule="[...rules.amount]" 
                    label="Costo INFONAVIT"
                    :errorMessages="serverErrors.infonavit_cost"
                    :prefix="values.infonavit_cost == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.amortization_infonavit = v; delete serverErrors.amortization_infonavit}" 
                    :valueInput="values.amortization_infonavit" 
                    :rule="[...rules.amount]" 
                    label="Amortización INFONAVIT"
                    :errorMessages="serverErrors.amortization_infonavit"
                    :prefix="values.amortization_infonavit == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.isn = v; delete serverErrors.isn}" 
                    :valueInput="values.isn" 
                    :rule="[...rules.amount]" 
                    label="ISN"
                    :errorMessages="serverErrors.isn"
                    :prefix="values.isn == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row>

        <!-- <v-row>
            <v-col class="py-2" cols="12">
               <textFieldForm 
                    
                    @valueChange="(v) => {values.total_cost_salary = v; delete serverErrors.total_cost_salary}" 
                    :valueInput="values.total_cost_salary" 
                    :rule="[...rules.amount]" 
                    label="Costo total sueldo"
                    :errorMessages="serverErrors.total_cost_salary"
                    :prefix="values.total_cost_salary == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
        </v-row> -->

        
        
       
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    components: {
       
    },
   
    data(){
        return {
           
           
            

            rules: {
				required: [
					v => !!v || 'El campo requerido',
                   
				],
                
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return "El campo es requerido";
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
               
               
				
				
            },
           
            
            

          
        }
    },
   
    mounted(){
        
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        
       
    }
}
</script>

<style>

</style>