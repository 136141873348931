<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" xl="2" class="mx-0 px-0 pt-sm-0 mr-md-2 pb-sm-1">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>
						
					</v-col>
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" class="mx-0 pt-md-0 pt-4 pb-4 pb-md-1">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
            subtotalReport: '$0.00',
            taxesReport: '$0.00',
            totalReport: '$0.00',
            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')??new Date().toISOString().split('T')[0]
            },
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Agente\nAduanal',
						align: 'left',
						value: 'customs_agent_name',
						
						class: 'table-b-border black--text white-space-normal small-th'
					},
                    {
						text: 'Fecha',
						align: 'left',
						value: 'bill_date_emitted',
                        
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Tráfico',
						align: 'center break-words',
						value: 'traffic_number',
                        
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Pedimento',
						align: 'center',
						value: 'pedimento',
                        
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Contenedor',
						align: 'center',
                        
						value: 'container_number',
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Factura',
						align: 'center break-words',
                        
						value: 'folio',
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Cliente',
						align: 'left ',
                        
						value: 'customer_name',
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Total CG',
						align: 'right break-words',
                        
						value: 'total_cg',
						class: 'table-b-border black--text white-space-normal small-th'
					},
                    {
						text: 'Anticipos',
						align: 'right',
                        
						value: 'total_advances',
						class: 'table-b-border black--text small-th'
					},
                    {
						text: 'Total a pagar',
						align: 'right break-words',
                        
						value: 'total',
						class: 'table-b-border black--text  white-space-normal small-th'
					},
                    {
						text: 'Estatus',
						align: 'left',
                        
						value: 'status',
						class: 'table-b-border black--text small-th'
					},
					
				],
				items: [],
				search: '',
				loading: true,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.export();}
            },

            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },
			
		}
	},

    watch: {
        [`dateRange.start_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
         
        }
    },


	mounted(){
		this.index();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() 
        {
            if(this.dateRange.start_date == '' || this.dateRange.end_date == '')
                return;
			this.tableProps.loading = true;
           
            this.tableProps.items = [];
			//call api
			this.$api.report.reportCABalancePayableAccounts(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
				.then((resp) =>{
					console.log(resp.data);
                   
					this.tableProps.items = resp.data.data.map((x) => {
						
						x.total = Utils.currencyFormat(x.total??0, x.currency);
						x.total_cg = Utils.currencyFormat(x.total_cg??0, x.currency);
                        x.total_advances = Utils.currencyFormat(x.total_advances??0, x.currency);
                        
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportCABalancePayableAccounts(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `cuenta_gastos_${this.$store.state.business}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportCABalancePayableAccounts(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `cuenta_gastos_${this.$store.state.business}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>