<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta"
                    :loading="loadingAccounts"
                    :valueInput="values.account_id"
                    :rule="rules.required"
                    :items="serverItems.accounts"
                    :errorMessages="serverErrors.account_id"
                    @valueChange="(v) => {values.account_id = v; delete serverErrors.account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

       
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingAccounts: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
				
				
            },

            serverItems:{
                accounts: []
            }
            

          
        }
    },
   
    mounted(){
        this.tryGetAccounts();
    },
    methods: {
        tryGetAccounts()
        {
            this.loadingAccounts = true;
            this.$api.account.getSelect(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.accounts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingAccounts = false;
                })
            ;
        }
       
    }
}
</script>

<style>

</style>