<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" xl="4" class="mx-0 px-0 mr-md-2 py-lg-0">
						<!-- <alternativeAutocompleteOne
                            :items="customers"
                            :valueInput="customerId"
                            :loading="customersLoading"
                            :page="customersPage"
                            @valueChange="(v) => {customerId = v}"
                            @onChange="(v) => {index()}"
                            @itemsChange="(v) => {customers = v}"
                            @lastPageChange="(v) => {lastPage = v}"
                            @pageChange="(v) =>{ customersPage = v }"
                            :label="'Cliente'"
                            @searchSync="trySearch"
                            @onIntersect="onIntersect"
                            :lastPage="lastPage"
                        ></alternativeAutocompleteOne> -->
                        <alternativeAutocompleteOne
                            :items="customers"
                            :valueInput="customerId"
                            :loading="customersLoading"
                           
                            @valueChange="(v) => {customerId = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Cliente'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
                    <v-col cols="12" md="3" lg="2" xl="2" class="mx-0 px-0 mr-md-2 py-lg-0 pr-md-10 ">
                        <alternativeAutocompleteOne
                            :items="currencies"
                            :valueInput="selectedCurrency"
                            @valueChange="(v) => {selectedCurrency = v}"
                            @onChange="(v) => {index()}"
                            :label="'Divisa'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>
                    
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" class="mx-0 py-lg-0 ">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary" justify="space-around">
                                        <!-- <v-col cols="12" sm="3" class="py-1 text-sm-start text-center">
                                            <span>TOTALES</span>
                                        </v-col> -->
                                        <v-col cols="6" md="2" class="py-1 px-0 text-center">
                                            <span class="nowrap caption font-weight-bold">TOTAL 0 - 30</span>
                                            <br>
                                            <table class="mx-auto ">
                                                <tr v-if="selectedCurrency == 'MXN'">
                                                    <td class="text-subtitle-1 font-weight-bold table-line-height " align=RIGHT>{{totalReport30.mxn}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr v-if="selectedCurrency == 'USD'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport30.usd}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                        </v-col>
                                        <v-col cols="6" md="2" class="py-1    px-0 text-center">
                                           
                                          
                                            <span class="nowrap caption font-weight-bold">TOTAL 31 - 60</span>
                                            <br>
                                            <table class="mx-auto ">
                                                <tr v-if="selectedCurrency == 'MXN'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport60.mxn}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr v-if="selectedCurrency == 'USD'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport60.usd}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                        </v-col>
                                        <v-col cols="6" md="2" class="py-1  px-0 text-center">
                                           
                                            <span class="nowrap caption font-weight-bold">TOTAL 61 - 90</span>
                                            <br>
                                            <table class="mx-auto ">
                                                <tr v-if="selectedCurrency == 'MXN'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport90.mxn}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr v-if="selectedCurrency == 'USD'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport90.usd}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                            
                                        </v-col>
                                        <!-- <v-col cols="6" md="2" class="py-1 px-0  text-center">
                                           
                                            <span class="nowrap caption font-weight-bold">TOTAL 90+</span>
                                            <br>
                                            <table class="mx-auto ">
                                                <tr>
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport90plus.mxn}}</td>
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>MXN</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport90plus.usd}}</td>
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>USD</td>
                                                </tr>
                                               
                                            </table>
                                            
                                        </v-col> -->

                                        <v-col cols="6" md="2" class="py-1  px-0 text-center">
                                           
                                            <span class="nowrap caption font-weight-bold">ADEUDO TOTAL</span>
                                            <br>
                                            <table class="mx-auto ">
                                                <tr v-if="selectedCurrency == 'MXN'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport.mxn}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr v-if="selectedCurrency == 'USD'">
                                                    <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=RIGHT>{{totalReport.usd}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap table-line-height" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler"  @handleOptions="handleOptions">
                                    <template v-slot:item.bill_date_emitted="props">
                                        <span class="body-2">{{ut.globalDateFormat(props.item.bill_date_emitted)}}</span>
                                    </template>
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager';
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
// import debounce from '../../../helpers/debounce';
export default {
    data(){
		return {
            ut: Utils,
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            selectedCurrency: PageStateManager.getState(this.$router.history.current.path, 'selectedCurrency')??"MXN",
            total: 10,
			search: '',
            customersLoading: false,
            customerId: PageStateManager.getState(this.$router.history.current.path, 'customerId')??"",
            customers: [],
            lastPage: false,
            customersPage: 1,

            timeoutID: null,
            lockSearch: false,

            totalReport30: {mxn: '$0.00', usd: '$0.00'},
            totalReport60:{mxn: '$0.00', usd: '$0.00'},
            totalReport90:{mxn: '$0.00', usd: '$0.00'},
            totalReport90plus:{mxn: '$0.00', usd: '$0.00'},
            totalReport:{mxn: '$0.00', usd: '$0.00'},
          
			serverErrors: {},
            actions: [
                {
					name: 'Adjunto',
					icon: {
						color: 'secondary',
						icon: 'mdi-file-document-outline'
					},
					disabled: true,
					type: 'external',   //  method, external, redirect
					action: '',
                    permissions: 'receivable_accounts.show'
				},
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'receivable_accounts.show'
				},
				
			],
			tableProps: {
				headers: [
					{
						text: 'factura',
						align: 'left',
						value: 'folio',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Referencia',
						align: 'left',
						value: 'reference.name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cuenta',
						align: 'left',
						value: 'concept',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estado',
						align: 'center',
                        
						value: 'status',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Fecha',
						align: 'center',
                        
						value: 'bill_date_emitted',
						class: 'table-b-border black--text'
					},
                    {
						text: '0-30',
						align: 'right',
                        
						value: 'thirty',
						class: 'table-b-border black--text'
					},
                    {
						text: '31-60',
						align: 'right',
                        
						value: 'sixty',
						class: 'table-b-border black--text'
					},
                    {
						text: '61-90',
						align: 'right',
                        
						value: 'ninety',
						class: 'table-b-border black--text'
					},
                    // {
					// 	text: '90+',
					// 	align: 'right',
                        
					// 	value: 'ninetyPlus',
					// 	class: 'table-b-border black--text'
					// },
                    {
						text: 'Detalle',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					},
					
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: PageStateManager.getState(this.$router.history.current.path, 'customerId') ? true:false,
                elevation: '0',
                sortBy: "bill_date_emitted",
                sortDesc: "desc"
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                disabled:true,
                click: ()=>{this.export();}
            },
            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                customIcon:true,
                disabled:true,
                text: "Generar pdf",
                icon: "mdi-progress-download",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },


            currencies:[
                {value: "USD", text:"USD"},
                {value: "MXN", text:"MXN"}
            ]





			
		}
	},
    watch: {
        [`customerId`]() {
            this.watchCustomerId();
        },
        [`selectedCurrency`]() {
            PageStateManager.setState(this.$router.history.current.path, 'selectedCurrency', this.selectedCurrency);
           
        }
    },
   

	mounted(){
        this.getCustomers();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
		this.index();
        this.watchCustomerId();
	},

    methods: {
        watchCustomerId()
        {
            PageStateManager.setState(this.$router.history.current.path, 'customerId', this.customerId);
            this.pdfBtnData.disabled = true;
            this.exportBtnData.disabled = true;
            if(this.customerId != "" && this.customerId != null && this.customerId != undefined){
                this.pdfBtnData.disabled = false;
                this.exportBtnData.disabled = false;
            }
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;

               
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
           this.getCustomers(val.search, val.page);
        },
		index() {
            // // console.log(this.$router)
            if(this.customerId == '' || this.customerId == null || this.customerId == undefined)
                return;
			this.tableProps.loading = true;
            this.totalReport30.mxn = this.totalReport30.usd = Utils.currencyFormat(0, '');
            this.totalReport60.mxn = this.totalReport60.usd = Utils.currencyFormat(0, '');
            this.totalReport90.mxn = this.totalReport90.usd = Utils.currencyFormat(0, '');
            //this.totalReport90plus.mxn =  this.totalReport90plus.usd = Utils.currencyFormat(0, '');
            this.totalReport.mxn = this.totalReport.usd = Utils.currencyFormat(0, '');
            this.tableProps.items = [];
           
			//call api
			this.$api.report.reportBalanceDueByCustomers(this.$store.state.business, this.customerId, this.selectedCurrency)
				.then((resp) =>{
					console.log(resp);
                    this.totalReport30.mxn = Utils.currencyFormat(resp.data.summary.thirty.mxn, '');
                    this.totalReport60.mxn = Utils.currencyFormat(resp.data.summary.sixty.mxn, '');
                    this.totalReport90.mxn = Utils.currencyFormat(resp.data.summary.ninety.mxn, '');
                    //this.totalReport90plus.mxn = Utils.currencyFormat(resp.data.summary.ninety_plus.mxn, '');
                    this.totalReport30.usd = Utils.currencyFormat(resp.data.summary.thirty.usd, '');
                    this.totalReport60.usd = Utils.currencyFormat(resp.data.summary.sixty.usd, '');
                    this.totalReport90.usd = Utils.currencyFormat(resp.data.summary.ninety.usd, '');
                    //this.totalReport90plus.usd = Utils.currencyFormat(resp.data.summary.ninety_plus.usd, '');
                    this.totalReport.mxn = Utils.currencyFormat(
                        resp.data.summary.total.mxn 
                    , '');
                    this.totalReport.usd = Utils.currencyFormat(
                        resp.data.summary.total.usd, '');

					this.tableProps.items = resp.data.data.map((x) => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        if(x.invoice_pdf_path != null && x.invoice_pdf_path != ''){
                            x.actions[0].action = x.invoice_pdf_path;
                            x.actions[0].disabled = false;
                        }
                        x.actions[1].action = { name: 'CXCDetails', params: { slug: x.slug, report: 'report' } }
                        
						x.thirty = x.sixty = x.ninety = x.ninetyPlus = '-';
                        if(x.due_date_in_days <= 30)
                            x.thirty = Utils.currencyFormat(x?.pending_payment??0, x.currency);
                        if(x.due_date_in_days >= 31 && x.due_date_in_days <= 60)
                            x.sixty = Utils.currencyFormat(x?.pending_payment??0, x.currency);
                        if(x.due_date_in_days >= 61)
                            x.ninety = Utils.currencyFormat(x?.pending_payment??0, x.currency);
                        // if(x.due_date_in_days >= 91)
                        //     x.ninetyPlus = Utils.currencyFormat(x?.pending_payment??0, x.currency);

                        x.concept = `${x.concept.name} (${x?.concept?.account?.name??''})`;

                        // x.bill_date_emitted = Utils.globalDateFormat(x.bill_date_emitted);

						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
                    this.lockSearch = false;
                    console.log('finally', this.lockSearch)
				})
			;
           
		},

        getCustomers(search = '', page = this.customersPage)
        {
            
            this.customersLoading = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business, search, page)
                .then((resp) => {
                    // this.customers = [...this.customers, ...resp.data.data];
                    this.customers = resp.data;
                    //this.lastPage = false;
                    // if(page == resp.data.last_page)
                    //     this.lastPage = true;
                    // if(this.customers.length >= 1){
                    //     //this.customerId = this.customers[0].value;
                    //     this.index();
                    // }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.customersLoading = false;
                })
            ;
        },
        onIntersect(val)
        {
            //console.log(val)
            this.getCustomers(val.search, val.page);
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportBalanceDueByCustomers(this.$store.state.business, this.customerId, this.selectedCurrency)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `saldo_cliente_${this.customerId}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },


        pdf(){
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportBalanceDueByCustomers(this.$store.state.business, this.customerId, this.selectedCurrency)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `saldo_cliente_${this.customerId}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>


<style lang="scss">
    .table-line-height{
        line-height: 1.2rem !important;
    }
</style>