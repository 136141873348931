<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
               

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="[...rules.required, ...rules.max]" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.rfc = v; delete serverErrors.rfc}" 
                            :valueInput="values.rfc" 
                            :rule="rules.rfc" 
                            label="RFC"
                            :errorMessages="serverErrors.rfc"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Origen"
                            
                            :valueInput="values.origin"
                            :rule="rules.required"
                            :items="items.origins"
                            :errorMessages="serverErrors.origin"
                            @valueChange="(v) => {values.origin = v; this.originCountry();delete serverErrors.origin}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="País"
                            
                            :valueInput="values.country_code"
                            :rule="rules.required"
                            :items="items.countries"
                            :errorMessages="serverErrors.country_code"
                            :returnObject="true"
                            @valueChange="(v) => {values.country_code = v; delete serverErrors.country_code}"
                            :disabled="values.origin == 'NACIONAL' ? true : false"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.contact = v; delete serverErrors.contact}" 
                            :valueInput="values.contact" 
                            :rule="rules.required" 
                            label="Contacto"
                            :errorMessages="serverErrors.contact"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <autocompleteMultipleForm
                        
                        label="Empresas a las que provee"
                        :valueInput="values.companies"
                        :rule="rules.business"
                        :items="items.business"
                        
                        @valueChange="(v) => {values.companies = v; delete serverErrors.companies}"
                        :errorMessages="serverErrors.companies"
                        ></autocompleteMultipleForm>
                    </v-col>
                </v-row>


               

                <v-row v-if="edit">
                    <v-col class="py-2">
                        <switchForm
                            title="Estatus"
                            leftLabel=""
                            :rightLabel="values.status ? 'Activo': 'Inactivo'"
                            :valueInput="values.status"
                            
                            @valueChange="(v) => {values.status = v}"
                        ></switchForm>
                    </v-col>
                </v-row>



                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>
import json from "@/assets/json/countries.json";
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            disableSelect: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                rfc: [
                    (v) => !!v || "El campo requerido",
                    (v) => v.length <= 13 || "Debe de ser entre 12 y 13 caracteres",
                    (v) => v.length >= 12 || "Debe de ser entre 12 y 13 caracteres",
                    
                ],
                max: [
                    (v) => v.length <= 100 || "Máximo 100 caracteres"
                ],
                business: [
					v => v.length > 0 || 'El campo requerido'
				],
				
				
            },

            items: {
                origins: [
                    { text: "NACIONAL", value: "NACIONAL" },
                    { text: "INTERNACIONAL", value: "INTERNACIONAL" },
                ],
                countries: [],
                business: []
            }
            

          
        }
    },
   
    mounted(){
        this.getCountries();
        this.getBusiness();
    },
    methods: {
        getCountries()
        {
            json.forEach(c => {
                this.items.countries.push({text: c.name, value: c.code});
            });
        },
        originCountry() {
            if (this.values.origin === "NACIONAL") {
                this.values.country_code = {text: "MÉXICO", value:"MX"};
                
            } else {
                
                this.values.country_code = null;
            }
        },
        getBusiness()
        {
            this.items.business = this.$store.state.userData.business.map((item) => {
                return {value: item.slug, text: item.name};
            })
            
            console.log(this.$store.state.userData.business);
        }

       
    }
}
</script>

<style>

</style>