<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>
        
        <!-- TODO: active switch -->
        <v-row v-if="edit">
            <v-col class="py-2">
                <switchForm
                    title="Estatus"
                    leftLabel=""
                    :rightLabel="values.status ? 'Activo': 'Inactivo'"
                    :valueInput="values.status"
                    
                    @valueChange="(v) => {values.status = v}"
                ></switchForm>
            </v-col>
        </v-row>
       
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
				
				
            },

          
        }
    },
   
    mounted(){
    },
    methods: {
        
    }
}
</script>

<style>

</style>