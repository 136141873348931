<template>
    <div>
       
     
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tipo"
                   
                    :valueInput="values.type"
                    :rule="rules.required"
                    :items="items.types"
                    :errorMessages="serverErrors.type"
                    @valueChange="(v) => {values.type = v; delete serverErrors.type}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

       
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
           
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
				
				
            },

            items: {
                types: [
                    { text: "DEPÓSITO", value: "DEPÓSITO" },
                    { text: "RETIRO", value: "RETIRO" },
                ]
            }
            

          
        }
    },
   
    mounted(){
        
    },
    methods: {

       
    }
}
</script>

<style>

</style>