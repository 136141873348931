<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalles de la cuenta
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >
                        <separator title="Datos generales"></separator>

                        <details-item  :detailsItems="items"></details-item>

                        <separator title="Estimaciones"></separator>
                        <p v-if="itemsPayments.length === 0" class="pb-0 mb-0">No hay movimientos de estimaciones para esta cuenta</p>
                        <details-item class="mb-5" v-else v-for="(itemP, index) in itemsPayments" :key="index+1" :detailsItems="itemP"></details-item>


                        <separator title="Garantías"></separator>
                        <p v-if="itemsWarranties.length === 0" class="pb-0 mb-0">No hay movimientos de garantías para esta cuenta</p>
                        <details-item class="mb-5" v-else v-for="(itemW, index) in itemsWarranties" :detailsItems="itemW" :key="index+20"></details-item>


                        <separator title="Deductivas"></separator>
                        <p v-if="itemsReductions.length === 0" class="pb-0 mb-0">No hay movimientos de deductivas para esta cuenta</p>
                        <details-item class="mb-5" v-else v-for="(itemR, index) in itemsReductions" :detailsItems="itemR" :key="index+50"></details-item>


                        <separator title="Aditivas"></separator>
                        <p v-if="itemsAdditions.length === 0" class="pb-0 mb-0">No hay movimientos de aditivas para esta cuenta</p>
                        <details-item class="mb-5" v-else v-for="(itemA, index) in itemsAdditions" :detailsItems="itemA" :key="index+80"></details-item>


                    </v-col>
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Separator from "../../../components/Separator.vue";
import Utils from "../../../helpers/Utils";
export default {
    components: {
        DetailsItem,
        Separator
    },
	data(){
		return {
            slug: this.$route.params.slug,
            construction: this.$route.params.construction,
            loading: false,
           
            items: [
            ],

            itemsPayments:[
                
            ],

            itemsWarranties:[],
            itemsReductions:[],
            itemsAdditions:[],
            
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                click: () => {this.backPage()}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
            this.items.push({ concepto: 'Clave', index: 'slug', valor: "test" },)
			this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.contractorCXP.get(this.$store.state.business, this.construction, this.slug)
			.then((response) => {
                console.log(response)
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });

                //General
                this.items= [
                    { concepto: 'Proveedor', index: 'privider_id', valor: response.data.provider?.name },
                    // { concepto: 'Obra', index: 'slug', valor: response.data.reference.name??'' },
                    { concepto: 'Inicio de ejecución', index: 'slug', valor: Utils.globalDateFormat(response.data.start_ejecution) },
                    { concepto: 'Área de construcción (M2)', index: 'slug', valor: response.data.construction_area??'' },
                    { concepto: 'Tipo de obra', index: 'slug', valor: response.data.construction_type.name??'' },
                    { concepto: 'Monto contratado', index: 'slug', valor: Utils.currencyFormat(response.data.amount, response.data.currency??"") },
                    { concepto: 'Saldo', index: 'slug', valor: Utils.currencyFormat(response.data.balance, response.data.currency??"") },
                    // { concepto: 'Cotización', index: 'slug', valor: response.data.reference?.name??'' },



                    
                ];

                if(response.data.quotation_file_url != '' && response.data.quotation_file_url != null){
                    this.items.push(
                        {concepto: 'Adjunto', index: 'quotation_file_url', valor: response.data.quotation_file_url, isFile:true}
                    );
                }else{
                    this.items.push(
                        { concepto: 'Adjunto', index: 'slug', valor: '' },
                    );
                }


                //paymemts
                response.data.payments.forEach(pay => {
                    let item = [
                        { concepto: 'Fecha', index: 'privider_id', valor: Utils.globalDateFormat(pay.date) },
                        { concepto: 'Forma de pago', index: 'privider_id', valor: pay.payment_method.name },
                        { concepto: 'Cuenta bancaria', index: 'privider_id', valor: pay.bank_account.name },
                        { concepto: 'Monto', index: 'privider_id', valor: Utils.currencyFormat(pay.total, pay.currency) },
                
                    ];
                    this.itemsPayments.push(item);
                });

                //warranty
                response.data.warranties.forEach(pay => {
                    let item = [
                        { concepto: 'Periodo de ejecución', index: 'privider_id', valor: `De ${ Utils.globalDateFormat(pay.start_ejecution)} a ${ Utils.globalDateFormat(pay.end_ejecution)}` },
                        { concepto: 'Porcentaje retenido', index: 'privider_id', valor: pay.percentage + "%" },
                        { concepto: 'Monto retenido', index: 'privider_id', valor: Utils.currencyFormat(pay.amount, pay.currency??"") },
                
                    ];
                    this.itemsWarranties.push(item);
                });


                //reduction
                response.data.deductivas.forEach(pay => {
                    let item = [
                        { concepto: 'Fecha', index: 'privider_id', valor: `${ Utils.globalDateFormat(pay.application_date)}` },
                        { concepto: 'Monto', index: 'privider_id', valor: Utils.currencyFormat(pay.amount, pay.currency??"")},
                        { concepto: 'Cotización', index: 'privider_id', valor: pay.quotation_file_url??"", isFile:pay.quotation_file_url == null ? false:true },
                
                    ];
                    this.itemsReductions.push(item);
                });

                 //addition
                response.data.aditivas.forEach(pay => {
                    let item = [
                        { concepto: 'Fecha', index: 'privider_id', valor: `${ Utils.globalDateFormat(pay.application_date)}` },
                        { concepto: 'Monto', index: 'privider_id', valor: Utils.currencyFormat(pay.amount, pay.currency??"")},
                        { concepto: 'Cotización', index: 'privider_id', valor: pay.quotation_file_url??"", isFile:pay.quotation_file_url == null ? false:true },
                
                    ];
                    this.itemsAdditions.push(item);
                });
              

              
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        backPage()
        {
            
            this.$router.push({name: 'ContractorsCXP'});
            
          
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 