<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Agente aduanal"
                    :loading="loadingAgents"
                    :valueInput="values.customs_agent_id"
                    :rule="rules.required"
                    :items="serverItems.agents"
                    :errorMessages="serverErrors.customs_agent_id"
                    @valueChange="(v) => {values.customs_agent_id = v; delete serverErrors.customs_agent_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.traffic_number = v; delete serverErrors.traffic_number}" 
                    :valueInput="values.traffic_number" 
                    :rule="rules.required" 
                    label="Tráfico/BOL"
                    :errorMessages="serverErrors.traffic_number"
                ></textFieldForm>
            </v-col>
           
        </v-row>
        
       

        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha de pago"
                    :valueInput="values.payment_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.payment_date = v; delete serverErrors['payment_date']}"
                    :errorMessages="serverErrors['payment_date']"
                ></datePickerForm>
            </v-col>
        </v-row>
        

        <!-- <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta"
                    :loading="loadingAccounts"
                    :valueInput="values.account_id"
                    :rule="rules.required"
                    :items="serverItems.accounts"
                    :errorMessages="serverErrors.account_id"
                    @valueChange="(v) => {values.account_id = v; delete serverErrors.account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row> -->
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :loading="loadingConcepts"
                    label="Cuenta"
                    :valueInput="values.concept_id"
                    :rule="rules.required"
                    :items="serverItems.concepts"
                    :errorMessages="serverErrors.concept_id"
                    @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
       

      

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta bancaria"
                    :loading="loadingBankAccounts"
                    :valueInput="values.bank_account_id"
                    :rule="rules.required"
                    :items="serverItems.bankAccounts"
                    :errorMessages="serverErrors.bank_account_id"
                    @valueChange="(v) => {values.bank_account_id = v; delete serverErrors.bank_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        


       

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="rules.amount" 
                    label="Monto del anticipo"
                    :errorMessages="serverErrors.amount"
                    :prefix="values.amount === '' ? '' : '$'"
                   
                ></textFieldForm>
            </v-col>
           
        </v-row>


        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF'"
                    label="Adjunto"
                    :multiple="false"
                    :valueInput="values.support_file"
                    :url="values.support_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.support_file = v; delete serverErrors.support_file}"
                    :errorMessages="serverErrors.support_file"
                    v-on:update:url="values.support_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

        
                
            
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingConcepts: false,
            loadingBankAccounts: false,
            loadingClients: false,

            loadingAgents: false,
            loadingAccounts: false,
           
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
			
                
               
             
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                
               
                
				
            },
            items:{
               
            },
            serverItems: {
               
                methods: [],
                bankAccounts: [],
                customers: [],

                agents: []
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        
       // this.getReferences();
       this.getConcepts();
        // this.tryGetAccounts();
        this.getBankAccounts();
        this.getCustomers();
        this.getAgents();
    },
    methods: {

        // tryGetAccounts()
        // {
        //     this.loadingAccounts = true;
        //     this.$api.account.getSelect(this.$store.state.business)
        //         .then((resp) => {
        //             this.serverItems.accounts = resp.data;
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })
        //         .finally(() => {
        //             this.loadingAccounts = false;
        //         })
        //     ;
        // },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },

        getAgents()
        {
            this.loadingBankAccounts = true;
            this.$api.customAgent.getSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    this.serverItems.agents = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
      
        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
     

       

         
        getCustomers()
        {
            this.loadingClients = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingClients = false;
				})
			;
        },

    }
}
</script>

<style>

</style>