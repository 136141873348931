<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col class="py-2">
                        <datePickerForm
                            label="Fecha"
                            :valueInput="values.date"
                            :rule="rules.required"
                            @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                        ></datePickerForm>
                    </v-col>
                </v-row>

                <v-row>
                    
                    <v-col class="py-2">
                        <div class="loan-select-switch-container">
                            <autocompleteOneForm
                                v-if="!values.creditor_is_construction"
                                class="loan-select-special"
                                label="Acreedor"
                                :loading="false"
                                :valueInput="values.creditor_id"
                                :rule="rules.required"
                                :items="serverItems.businessesCreditor"
                                :errorMessages="serverErrors.creditor_id"
                                @valueChange="(v) => {values.creditor_id = v;resetCreditor(); getReferences(v,'loadingCreditorReferences', 'creditorReferences');  getBankAccounts(v,'loadingBankAccountsCreditor', 'bankAccountsCreditor'); delete serverErrors.creditor_id}"
                            ></autocompleteOneForm>
                            <autocompleteOneForm
                                v-if="values.creditor_is_construction"
                                class="loan-select-special"
                                label="Acreedor"
                                :loading="false"
                                :valueInput="values.creditor_id"
                                :rule="rules.required"
                                :items="serverItems.constructions"
                                :errorMessages="serverErrors.creditor_id"
                                @valueChange="(v) => {values.creditor_id = v; delete serverErrors.creditor_id}"
                            ></autocompleteOneForm>
                            <switchForm
                                v-if="this.$store.state.business == 'merca-construcciones-del-centro-sa-de-cv'"
                                title=""
                                class="ml-2"
                                leftLabel="Empresa"
                                :rightLabel="'Obra'"
                                :valueInput="values.creditor_is_construction"
                                :alwasActive="false"
                                @valueChange="(v) => {values.creditor_is_construction = v}"
                            ></switchForm>
                        </div>
                       
                    </v-col>
                    <!-- <v-col class="py-2" cols="4"> -->
                       
                    <!-- </v-col> -->
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Cuenta bancaria del acreedor"
                            :loading="loadingBankAccountsCreditor"
                            :valueInput="values.creditor_bank_account_id"
                            :rule="rules.required"
                            :items="serverItems.bankAccountsCreditor"
                            :errorMessages="serverErrors.creditor_bank_account_id"
                            @valueChange="(v) => {values.creditor_bank_account_id = v; delete serverErrors.creditor_bank_account_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
               
               

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            :disabled="lockCreditorReferences"
                            label="Referencia del acreedor"
                            :loading="loadingCreditorReferences"
                            :valueInput="values.creditor_reference_id"
                            :rule="[]"
                            :items="serverItems.creditorReferences"
                            :errorMessages="serverErrors.creditor_reference_id"
                            @valueChange="(v) => {values.creditor_reference_id = v; delete serverErrors.creditor_reference_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                        <div class="loan-select-switch-container">
                            <autocompleteOneForm
                                v-if="!values.debtor_is_construction"
                                class="loan-select-special"
                                label="Deudor"
                                :loading="false"
                                :valueInput="values.debtor_id"
                                :rule="rules.required"
                                :items="serverItems.businessesDebtor"
                                :errorMessages="serverErrors.debtor_id"
                                @valueChange="(v) => {values.debtor_id = v; resetDebtor(); getReferences(v,'loadingDebtorReferences', 'debtorReferences');  getBankAccounts(v,'loadingBankAccountsDebtor', 'bankAccountsDebtor'); delete serverErrors.debtor_id}"
                            ></autocompleteOneForm>
                            <autocompleteOneForm
                                v-if="values.debtor_is_construction"
                                class="loan-select-special"
                                label="Deudor"
                                :loading="false"
                                :valueInput="values.debtor_id"
                                :rule="rules.required"
                                :items="serverItems.constructions"
                                :errorMessages="serverErrors.debtor_id"
                                @valueChange="(v) => {values.debtor_id = v; delete serverErrors.debtor_id}"
                            ></autocompleteOneForm>
                            <switchForm
                                v-if="this.$store.state.business == 'merca-construcciones-del-centro-sa-de-cv'"
                                title=""
                                class="ml-2"
                                leftLabel="Empresa"
                                :rightLabel="'Obra'"
                                :valueInput="values.debtor_is_construction"
                                :alwasActive="false"
                                @valueChange="(v) => {values.debtor_is_construction = v}"
                            ></switchForm>
                        </div>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Cuenta bancaria del deudor"
                            :loading="loadingBankAccountsDebtor"
                            :valueInput="values.debtor_bank_account_id"
                            :rule="rules.required"
                            :items="serverItems.bankAccountsDebtor"
                            :errorMessages="serverErrors.debtor_bank_account_id"
                            @valueChange="(v) => {values.debtor_bank_account_id = v; delete serverErrors.debtor_bank_account_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            :disabled="lockDebtorReferences"
                            label="Referencia del deudor"
                            :loading="loadingDebtorReferences"
                            :valueInput="values.debtor_reference_id"
                            :rule="[]"
                            :items="serverItems.debtorReferences"
                            :errorMessages="serverErrors.debtor_reference_id"
                            @valueChange="(v) => {values.debtor_reference_id = v; delete serverErrors.debtor_reference_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>




                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                            :valueInput="values.amount" 
                            :rule="rules.amount" 
                            label="Monto"
                            :errorMessages="serverErrors.amount"
                            :prefix="(values.amount == '' || values.amount == null) ? '' : '$'"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            :loading="loadingConcepts"
                            label="Cuenta"
                            :valueInput="values.concept_id"
                            :rule="rules.required"
                            :items="serverItems.concepts"
                            :errorMessages="serverErrors.concept_id"
                            @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.comments = v;delete serverErrors.comments}" 
                            :valueInput="values.comments" 
                            :rule="[]" 
                            label="Comentarios"
                            :errorMessages="serverErrors.comments"
                            
                        ></textFieldForm>
                    </v-col>
                </v-row>
                        

                
                <v-row>
                    <v-col class="py-2">
                        <fileInputForm
                            :accept="'.pdf,.PDF'"
                            label="Adjunto"
                            :multiple="false"
                            :valueInput="values.file"
                            :url="values.file_url"
                            :rule="[]"
                            @valueChange="(v) => {values.file = v; delete serverErrors.file}"
                            :errorMessages="serverErrors.file"
                            v-on:update:url="values.file_url = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row>
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingCreditorReferences: false,
            loadingBankAccountsCreditor: false,
            lockCreditorReferences: false,

            loadingDebtorReferences: false,
            loadingBankAccountsDebtor: false,
            lockDebtorReferences: false,
            loadingConcepts: false,
            file: [],
            
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
				
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
              
				
            },
           
            serverItems: {
                businessesDebtor: [],
                businessesCreditor: [],
                creditorReferences: [],
                bankAccountsCreditor: [],

                debtorReferences: [],
                bankAccountsDebtor: [],

                concepts: [],

                constructions:[],
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        },
        [`this.$store.state`]: function (val) {
            console.log(val, "store");
        },
        [`values.creditor_is_construction`]: function (val) {
            //false => business
            //true => costruction
            this.values.creditor_id = null;
            this.lockCreditorReferences = false;
            this.values.creditor_bank_account_id = null;
            if(val){
                // this.loadConstructions();
                this.values.creditor_reference_id = null;
                this.lockCreditorReferences = true;
                //load merca construcciones accounts
                
                this.getBankAccounts("merca-construcciones-del-centro-sa-de-cv", 'loadingBankAccountsCreditor', 'bankAccountsCreditor');
            }

        },
        [`values.debtor_is_construction`]: function (val) {
            //false => business
            //true => costruction
            this.values.debtor_id = null;
            this.lockDebtorReferences = false;
            this.values.debtor_bank_account_id = null;
            if(val){
                // this.loadConstructions();
                this.values.debtor_reference_id = null;
                this.lockDebtorReferences = true;
                //load merca construcciones accounts
                
                this.getBankAccounts("merca-construcciones-del-centro-sa-de-cv", 'loadingBankAccountsDebtor', 'bankAccountsDebtor');
            }

        }
    },
    mounted(){
        this.loadConstructions();
        
        this.getConcepts();
        if(this.edit){
            this.loadBusinessesEditMode();
            //creditor
            if(!this.values.creditor_is_construction){
                this.getReferences(this.values.creditor_id, 'loadingCreditorReferences', 'creditorReferences');
                this.getBankAccounts(this.values.creditor_id, 'loadingBankAccountsCreditor', 'bankAccountsCreditor');
            }else{
                this.lockCreditorReferences = true;
                this.getBankAccounts("merca-construcciones-del-centro-sa-de-cv", 'loadingBankAccountsCreditor', 'bankAccountsCreditor');
            }

            //debtor
            if(!this.values.debtor_is_construction){
                this.getReferences(this.values.debtor_id, 'loadingDebtorReferences', 'debtorReferences');
                this.getBankAccounts(this.values.debtor_id, 'loadingBankAccountsDebtor', 'bankAccountsDebtor');
            }else{
                this.lockDebtorReferences = true;
                this.getBankAccounts("merca-construcciones-del-centro-sa-de-cv", 'loadingBankAccountsDebtor', 'bankAccountsDebtor');
            }
        }else{
            this.loadBusinesses();
            this.getReferences(this.values.debtor_id, 'loadingDebtorReferences', 'debtorReferences');
            this.getBankAccounts(this.values.debtor_id, 'loadingBankAccountsDebtor', 'bankAccountsDebtor');
        }
        //this.getExchange();
    },
    methods: {
        resetDebtor()
        {
             
            this.values.debtor_bank_account_id = this.values.debtor_reference_id = "";
        },
        resetCreditor()
        {
            this.values.creditor_bank_account_id = this.values.creditor_reference_id = "";
        },

        loadBusinesses()
        {
            let found = this.$store.state.userData.business.find((item) => item.slug == this.$store.state.business);
            this.serverItems.businessesDebtor.push({
                text: found.trade_name, value: found.slug
            });
            this.serverItems.businessesCreditor = this.$store.state.userData.business.map((item) => {
                return {text: item.trade_name, value: item.slug}
            });
        },
        loadBusinessesEditMode()
        {
            let found = this.$store.state.userData.business.find((item) => item.slug == this.$store.state.business);
            console.log(found);
            this.serverItems.businessesCreditor.push({
                text: found.trade_name, value: found.slug
            });
            this.serverItems.businessesDebtor = this.$store.state.userData.business.map((item) => {
                return {text: item.trade_name, value: item.slug}
            });
        },
        loadConstructions()
        {
            this.$api.construction.getSelect(this.$store.state.business)
                .then((resp) => {

                    this.serverItems.constructions = resp.data.map((item) => { 
                        return {text: item.name, value: item.value}
                    });


                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConstructions = false;
                })
            ;
            console.log(this.$store.state)
           
        },

        getReferences(business, loading, item)
        {
            if(business  == "" || business == undefined || business == null)
                return;
            if(item  == "" || item == undefined || item == null)
                return;
            this[`${loading}`] = true;
            this.serverItems[`${item}`] = []
            this.$api.reference.getSelectReferences(business)
                .then((resp) => {
                    this.serverItems[`${item}`] = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this[`${loading}`] = false;
                })
            ;
        },

        getBankAccounts(business, loading, item)
        {
            if(business  == "" || business == undefined || business == null)
                return;
            if(item  == "" || item == undefined || item == null)
                return;
            this[`${loading}`] = true;
             this.serverItems[`${item}`] = []
            this.$api.bank.accountGetSelect(business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems[`${item}`].push({
                            text: element.text,
                            value: element.value,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this[`${loading}`] = false;
                })
            ;
        },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },

       
    }
}
</script>

<style lang="scss">
    .loan-select-switch-container{
        display: flex;
        justify-content: space-between;
    }
    .loan-select-special{
        flex: auto;
        // margin-right: 10px;
    }
</style>