<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.payment_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.payment_date = v; delete serverErrors['payment_date']}"
                    :errorMessages="serverErrors['payment_date']"
                ></datePickerForm>
            </v-col>
        </v-row>
        

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Forma de pago"
                    :loading="loadingMethods"
                    :valueInput="values.payment_method_id"
                    :rule="rules.required"
                    :items="serverItems.methods"
                    :errorMessages="serverErrors.payment_method_id"
                    @valueChange="(v) => {values.payment_method_id = v; delete serverErrors.payment_method_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta bancaria"
                    :loading="loadingBankAccounts"
                    :valueInput="values.bank_account_id"
                    :rule="rules.required"
                    :items="serverItems.bankAccounts"
                    :errorMessages="serverErrors.bank_account_id"
                    @valueChange="(v) => {values.bank_account_id = v; delete serverErrors.bank_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.pedimento = v; delete serverErrors.pedimento}" 
                    :valueInput="values.pedimento" 
                    :rule="rules.required" 
                    label="Pedimento"
                    :errorMessages="serverErrors.pedimento"
                ></textFieldForm>
            </v-col>
           
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comments"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF'"
                    label="Adjunto"
                    :multiple="false"
                    :valueInput="values.file_file"
                    :url="values.file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.file_file = v; delete serverErrors.file; delete serverErrors.file_file}"
                    :errorMessages="serverErrors.file"
                    v-on:update:url="values.file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

       

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="rules.amount" 
                    label="Monto"
                    :errorMessages="serverErrors.amount"
                    :prefix="values.amount === '' ? '' : '$'"
                   
                ></textFieldForm>
            </v-col>
           
        </v-row>

        
                
            
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
            loadingMethods: false,
            loadingBankAccounts: false,
            loadingClients: false,
           
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
			
                
               
             
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                
               
                
				
            },
            items:{
               
            },
            serverItems: {
               
                methods: [],
                bankAccounts: [],
                customers: []
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        
       // this.getReferences();
        this.getPaymentMethods();
        this.getBankAccounts();
        this.getCustomers();
    },
    methods: {

        
      
        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
     

        getPaymentMethods()
        {
           this.loadingMethods = true;
            this.$api.paymentMethod.getSelect()
                .then((resp) => {
                    this.serverItems.methods = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingMethods = false;
                })
            ;
        },

         
        getCustomers()
        {
            this.loadingClients = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingClients = false;
				})
			;
        },

    }
}
</script>

<style>

</style>