<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Agente aduanal"
                    :loading="loadingCustomsAgents"
                    :valueInput="values.customs_agent_id"
                    :rule="rules.required"
                    :items="serverItems.customsAgents"
                    :errorMessages="serverErrors.customs_agent_id"
                    @valueChange="(v) => {values.customs_agent_id = v; this.getAdvances(v); this.getCompensations(v); delete serverErrors.customs_agent_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.bill_date_emitted"
                    :rule="rules.required"
                    @valueChange="(v) => {values.bill_date_emitted = v; delete serverErrors['bill_date_emitted']}"
                    :errorMessages="serverErrors['bill_date_emitted']"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.traffic_number = v; delete serverErrors.traffic_number}" 
                    :valueInput="values.traffic_number" 
                    :rule="rules.required" 
                    label="Tráfico"
                    :errorMessages="serverErrors.traffic_number"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.pedimento = v; delete serverErrors.pedimento}" 
                    :valueInput="values.pedimento" 
                    :rule="rules.required" 
                    label="Pedimento"
                    :errorMessages="serverErrors.pedimento"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <autocompleteOneForm
                    
                    label="Clave"
                    :valueInput="values.pedimento_key"
                    :rule="rules.required"
                    :items="items.customsDocuments"
                    :errorMessages="serverErrors.pedimento_key"
                    @valueChange="(v) => {values.pedimento_key = v; delete serverErrors.pedimento_key}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.container_number = v; delete serverErrors.container_number}" 
                    :valueInput="values.container_number" 
                    :rule="[]" 
                    label="Contenedor"
                    :errorMessages="serverErrors.container_number"
                ></textFieldForm>
                
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.folio = v; delete serverErrors.folio}" 
                    :valueInput="values.folio" 
                    :rule="rules.required" 
                    label="Folio factura"
                    :errorMessages="serverErrors.folio"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row >
            <v-col class="py-2">
                <switchForm
                    title="Esta cuenta se paga con anticipos"
                    leftLabel="No"
                    :rightLabel="'Si'"
                    :valueInput="values.has_advances"
                    :disable="edit"
                    @valueChange="(v) => {values.has_advances = v; this.totalTotalConsideration()}"
                ></switchForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; delete serverErrors.customer_id; this.getWarranties(v); this.getDelays(v); this.totalTotalConsideration()}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :loading="loadingConcepts"
                    label="Cuenta"
                    :valueInput="values.concept_id"
                    :rule="rules.required"
                    :items="serverItems.concepts"
                    :errorMessages="serverErrors.concept_id"
                    @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


       


        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    
                    label="Factura XML"
                    :multiple="false"
                    :valueInput="file"
                    
                    :accept="'text/xml'"
                    @valueChange="(v) => {this.readXML(v)}"
                    :errorMessages="serverErrors.photo_file"
                    @cleared:file="clearInputs"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.fee = v;this.totalTotalConsideration(); delete serverErrors.fee}" 
                    :valueInput="values.fee" 
                    :rule="rules.amount" 
                    label="Honorarios"
                    :errorMessages="serverErrors.fee"
                    :prefix="values.fee === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>

            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva_fee = v; this.totalTotalConsideration();delete serverErrors.iva_fee}" 
                    :valueInput="values.iva_fee" 
                    :rule="rules.amount" 
                    label="IVA honorarios"
                    :errorMessages="serverErrors.iva_fee"
                    :prefix="values.iva_fee === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>

           
        </v-row>

         <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.considerations = v; this.totalTotalConsideration(); delete serverErrors.considerations}" 
                    :valueInput="values.considerations" 
                    :rule="rules.amount" 
                    label="Compensación"
                    :errorMessages="serverErrors.considerations"
                    :prefix="values.considerations === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>

            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva_considerations = v; this.totalTotalConsideration();delete serverErrors.iva_considerations}" 
                    :valueInput="values.iva_considerations" 
                    :rule="rules.amount"
                    label="IVA Compensación"
                    :errorMessages="serverErrors.iva_considerations"
                    :prefix="values.iva_considerations === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            
           
        </v-row>
        
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.validation = v;this.totalTotalConsideration();delete serverErrors.validation}" 
                    :valueInput="values.validation" 
                    :rule="rules.amount" 
                    label="Validación"
                    :errorMessages="serverErrors.validation"
                    :prefix="values.validation === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>

            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva_validation = v;this.totalTotalConsideration();delete serverErrors.iva_validation}" 
                    :valueInput="values.iva_validation" 
                    :rule="rules.amount" 
                    label="IVA Validación"
                    :errorMessages="serverErrors.iva_validation"
                    :prefix="values.iva_validation === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>

            
            
        </v-row>
        
        
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.total_fee = v; delete serverErrors.total_fee}" 
                    :valueInput="values.total_fee" 
                    :rule="rules.amount" 
                    label="Total honorarios"
                    :errorMessages="serverErrors.total_fee"
                    :prefix="values.total_fee === '' ? '' : '$'"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Divisa"
                    :valueInput="values.currency"
                    :rule="rules.required"
                    :items="items.currency"
                    :errorMessages="serverErrors.currency"
                    @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                    :disabled="xml === 1"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.uuid = v;delete serverErrors.uuid}" 
                    :valueInput="values.uuid" 
                    :rule="[]" 
                    label="UUID"
                    :errorMessages="serverErrors.uuid"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.fiscal_padlock = v; this.totalTotalCG(); delete serverErrors.fiscal_padlock}" 
                    :valueInput="values.fiscal_padlock" 
                    :rule="rules.amount" 
                    label="Candado fiscal"
                    :errorMessages="serverErrors.fiscal_padlock"
                    :prefix="values.fiscal_padlock === '' ? '' : '$'"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.extra_services = v; this.totalTotalCG(); delete serverErrors.extra_services}" 
                    :valueInput="values.extra_services" 
                    :rule="rules.amount" 
                    label="Servicios extra"
                    :errorMessages="serverErrors.extra_services"
                    :prefix="values.extra_services === '' ? '' : '$'"
                ></textFieldForm>
               
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.customs_examination = v;this.totalTotalCG(); delete serverErrors.customs_examination}" 
                    :valueInput="values.customs_examination" 
                    :rule="rules.amount" 
                    label="Reconocimiento aduanero"
                    :errorMessages="serverErrors.customs_examination"
                    :prefix="values.customs_examination === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.taxes_additional_services = v;this.totalTotalCG(); delete serverErrors.taxes_additional_services}" 
                    :valueInput="values.taxes_additional_services" 
                    :rule="rules.amount" 
                    label="Impuestos por servicios adicionales "
                    :errorMessages="serverErrors.taxes_additional_services"
                    :prefix="values.taxes_additional_services === '' ? '' : '$'"
                ></textFieldForm>
               
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.american_account = v; delete serverErrors.american_account}" 
                    :valueInput="values.american_account" 
                    :rule="[]" 
                    label="Cuenta americana"
                    :errorMessages="serverErrors.american_account"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.taxes_pedimento = v; this.totalTotalCG();delete serverErrors.taxes_pedimento}" 
                    :valueInput="values.taxes_pedimento" 
                    :rule="rules.amount" 
                    label="Impuestos pedimentos"
                    :errorMessages="serverErrors.taxes_pedimento"
                    :prefix="values.taxes_pedimento === '' ? '' : '$'"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.damages_in_containers = v; this.totalTotalCG();delete serverErrors.damages_in_containers}" 
                    :valueInput="values.damages_in_containers" 
                    :rule="rules.amount" 
                    label="Daños a contenedores "
                    :errorMessages="serverErrors.damages_in_containers"
                    :prefix="values.damages_in_containers === '' ? '' : '$'"
                ></textFieldForm>
               
            </v-col>
        </v-row>

       
        
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.manoeuvres = v; this.totalTotalCG();delete serverErrors.manoeuvres}" 
                    :valueInput="values.manoeuvres" 
                    :rule="rules.amount" 
                    label="Maniobras"
                    :errorMessages="serverErrors.manoeuvres"
                    :prefix="values.manoeuvres === '' ? '' : '$'"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva_manoeuvres = v; this.totalTotalCG();delete serverErrors.iva_manoeuvres}" 
                    :valueInput="values.iva_manoeuvres" 
                    :rule="rules.amount" 
                    label="IVA maniobras"
                    :errorMessages="serverErrors.iva_manoeuvres"
                    :prefix="values.iva_manoeuvres === '' ? '' : '$'"
                ></textFieldForm>
               
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    ref="advancesField"
                    :loading="loadingAdvances"
                    label="Anticipos"
                    :valueInput="values.advance_id"
                    :rule="rules.test"
                    :items="serverItems.advances"
                    :errorMessages="serverErrors.advance_id"
                    @valueChange="(v) => {values.advance_id = v; this.validateAdvances(); this.totalTotalConsideration(); delete serverErrors.advance_id; delete serverErrors.compensation_id;}"
                    :disabled="values.has_advances && !edit ? false : true"
                    :returnObject="true"
                ></autocompleteOneForm>
            </v-col>
            <v-col class="py-2">
                <autocompleteOneForm
                    ref="compenField"
                    :loading="loadingCompensations"
                    label="Aplicación de saldo a favor"
                    :valueInput="values.compensation_id"
                    :rule="rules.test"
                    :items="serverItems.compensations"
                    :errorMessages="serverErrors.compensation_id"
                    @valueChange="(v) => {values.compensation_id = v; this.validateAdvances(); this.totalTotalConsideration(); delete serverErrors.compensation_id; delete serverErrors.advance_id}"
                    :disabled="values.has_advances && !edit ? false : true"
                    :returnObject="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    ref="warrantiesField"
                    :loading="loadingWarranties"
                    label="Garantías"
                    :valueInput="values.warranty_id"
                    :rule="[]"
                    :items="serverItems.warranties"
                    :errorMessages="serverErrors.warranty_id"
                    @valueChange="(v) => {values.warranty_id = v; this.validateAdvances(); this.totalTotalConsideration(); delete serverErrors.warranty_id; }"
                    :disabled="edit? true: false"
                    :returnObject="true"
                ></autocompleteOneForm>
            </v-col>
            <v-col class="py-2">
                <autocompleteOneForm
                    ref="delaysField"
                    :loading="loadingDelays"
                    label="Demoras"
                    :valueInput="values.demora_id"
                    :rule="[]"
                    :items="serverItems.delays"
                    :errorMessages="serverErrors.demora_id"
                    @valueChange="(v) => {values.demora_id = v; this.validateAdvances(); this.totalTotalConsideration(); delete serverErrors.demora_id; }"
                    :disabled="edit? true: false"
                    :returnObject="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

         <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.warranties = v;this.totalTotalCG(); delete serverErrors.warranties}" 
                    :valueInput="values.warranties" 
                    :rule="[...rules.amount, ...rules.warrantyMax]" 
                    label="Monto de las garantías"
                    :errorMessages="serverErrors.warranties"
                    :prefix="values.warranties === '' ? '' : '$'"
                    :disabled="values.warranty_id && !edit ? false:true"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.demoras = v;this.totalTotalCG(); delete serverErrors.demoras}" 
                    :valueInput="values.demoras" 
                    :rule="[...rules.amount, ...rules.delayMax]" 
                    label="Monto de las demoras"
                    :errorMessages="serverErrors.delays"
                    :prefix="values.demoras === '' ? '' : '$'"
                    :disabled="values.demora_id && !edit? false:true"
                ></textFieldForm>
               
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.total_cg = v; delete serverErrors.total_cg}" 
                    :valueInput="values.total_cg" 
                    :rule="rules.amount" 
                    label="Total CG"
                    :errorMessages="serverErrors.total_cg"
                    :prefix="values.total_cg === '' ? '' : '$'"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.total = v; delete serverErrors.total}" 
                    :valueInput="values.total" 
                    :rule="[]" 
                    label="Total a pagar"
                    :errorMessages="serverErrors.total"
                    :prefix="values.total === '' ? '' : '$'"
                    :disabled="true"
                ></textFieldForm>
               
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Días de crédito"
                    :valueInput="values.credit_period"
                    :rule="rules.required"
                    :items="items.credit_period"
                    :errorMessages="serverErrors.credit_period"
                    @valueChange="(v) => {values.credit_period = v; delete serverErrors.credit_period}"
                ></autocompleteOneForm>
            </v-col>

            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.current_mxn_per_usd = v; delete serverErrors.current_mxn_per_usd}" 
                    :valueInput="values.current_mxn_per_usd" 
                    :rule="rules.amount" 
                    label="Tipo de cambio mes"
                    :errorMessages="serverErrors.current_mxn_per_usd"
                    :prefix="values.current_mxn_per_usd === '' ? '' : '$'"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
             <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comments"
                   
                ></textFieldForm>
               
            </v-col>
        </v-row>




       
        

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF'"
                    label="Archivos de soporte"
                    :multiple="true"
                    :valueInput="values.files"
                    :url="''"
                    :rule="[]"
                    @valueChange="(v) => {values.files = v; delete serverErrors.files}"
                    :errorMessages="serverErrors.files"
                    
                ></fileInputForm>
            </v-col>
        </v-row>


        <v-row :key="updt">
            <v-col>
                
                <div v-for="(item, index) in values.files_saved" :key="index">
                    <v-row justify="space-between" class="mx-2 my-1" v-if="item._delete == 0">
                        <span>{{item.name}}</span>
                        <div>
                            <v-btn
                                icon
                                color=""
                                @click="() => {openFile(item.file_url)}"
                                >
                                <v-icon>mdi-information-outline</v-icon>
                            </v-btn>

                            <v-btn
                                icon
                                color="warning"
                                @click="() => {deleteFile(item)}"
                                >
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </div>
                    </v-row>
                </div>

            </v-col>
            
        </v-row>
                
            
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            updt: 0,
            loadingProvider: false,
            loadingReferences: false,
            loadingConcepts: false,
            loadingCustomsAgents: false,
            loadingClients: false,
            loadingAdvances:false,
            loadingCompensations: false,
            loadingWarranties: false,
            loadingDelays: false,
            file: [],
            xml: 0,
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
			
                
               
             
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v === undefined)
                            return 'El campo es requerido';
                        if(v === null || ''+v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        return true;
                    }
                ],

                test: [
                    v => {
                        if(this.values.has_advances){
                            if((this.values.advance_id == "" || this.values.advance_id == undefined || this.values.advance_id == null) && 
                            (this.values.compensation_id == "" || this.values.compensation_id == undefined || this.values.compensation_id == null) )
                                return "Selecciona al menos un anticipo o una compenzación"
                        }
                        return true;
                    }
                ],

                warrantyMax: [
                    v => {
                        if(this.values.warranty_id != null && this.values.warranty_id != "" && this.values.warranty_id != undefined){
                            if(v > this.values.warranty_id.total)
                                return "El moto sobrepasa la garantía seleccionada";
                        }

                        return true;
                    }
                ],

                delayMax: [
                    v => {
                        if(this.values.demora_id != null && this.values.demora_id != "" && this.values.demora_id != undefined){
                            if(v > this.values.demora_id.total)
                                return "El moto sobrepasa la demora seleccionada";
                        }

                        return true;
                    }
                ]
                
               
                
				
            },
            items:{
                types:[ 
                    {text: "PAGO", value: "PAGO"},
                    {text: "ANTICIPO", value: "ANTICIPO"},
                    {text: "GARANTÍA", value: "GARANTÍA"}
                ],
                currency: [
                    {text: "MXN", value: "MXN"},
                    {text: "USD", value: "USD"}
                ],
                credit_period:[
                    {text: "0 DÍAS", value: '0'},
                    {text: "7 DÍAS", value: 7},
                    {text: "15 DÍAS", value: 15},
                    {text: "30 DÍAS", value: 30},
                    {text: "60 DÍAS", value: 60},
                    {text: "90 días", value: 90}
                ],
               
                customsDocuments: [
                    {text:"A1", value: "A1"},
                    {text:"A3", value: "A3"},
                    {text:"AF", value: "AF"},
                    {text:"BM", value: "BM"},
                    {text:"I1", value: "I1"},
                    {text:"H1", value: "H1"},
                    {text:"IN", value: "IN"},
                    {text:"RT", value: "RT"},
                    {text:"J4", value: "J4"},
                    {text:"V1", value: "V1"},
                    {text:"F4", value: "F4"},
                    {text:"M3", value: "M3"},
                    {text:"G9", value: "G9"},
                    {text:"R1", value: "R1"},
                ]
            },
            serverItems: {
                providers: [],
                references: [],
                
                customsAgents: [],
                customers: [],
                advances:[],
                compensations:[],
                warranties:[],
                delays:[],
                concepts: [],
            },

          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        },
        'values.customs_agent_id': function(v) {
            console.log(v);
            this.clear();
            // this.getPayableAccounts(v);
        }
    },
    mounted(){
        
       // this.getReferences();
        this.getConcepts();
        this.getCustomsAgents();
        this.getCustomers();
        if(this.edit){
            this.loadAdvancesCompensationsEdit();
            this.loadWarrantiesDelaysEdit();
        }else{
            this.getAdvances(this.values.customs_agent_id);
            this.getCompensations(this.values.customs_agent_id);
            this.getExchange();
        }
    },
    methods: {
        openFile(url){
            window.open(url)
        },
        deleteFile(item)
        {
            item._delete = 1;
            this.updt = this.updt + 1;
        },
        getExchange()
        {
            if(this.edit)
                return;
            this.$api.utils.getExchange()
                .then((resp) => {
                    this.values.current_mxn_per_usd =  parseFloat(resp.data.usd_to_mxn).toFixed(2);
                })
                .catch((error) => {
                    console.log(error);
                    this.values.current_mxn_per_usd = '';
                })
            ;
        },

        validateAdvances()
        {
            
            this.$refs.advancesField.$children[0].validate();
            this.$refs.compenField.$children[0].validate();
        },

        loadAdvancesCompensationsEdit()
        {
            if(this.edit){
                if(typeof this.values.advance_id  === 'object')
                    this.serverItems.advances = [this.values.advance_id];
                if(typeof this.values.compensation_id  === 'object')
                    this.serverItems.compensations = [this.values.compensation_id];

                this.totalTotalConsideration();
            }
        },

        loadWarrantiesDelaysEdit()
        {
            if(this.edit){
                if(typeof this.values.demora_id  === 'object')
                    this.serverItems.delays = [this.values.demora_id];
                if(typeof this.values.warranty_id  === 'object')
                    this.serverItems.warranties = [this.values.warranty_id];

                this.totalTotalConsideration();
            }
        },


        totalTotalConsideration()
        {
            
            this.values.total_fee =  parseFloat((this.values.fee??"0")) + 
                                                parseFloat((this.values.validation??"0"))+
                                                parseFloat((this.values.considerations??"0"))+
                                                parseFloat((this.values.iva_fee??"0"))+
                                                parseFloat((this.values.iva_validation??"0"))+
                                                parseFloat((this.values.iva_considerations??"0"));
            //delete this.serverErrors.total;
            // console.log(this.values.total_fee, "FEEEEEEEEEEEEEEEEEEEE")
             if(isNaN(this.values.total_fee))
                this.values.total_fee = 0;
            if(this.values.total_fee < 0)
                this.values.total_fee = 0;
            this.values.total_fee = parseFloat(''+this.values.total_fee).toFixed(4);
            this.totalTotalCG();
        },

        totalTotalCG()
        {
            delete this.serverErrors.total_cg;
            this.values.total_cg = parseFloat((this.values.fiscal_padlock??"0")) +
                                    parseFloat((this.values.extra_services??"0")) +
                                    parseFloat((this.values.customs_examination??"0")) +
                                    parseFloat((this.values.taxes_additional_services??"0")) +
                                    parseFloat((this.values.taxes_pedimento??"0")) +
                                    parseFloat((this.values.damages_in_containers??"0")) +
                                    parseFloat((this.values.manoeuvres??"0")) +
                                    parseFloat((this.values.iva_manoeuvres??"0")) +
                                    parseFloat((this.values.total_fee??"0")) -
                                    parseFloat((this.values.warranties??"0")) -
                                    parseFloat((this.values.demoras??"0")); 

            
            //console.log(this.values.total_cg, "aquiaquiauiauaiuaiua")
            if(isNaN(this.values.total_cg))
                this.values.total_cg = 0;
            if(this.values.total_cg < 0)
                this.values.total_cg = 0;
            this.values.total_cg = parseFloat(""+this.values.total_cg).toFixed(4);
            this.totalTotal();
        },

        totalTotal()
        {
            delete this.serverErrors.total;
            let advancesCompen = 0;
            let result = parseFloat((this.values.total_cg??"0")) - 0;
            if(this.values.has_advances){
                let advances = this.values.advance_id?.total??0;
                let compensation = this.values.compensation_id?.total??0;
                advances = isNaN(advances) ? 0 : advances;
                compensation = isNaN(compensation) ? 0 : compensation;

                advancesCompen = advances + compensation;
               // console.log(advancesCompen)
            }
            let finalResult =  result - advancesCompen;
            if(finalResult < 0)
                finalResult = 0;
            
            this.values.total = finalResult;
            this.values.total = parseFloat(""+this.values.total).toFixed(4);
        },

        getWarranties(customer)
        {
            this.serverItems.warranties = [];
            this.values.warranty_id = "";
            this.values.warranties = 0;
            if(customer == "" || customer == undefined)
                return;
            this.loadingWarranties = true;
            this.$api.warranty.getSelectByCustomer(this.$store.state.business, customer)
				.then((resp) =>{
					console.log(resp, "desde warranties");
					this.serverItems.warranties = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingWarranties = false;
				})
			;
        },

        getDelays(customer)
        {
            this.serverItems.delays = [];
            this.values.demora_id = "";
            this.values.demoras = 0;
            if(customer == "" || customer == undefined)
                return;
            this.loadingDelays = true;
            this.$api.delay.getSelectByCustomer(this.$store.state.business, customer)
				.then((resp) =>{
					console.log(resp, "desde delays");
					this.serverItems.delays = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingDelays = false;
				})
			;
        },

        getAdvances(customAgent = "")
        {
            if(customAgent == "" || customAgent == undefined)
                return;
            this.loadingAdvances = true;
            this.$api.advance.getSelect(this.$store.state.business, customAgent)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.advances = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingAdvances = false;
				})
			;
        },

        getCompensations(customAgent)
        {
            if(customAgent == "" || customAgent == undefined)
                return;
            this.loadingCompensations = true;
            this.$api.advance.getSelectCompensations(this.$store.state.business, customAgent)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.compensations = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCompensations = false;
				})
			;
        },


        getCustomsAgents()
        {
            this.loadingCustomsAgents = true;
            this.$api.customAgent.getSelect(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customsAgents = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCustomsAgents = false;
				})
			;
        },

      
        getProviders()
        {
            this.loadingProvider = true;
            this.$api.supplier.getSelectSuppliers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.providers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingProvider = false;
				})
			;
        },

        getReferences()
        {
            this.loadingReferences = true;
            this.$api.reference.getSelectReferences(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.references = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingReferences = false;
                })
            ;
        },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },

         
        getCustomers()
        {
            this.loadingClients = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingClients = false;
				})
			;
        },

        total() {
            const taxes = ((parseFloat(this.values.iva) + parseFloat(this.values.ieps)) - (parseFloat(this.values.iva_withholdings)  + parseFloat(this.values.isr_withholdings) + parseFloat(this.values.discounts)));
            this.values.total =  parseFloat(this.values.subtotal) + parseFloat(taxes);
            delete this.serverErrors.total;
        },

        readXML(file) {
            if (file !== null) {
                let formdata = new FormData();
                formdata.append("xml_archive", file);
                this.$api.agentPayableAccount.readXML(formdata)
                .then((response) => {
                    // this.values.taxes = response.data.taxes;
                    this.values.fee = parseFloat(response.data.fee).toFixed(4);
                    this.values.iva_fee = parseFloat(response.data.iva_fee).toFixed(4) ;
                    this.values.validation = parseFloat(response.data.validation).toFixed(4) ;
                    this.values.considerations = parseFloat(response.data.considerations).toFixed(4);
                    this.values.customs_examination = parseFloat(response.data.customs_examination).toFixed(4);
                    this.values.iva_validation = (parseFloat(response.data.iva_validation??0).toFixed(4)??0);
                    this.values.iva_considerations = (parseFloat(response.data.iva_considerations??0).toFixed(4)??0);
                    this.values.uuid = response.data.uuid;
                    this.values.currency = response.data.currency;
                    this.xml = 1;
                    this.totalTotalConsideration();
                })
                .catch((error) => {
                    alert(error);
                });
            }
        },

        clearInputs() {
            this.xml = 0;
            // this.values.taxes = 0;
            this.values.fee = 0;
            this.values.iva_fee = 0;
            this.values.validation = 0;
            this.values.considerations = 0;
            this.values.uuid = "";

            console.log(this.values, file)
        },

        clear(){
            this.serverItems.advances = this.serverItems.compensations = [];
            this.values.advance_id = this.values.compensation_id = "";

        }
    }
}
</script>

<style>

</style>