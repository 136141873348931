<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
                    
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" class="mx-0 ">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary">
                                        <v-col cols="12" sm="6" class="title-pending py-1 pb-0 text-sm-start text-center mb-3 mb-sm-0">
                                            <span >Flujo total para préstamo</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-end text-center">
                                           
                                            <span>{{totalReport.mxn}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                    <v-row class="primary">
                                        <v-col cols="12" sm="6" class="pt-0 pb-1 text-sm-start text-center">
                                            <span></span>
                                        </v-col>
                                        <v-col class="pt-0 pb-1 text-sm-end text-center">
                                           
                                            <span>{{totalReport.usd}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                  
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable  :props="tableProps" @methodHandler="method_handler">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,

            options: {},
            total: 10,
			search: '',
            selectedID: '',
            totalReport: {mxn: '$0.00 MXN', usd: '$0.00 USD'},
			
			tableProps: {
				headers: [
					{
						text: 'Acreedor',
						align: 'left',
						value: 'creditor_name',
						
						class: 'table-b-border black--text'
					},
					{
						text: 'Deudor',
						align: 'left',
						value: 'debtor_name',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Debe',
						align: 'right',
						value: 'amount',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Haber',
						align: 'right',
						value: 'payments',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo',
						align: 'right',
						value: 'balance',
                        
						class: 'table-b-border black--text'
					},
                   
					
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.export();}
            },
            pdfBtnData: {
                disabled:false,
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },

			
		}
	},

    watch: {
      
       
    },

	mounted(){
		this.index();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {

			this.tableProps.loading = true;
            this.tableProps.items = [];
            this.totalReport.mxn = Utils.currencyFormat(0, '');
            this.totalReport.usd = Utils.currencyFormat(0, '');
			//call api
			this.$api.report.reportLoansPending(this.$store.state.business)
				.then((resp) =>{
					console.log(resp);
                    let totalMxn = 0;
                    let totalUsd = 0;
                    // this.totalReport.usd = Utils.currencyFormat(resp.data.summary.total_usd, '');
					this.tableProps.items = resp.data.data.map((x) => {
						if(x.currency == "MXN"){
                            totalMxn = totalMxn + x.amount;
                        }else{
                            totalUsd = totalUsd + x.amount;
                        }
						x.amount = Utils.currencyFormat(x?.amount??0, x.currency);
						x.payments = Utils.currencyFormat(x?.payments??0, x.currency);
						x.balance = Utils.currencyFormat(x?.balance??0, x.currency);

						return x;
					});
                    this.totalReport.mxn = Utils.currencyFormat(totalMxn, "MXN");
                    this.totalReport.usd = Utils.currencyFormat(totalUsd, "USD");
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        export()
        {
          

            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportLoansPending(this.$store.state.business)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `loans.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
          
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.PdfReportLoansPending(this.$store.state.business)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `loans.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        
    
    }
}
</script>

<style lang="scss">
    .title-pending{
        transform: translate(0px, 12px) !important;
    }
</style>