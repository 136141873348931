import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=39c23bc5&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=39c23bc5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Index.vue?vue&type=custom&index=0&blockType=v-col&cols=12&md=3&xl=3&class=mx-0%20py-lg-0%20"
if (typeof block0 === 'function') block0(component)

export default component.exports