<template>
    <div>
       
      
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.key = v; delete serverErrors.key}" 
                    :valueInput="values.key" 
                    :rule="[...rules.required]" 
                    label="Clave"
                    :errorMessages="serverErrors.key"
                ></textFieldForm>
            </v-col>
        </v-row>

       
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
   
    data(){
        return {
           
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
				
				
            },
            

          
        }
    },
   
    mounted(){
        
    },
    methods: {

       
    }
}
</script>

<style>

</style>