<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center" >
                    <v-col cols="12" md="3" xl="3" class="mx-0 pr-0 pr-md-1 px-0 py-lg-0 py-1" >
                        <alternativeAutocompleteOne
                            :items="accounts"
                            :valueInput="accountItem"
                            :loading="accountLoading"
                            
                            @valueChange="(v) => {accountItem = v}"
                            @onChange="(v) => {index()}"
                            
                            :label="'Cuenta'"
                            :getObject="true"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>
					<v-col cols="12" md="2"  xl="2" class="mx-0 my-0 px-0 py-2">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>

						
					</v-col>
                   
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5"  xl="4" class="mx-0  mt-0 py-4">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-2 font-weight-bold">
                                <v-col>
                                    <v-row class="primary">
                                        <v-col cols="12" lg="4" class="py-1 ">
                                           <v-row>
                                                <v-col class="text-lg-start text-center">
                                                    <span>TOTAL:</span>
                                                </v-col>
                                                <v-col class="text-lg-end text-center">
                                                    <span class="nowrap">{{totalReport}}</span>
                                                </v-col>
                                           </v-row>
                                        </v-col>
                                        <v-col cols="12" lg="4" class="py-1">
                                           <v-row>
                                                <v-col class="text-lg-start text-center">
                                                    <span>Saldo inicial:</span>
                                                </v-col>
                                                <v-col class="text-lg-end text-center">
                                                    <span class="nowrap">{{initialReport}}</span>
                                                </v-col>
                                           </v-row>
                                        </v-col>
                                        <v-col cols="12" lg="4" class="py-1">
                                           <v-row>
                                                <v-col class="text-lg-start text-center">
                                                    <span>Saldo final:</span>
                                                </v-col>
                                                <v-col class="text-lg-end text-center">
                                                    <span class="nowrap">{{finalReport}}</span>
                                                </v-col>
                                           </v-row>
                                        </v-col>
                                       
                                      
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',

            totalReport: '$0.00',
            initialReport: '$0.00',
            finalReport: '$0.00',

            accountLoading: false,
            accounts: [],
            accountItem: PageStateManager.getState(this.$router.history.current.path, 'accountItem')??'',

            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')??new Date().toISOString().split('T')[0]
            },
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Movimiento',
						align: 'center',
						value: 'type',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Folio',
						align: 'center',
						value: 'folio_int',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Referencia',
						align: 'center',
                        
						value: 'reference_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Concepto',
						align: 'left',
                        
						value: 'concept_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'right',
                        
						value: 'amount',
						class: 'table-b-border black--text'
					},
                    
				],
				items: [],
				search: '',
				loading: PageStateManager.getState(this.$router.history.current.path, 'accountItem') ? true : false,
                elevation: '0'
			},
			
			
            exportBtnData: {
               hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                disabled: true,
                click: ()=>{this.export();}
            },

            pdfBtnData: {
                disabled: true,
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },


			
		}
	},

    watch: {
        [`accountItem`]() {
            this.watchAccountItem();
        },
        [`dateRange.start_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
         
        }
    },

	mounted(){
		this.getAccounts();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.watchAccountItem();
        this.index();
	},

    methods: {
        watchAccountItem()
        {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'accountItem', this.accountItem);
            this.pdfBtnData.disabled = true;
            this.exportBtnData.disabled = true;
            if(this.accountItem != "" && this.accountItem != null && this.accountItem != undefined){
                this.pdfBtnData.disabled = false;
                this.exportBtnData.disabled = false;
            }
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
           
            if(this.accountItem == '' || this.accountItem == null || this.dateRange.start_date == '' || this.dateRange.end_date == '')
                return;
			this.tableProps.loading = true;
            this.totalReport =   this.initialReport = this.finalReport = Utils.currencyFormat(0, this.accountItem.currency??'');
         
            this.tableProps.items = [];
			//call api
			this.$api.report.reportPaymentBankAccount(this.$store.state.business, {"bank_account_id":this.accountItem.value, "start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
				.then((resp) =>{
					//console.log(resp);
                   
                    this.totalReport = Utils.currencyFormat(resp.data.total, this.accountItem.currency);
                    this.initialReport = Utils.currencyFormat(resp.data.initial_balance,  this.accountItem.currency);
                    this.finalReport = Utils.currencyFormat(resp.data.balance, this.accountItem.currency);

					this.tableProps.items = resp.data.data.map((x) => {
						
						x.amount = Utils.currencyFormat(x.amount??0, x.currency);
                        x.date = Utils.globalDateFormat(x.date);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getAccounts()
        {
            this.accountLoading = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    this.accounts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.accountLoading = false;
                })
            ;
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportPaymentBankAccount(this.$store.state.business, {"bank_account_id":this.accountItem.value, "start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `payments_by_bank_account.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportPaymentBankAccount(this.$store.state.business, {"bank_account_id":this.accountItem.value, "start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `payments_by_bank_account.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>