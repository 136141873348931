<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
                    <v-col cols="12" md="3" lg="3" xl="2" class="mx-0 px-0 mr-md-1 py-lg-0 ">
                        <alternativeAutocompleteOne
                            :items="folios"
                            :valueInput="start_folio"
                            @valueChange="(v) => {start_folio = v}"
                            @onChange="(v) => {index()}"
                            :label="'Folio inicial'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" xl="2" class="mx-0 px-0 mr-md-0 py-lg-0 ">
                        <alternativeAutocompleteOne
                            :items="folios"
                            :valueInput="end_folio"
                            @valueChange="(v) => {end_folio = v}"
                            @onChange="(v) => {index()}"
                            :label="'Folio final'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" class="mx-0 ">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-0 mr-md-2 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary">
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>ADEUDO EN MXN:</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-end text-center">
                                           
                                                <span>{{totalReport.mxn}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                    <v-row class="primary">
                                        <v-col class="pt-0 pb-1 text-sm-start text-center">
                                            <span>ADEUDO EN USD:</span>
                                        </v-col>
                                        <v-col class="pt-0 pb-1 text-sm-end text-center">
                                           
                                                <span>{{totalReport.usd}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable  :props="tableProps" @methodHandler="method_handler">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            start_folio: PageStateManager.getState(this.$router.history.current.path, 'start_folio')??"",
            end_folio: PageStateManager.getState(this.$router.history.current.path, 'end_folio')??"",
            folios: [],

            options: {},
            total: 10,
			search: '',
            selectedID: '',
            totalReport: {mxn: '$0.00', usd: '$0.00'},
			
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left',
						value: 'folio',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cliente',
						align: 'left',
						value: 'customer_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Referencia',
						align: 'center',
						value: 'reference_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Vencimiento',
						align: 'center',
						value: 'due_date',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Días vencido',
						align: 'center',
                        
						value: 'due_date_in_days',
						class: 'table-b-border black--text'
					},
                    {
						text: 'monto',
						align: 'right',
                        
						value: 'total',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Adeudo',
						align: 'right',
                        
						value: 'pending_payment',
						class: 'table-b-border black--text'
					},
					
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.export();}
            },
            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },

			
		}
	},

    watch: {
      
        [`start_folio`]() {
            PageStateManager.setState(this.$router.history.current.path, 'start_folio', this.start_folio);
           
        },
        [`end_folio`]() {
            PageStateManager.setState(this.$router.history.current.path, 'end_folio', this.end_folio);
           
        }
    },

	mounted(){
		this.index();
        this.getFolios();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
            if(this.start_folio == null || this.end_folio == null || this.start_folio == "" || this.end_folio == "")
                return;
            if(this.start_folio == this.end_folio)
                return;


			this.tableProps.loading = true;
            this.tableProps.items = [];
			//call api
			this.$api.report.reportBillingReceivableAccounts(this.$store.state.business, parseInt(this.start_folio), parseInt(this.end_folio))
				.then((resp) =>{
					//console.log(resp);
                    this.totalReport.mxn = Utils.currencyFormat(resp.data.summary.total_mxn, '');
                    this.totalReport.usd = Utils.currencyFormat(resp.data.summary.total_usd, '');
					this.tableProps.items = resp.data.data.map((x) => {
						
						x.pending_payment = Utils.currencyFormat(x?.pending_payment??0, x.currency);
						x.total = Utils.currencyFormat(x?.total??0, x.currency);
                        x.due_date = Utils.globalDateFormat(x.due_date);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        export()
        {
            if(this.start_folio == null || this.end_folio == null || this.start_folio == "" || this.end_folio == "")
                return;
            if(this.start_folio == this.end_folio)
                return;

            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportBillingReceivableAccounts(this.$store.state.business, this.start_folio, this.end_folio)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `${this.start_folio}_${this.end_folio}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            if(this.start_folio == null || this.end_folio == null || this.start_folio == "" || this.end_folio == "")
                return;
            if(this.start_folio == this.end_folio)
                return;

            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportBillingReceivableAccounts(this.$store.state.business, this.start_folio, this.end_folio)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `${this.start_folio}_${this.end_folio}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        getFolios()
        {
            this.$api.report.getFoliosReceivableAccounts(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp);
                    this.folios = resp.data.sort(function(a,b) {
                        return a.value - b.value;
                    });
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
        }

    }
}
</script>