<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar venta
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <cxc-form ref="CXCForm" :values="form" @save="save" :serverErrors="serverErrors"></cxc-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../helpers/Utils';
import CXCForm from "./Form.vue";
import Object2Form from "../../helpers/Object2Form";
export default {
    components: {
        'cxc-form': CXCForm,
    },
	data(){
		return {
            loading: false,
            slug: this.$route.params.slug,
            serverErrors: {},
            form: {
                bill_date_emitted: "",
                customer: "",
                folio: "",
                type: "",

                // taxes: "",
                discounts:0,
                ieps:0,
                iva:0,
                iva_withholdings:0,
                isr_withholdings:0,
                subtotal: 0,
                total: 0,
                currency: "",

                uuid: "",
                credit_period: "",
                current_mxn_per_usd:0,

                customer_id: "",
                reference_id: "",
                concept_id: "",
                account_id: "",
                sub_account_id: "",
                sub_sub_account_id: "",
                aux_id: "",
                comments: "",
                taxes_items: [ ],
                invoice_pdf_file: [],
                invoice_pdf_path: "",
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "CXC",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index()
        {
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.receivableAccount.get(this.$store.state.business, this.slug)
                .then((resp) => {
                    this.form = {...this.form,...resp.data};
                    this.form.customer_id = resp.data.customer.slug;
                    this.form.concept_id = resp.data.concept.slug;
                    this.form.reference_id = resp.data.reference.slug;
                    this.form.invoice_pdf_file = [];
                    if(resp.data.credit_period === 0)
                        this.form.credit_period = '0';
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                // let form = JSON.parse(JSON.stringify(this.form))
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.receivableAccount.update(this.$store.state.business,this.slug, this.prepareRequest(this.form))
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'CXC'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            
            data.invoice_pdf_file = request.invoice_pdf_file;
            


            if(request.invoice_pdf_path == '' && (request.invoice_pdf_file instanceof Array))
                data.invoice_pdf_file = null;

            if(request.invoice_pdf_path != '' && (request.invoice_pdf_file instanceof Array))
                delete data.invoice_pdf_file;

            if(data.type == "CONTADO"){
                delete data.credit_period;
            }

            console.log(data);
            return Object2Form.objectToFormData(data,'',[]);
        }
    },
}
</script>

<style>

</style>