<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col class="py-2">
                        <datePickerForm
                            label="Fecha"
                            :valueInput="values.date"
                            :rule="rules.required"
                            @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                        ></datePickerForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Proveedor"
                            :loading="loadingSupplier"
                            :valueInput="values.provider_id"
                            :rule="rules.required"
                            :items="serverItems.suppliers"
                            :errorMessages="serverErrors.provider_id"
                            @valueChange="(v) => {values.provider_id = v; this.clear();  getCreditNotes(v); values.credit_note_id = '';  delete serverErrors.provider_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>
               
                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Forma de pago"
                            :loading="loadingMethods"
                            :valueInput="values.payment_method_id"
                            :rule="rules.required"
                            :items="serverItems.methods"
                            :errorMessages="serverErrors.payment_method_id"
                            @valueChange="(v) => {
                                values.payment_method_id = v;
                                resetCurrency();
                                paymentMethodChanged(v); 
                                currencyUpdated();
                                exchangeUpdate(); 
                                delete serverErrors.payment_method_id
                            }"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row v-if="values.payment_method_id == 'descuento'">
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Divisa"
                            :valueInput="values.currency"
                            :rule="rules.required"
                            :items="items.currency"
                            :errorMessages="serverErrors.currency"
                            @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                            
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Nota de crédito"
                            :loading="loadingCreditNotes"
                            :valueInput="values.credit_note_id"
                            :rule="values.payment_method_id == 'notas-de-credito' ? rules.required:[]"
                            :items="serverItems.creditNotes"
                            :errorMessages="serverErrors.credit_note_id"
                            @valueChange="(v) => {values.credit_note_id = v; setAmountToTable(v); delete serverErrors.credit_note_id}"
                            :disabled="values.payment_method_id == 'notas-de-credito' ? false:true"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            :disabled="(values.payment_method_id === 'notas-de-credito' || values.payment_method_id === 'descuento')"
                            label="Cuenta bancaria"
                            :loading="loadingBankAccounts"
                            :valueInput="values.bank_account_id"
                            :rule="rules.bank"
                            :items="serverItems.bankAccounts"
                            :errorMessages="serverErrors.bank_account_id"
                            @valueChange="(v) => {
                                values.bank_account_id = v; 
                                bankAccountUpdated(v); 
                                
                                delete serverErrors.bank_account_id
                            }"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.commentary = v; delete serverErrors.commentary}" 
                            :valueInput="values.commentary" 
                            :rule="[]" 
                            label="Comentarios"
                            :errorMessages="serverErrors.commentary"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                 <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {
                                values.exchange_rate = v; 
                                currencyUpdated();
                                exchangeUpdate(); 
                                delete serverErrors.exchange_rate
                            }" 
                            :valueInput="values.exchange_rate" 
                            :rule="rules.current_mxn_per_usd" 
                            label="Tipo de cambio"
                            :errorMessages="serverErrors.exchange_rate"
                            :prefix="values.exchange_rate === '' ? '' : '$'"
                        ></textFieldForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                        <fileInputForm
                            
                            label="Adjunto"
                            :multiple="false"
                            :valueInput="values.vaucher_file"
                            :url="values.vaucher_path"
                            :rule="[]"
                            @valueChange="(v) => {values.vaucher_file = v; delete serverErrors.vaucher_file}"
                            :errorMessages="serverErrors.vaucher_file"
                            v-on:update:url="values.vaucher_path = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-0">
                        <h4>Cuentas por pagar</h4>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col class="py-2">
                        <autocompleteOneForm
                            
                            label="Cuenta por pagar"
                            :loading="loadingPayableAccounts"
                            :valueInput="values.payable_account_id"
                            :rule="[]"
                            :items="serverItems.payableAccounts"
                            :errorMessages="cxpSelectorError"
                            @valueChange="(v) => {values.payable_account_id = v; cxpSelectorError = ''; delete serverErrors.payable_account_id}"
                        ></autocompleteOneForm>
                    </v-col>
                    <v-col cols="4" sm="3" class="pl-0" >
                        
                        <primary-button :props="addBtnData" class=""></primary-button>
                       
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <table-items  :props="tableProps" @methodHandler="method_handler" :items="values.balanceables">
                            <template v-slot:item.folio="props">
                                <span class="body-2 break-words">{{props.item.folio}}</span>
                            </template>
                            <template v-slot:item.total_text="props">
                                <span class="body-2">{{ut.currencyFormat(props.item.total,props.item.currency)}}</span>
                            </template>
                            <template v-slot:item.amount="props">
                                <div class="table-item">
                                    <v-text-field  :rules="rules.amount"  prefix="$" :suffix="values.currency? values.currency : props.item.currency" v-model="props.item.amount[values.currency? values.currency : props.item.currency]" @keyup="() => {updateTableItem(props.item); clearError(props.item)}"></v-text-field>
                            
                                </div>
                            </template>
                            <template v-slot:item.balance_due="props">
                                <span class="body-2">{{ut.currencyFormat(props.item.balance_due[values.currency? values.currency : props.item.currency],props.item.currency)}}</span>
                            </template>
                        </table-items>
                       <v-input class="warning--text caption" :error-messages="serverErrors.balanceables" v-model="values.balanceables" :rules="rules.balanceables"></v-input>
                    </v-col>
                </v-row>
               
                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>
import TableItems from "../incomes/TableItems.vue";
import Utils from '../../helpers/Utils';

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit', 'inputCreditNotes'],
    components: {
       'table-items': TableItems
    },
    data(){
        return {
            cxpSelectorError: "",
            ut: Utils,
            loadingSupplier: false,
            loadingMethods: false,
            loadingBankAccounts: false,
            loadingPayableAccounts: false,
            loadingCreditNotes:false,
            originalCurrency: '',
            actions: [
				{
					name: 'Borrar',
					icon: {
						color: 'warning',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteTableItem'
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left',
						value: 'folio',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					{
						text: 'Adeudo',
						align: 'left',
						value: 'total_text',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'left',
						value: 'amount',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo',
						align: 'left',
						value: 'balance_due',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '5%',
						class: 'table-b-border black--text'
					}
				],
				items: this.values.balanceables,
				search: '',
				loading: false,
                keyValue: 0,
			},
            addBtnData: {
                text: "Agregar",
                	
                click: this.addCXP,
                block:true,
                loading: false,
            },
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                balanceables: [
                    v => {
                        if(this.values.balanceables.length === 0)
                            return 'Al menos una cuenta por pagar';
                        return true;
                    }
                ],
				
               
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                balanceables: [
                    v => {
                        if(v.length == 0)
                            return 'Al menos una cuenta por cobrar';
                        return true;
                    }
                ],
                
                bank: [(v) => !!(this.values.payment_method_id === 'notas-de-credito' || this.values.payment_method_id === 'descuento'? true:v) || "Banco es requerido"],
				current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
            },
            items:{
                types:[ 
                    {text: "NORMAL", value: "NORMAL"},
                    {text: "ANTICIPADA", value: "ANTICIPADA"}
                ],
                currency: [
                    {text: "MXN", value: "MXN"},
                    {text: "USD", value: "USD"}
                ],
                credit_period:[
                    {text: "0 DÍAS", value: '0'},
                    {text: "7 DÍAS", value: 7},
                    {text: "15 DÍAS", value: 15},
                    {text: "30 DÍAS", value: 30},
                    {text: "60 DÍAS", value: 60},
                    {text: "90 días", value: 90}
                ],
                receivableBackup: [],
            },
            serverItems: {
                suppliers: [],
                methods: [],
                bankAccounts: [],
                payableAccounts: [],
                creditNotes: []
            },

          
        }
    },
    computed: {
        
    },
    watch: { 
        'values.provider_id': function(v) {
            //console.log(v);
            this.getPayableAccounts(v);
        },
        
    },
    mounted(){
        console.log(this.inputCreditNotes)
        this.loadSavedCreditNotes()
        this.getSupliers();
        this.getPaymentMethods();
        this.getBankAccounts();
        //console.log(this.values);
        this.getPayableAccounts(this.values.provider_id);
        this.getExchange();

        this.getCreditNotes(this.values.provider_id);
        this.selectCurrency()
        this.exchangeUpdate();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        bankAccountUpdated(v)
        {
            const bank_account = this.serverItems.bankAccounts.find( x => x.value === v);
            if(bank_account){
                this.values.currency = bank_account.currency;
            }
            const pastCurrency = this.values.currency;
            

            this.selectCurrency(v); 

            if(pastCurrency == this.values.currency)
                return;
            this.currencyUpdated();
            this.exchangeUpdate(); 
        },
        clearError(item)
        {
            let index =  Utils.getIndexArrayObject(this.values.balanceables, 'id', item.id);
            console.log(index, item);
            delete this.serverErrors[`balanceables.${index}.id`];
        },
        getExchange()
        {
            if(this.edit)
                return;
            this.$api.utils.getExchange()
                .then((resp) => {
                    this.values.exchange_rate =  parseFloat(resp.data.usd_to_mxn).toFixed(2);
                })
                .catch((error) => {
                    console.log(error);
                    this.values.exchange_rate = '';
                })
            ;
        },
        paymentMethodChanged(value)
        {
            if(value === 'notas-de-credito' || value === 'descuento')
            {
                this.values.bank_account_id = '';
                this.values.currency = "";
            }
        },
        deleteTableItem(properties)
        {
            //let pos = Utils.getIndexArrayObject(this.tableProps.items,'id',  properties.id);
            
            //this.tableProps.items.splice(pos, 1);
            let pos =  Utils.getIndexArrayObject(this.values.balanceables, 'id', properties.id);
            this.values.balanceables.splice(pos, 1);

            let ret = this.items.receivableBackup.find((itm) => itm.value == properties.id);
            if(ret != undefined)
                this.serverItems.payableAccounts.push(ret);
            this.values.payable_account_id = '';
            
        },
        setAmountToTable(idNote)
        {
            if(this.values.payment_method_id != 'notas-de-credito')
                return;
            const noteSelected = this.serverItems.creditNotes.find((item) => item.value == idNote);
            console.log(noteSelected, "noteselcted");
            
            //set value in table
            if(this.values.balanceables.length > 0){
                //const element = this.values.balanceables[0];
                this.values.balanceables[0].amount = (this.updateAmount((noteSelected.amount), noteSelected.currency, this.values.exchange_rate??1))
            }

            console.log(this.values.balanceables);
            this.exchangeUpdate();

            console.log(this.values.currency, "currency");
        },
        currencyUpdated()
        {
            if(this.values.exchange_rate == "" || this.values.exchange_rate == null || this.values.exchange_rate == undefined)
                return null;
            // let response = {MXN: 0, USD: 0};
            for (let i = 0; i < this.values.balanceables.length; i++) {
                const element = this.values.balanceables[i];
                
                // response = ;
                if(element.total != 0)
                    this.values.balanceables[i].amount = (this.updateAmount((element.total), element.currency, this.values.exchange_rate??1));
            }

            if(this.values.credit_note_id != ""){
                console.log("yes, if");
                this.setAmountToTable(this.values.credit_note_id)
            }
            
        },
        updateTableItem(item)
        {
            console.log(item);
            let mxn = item.total - item.amount.MXN;
            let usd = item.total - item.amount.USD;
            if(item.currency === "USD"){
                usd = (item.total - item.amount.USD).toFixed(4);
                mxn = (item.total - (item.amount.MXN / this.values.exchange_rate)).toFixed(4);
            }

            if(item.currency === "MXN"){
                usd = (item.total - (item.amount.USD * this.values.exchange_rate)).toFixed(4);
                mxn = (item.total - item.amount.MXN).toFixed(4);
            }

            if(mxn == 0){
                mxn = Math.abs(mxn)
            }

            if(usd == 0){
                console.log("gere");
                usd = Math.abs(usd)
            }
            const new_balance_due = {MXN: mxn, USD: usd};
          
            const ind = Utils.getIndexArrayObject(this.values.balanceables,'id', item.id);
            this.values.balanceables[ind].balance_due = new_balance_due;

            return new_balance_due;
        },
        addCXP()
        {
            if(this.values.payable_account_id === '' || this.values.payable_account_id == undefined)
                this.cxpSelectorError = "Campo requerido";
            
            let cxp = this.values.payable_account_id;
            if(cxp == null || cxp == '' || cxp == undefined)
                return;
            console.log(this.serverItems.payableAccounts, 'itemss');
            let found = this.serverItems.payableAccounts.filter((itm) => itm.value == cxp)[0];
            let item = {
                id: found.slug,
                folio: found.folio,
                amount: this.updateAmount((found.total - found.balance), found.currency, this.values.exchange_rate??1),
                balance_due: {MXN: 0.00, USD: 0.00},
                total: found.total - found.balance,
                currency: found.currency,
                current_mxn_per_usd: this.values.exchange_rate??1,
                actions : JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: found.slug },
            };

            this.originalCurrency = found.currency;

            this.values.balanceables.push(item);
            const pos = Utils.getIndexArrayObject(this.serverItems.payableAccounts,'value', cxp);
            this.serverItems.payableAccounts.splice(pos, 1);
            this.values.payable_account_id = '';
            delete this.serverErrors.balanceables;

            //update by credit
            if(this.values.credit_note_id != ""){
                console.log("yes, if");
                this.setAmountToTable(this.values.credit_note_id)
            }
            
        },

        resetCurrency()
        {
            this.values.currency = this.originalCurrency;
        },

        updateAmount(total, item_currency, current_mxn_per_usd){
           
            let mxn = total;
            let usd = total;
            if(item_currency === "USD"){
                usd = (total).toFixed(4);
                mxn = (total * current_mxn_per_usd).toFixed(4);
            }
            if(item_currency === "MXN"){
                usd = (total / current_mxn_per_usd).toFixed(4);
                mxn = (total).toFixed(4);
            }
            const amount = {MXN: mxn, USD: usd};
            return amount;
        },

        selectCurrency(value){
            const pastCurrency = this.values.currency;

            this.values.currency = "MXN";
            const bank_account = this.serverItems.bankAccounts.find( x => x.value === value);
            if(bank_account){
                this.values.currency = bank_account.currency;
            }
            if(this.values.payment_method_id == 'notas-de-credito'){
                const nota = this.serverItems.creditNotes.find( x => x.value === this.values.credit_note_id);
                if(nota){
                    this.values.currency = nota.currency;
                    console.log("enter nota", this.values.currency)
                }
            }
            if(pastCurrency == this.values.currency)
                return;
            for (let i = 0; i < this.values.balanceables.length; i++) {
                const element = this.values.balanceables[i];
                this.updateTableItem(element);
            }
            this.values.currency = pastCurrency;
        },
        exchangeUpdate()
        {
            for (let i = 0; i < this.values.balanceables.length; i++) {
                const element = this.values.balanceables[i];
                this.updateTableItem(element);
            }
        },

        loadSavedCreditNotes()
        {
            if(this.inputCreditNotes == null || this.inputCreditNotes == undefined)
                return;

           
            this.inputCreditNotes.forEach(cn => {

                this.serverItems.creditNotes.push(
                    {
                        value: cn.slug, 
                        text: `${cn.folio} ${parseFloat(""+cn.total).toFixed(4)} ${cn.currency}`,
                        amount: cn.total,
                        currency: cn.currency
                    }
                )
            });

            
        },

        getCreditNotes(supplier)
        {
            // this.values.credit_note_id = ''; 
            if(supplier == "" || supplier == null || supplier == undefined)
                return;

            this.loadingCreditNotes = true;
            this.$api.creditNote.getSelectCreditNotesBySupplier(this.$store.state.business, supplier)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.creditNotes = resp.data.map((item) => {
                        return {value: item.value, text:item.text, amount:item.total, currency: item.currency};
                    });
                    this.loadSavedCreditNotes();
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCreditNotes = false;
				})
			;
        },

       
        getSupliers()
        {
            this.loadingSupplier = true;
            this.$api.supplier.getSelectSuppliers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.suppliers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingSupplier = false;
				})
			;
        },

        getPaymentMethods()
        {
            this.loadingMethods = true;
            this.$api.paymentMethod.getSelect()
                .then((resp) => {
                    this.serverItems.methods = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingMethods = false;
                })
            ;
        },

        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                            currency: element.currency,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
        
        getPayableAccounts(supplier)
        {
            if(supplier == '' || supplier == null || supplier == undefined)
                return null;
            this.loadingPayableAccounts = true;
            this.$api.payableAccount.bySupplier(this.$store.state.business, supplier)
                .then((resp) => {
                    //console.log(resp.data);
                    resp.data.forEach(itm => {
                        this.serverItems.payableAccounts.push({
                            ...itm,
                            text: itm.folio,
                            value: itm.slug
                        });
                        this.items.receivableBackup.push({
                            ...itm,
                            text: itm.folio,
                            value: itm.slug
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPayableAccounts = false;
                })
            ;
        },
        clear()
        {
            this.tableProps.items = [];
            this.items.receivableBackup = [];
            this.values.balanceables = [];
            this.serverItems.payableAccounts = [];
        }

    }
}
</script>

<style>

</style>