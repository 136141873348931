<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-7">
                        <div class="font-weight-bold body-1">
                            Detalles de cobro
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="7" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
                <v-row justify="center" >
                    <v-col cols="12" md="9" class="py-0">
                        <span class="font-weight-bold normalText--text">Cuentas por cobrar</span>
                        <table-items
                            :props="tableProps" :items="balanceables"
                        ></table-items>
                        <!-- <details-item :detailsItems="observations"></details-item> -->
                    </v-col>

                    
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="7" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../components/DetailsItem.vue';
import TableItems from './TableItems.vue';
import Utils from "../../helpers/Utils";
export default {
    components: {
        DetailsItem,
        TableItems
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
           
            items: [
               
                
            ],
            observations: [],
            balanceables:[],
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Incomes",	
                click: () => {}
            },
            tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left break-words',
						value: 'folio',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					{
						text: 'Fecha de emision',
						align: 'left',
						value: 'bill_date_emitted',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Vigencia',
						align: 'left',
						value: 'due_date',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Periodo del crédito',
						align: 'left',
						value: 'credit_period',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'left',
						value: 'total',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					
				],
				
			},
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.income.get(this.$store.state.business, this.slug)
			.then((response) => {
                console.log(response);
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                let observation = '';
                response.data.receivable_accounts.forEach(itm => {
                    observation = observation + `${itm.comments}\n`;
                });

                let currency = response.data?.currency??"";
                if((response.data?.payment_method.slug != 'notas-de-credito' || response.data?.payment_method.slug != 'descuento') && response.data?.bank_account != null){
                    currency = response.data?.bank_account.currency;
                }

                this.items= [ //
                    { concepto: 'Folio', index: 'folio', valor: response.data.folio },
                    { concepto: 'Folio interno', index: 'folio_int', valor:response.data.folio_int},
                    { concepto: 'Fecha', index: 'date', valor: Utils.globalDateFormat(response.data.date) },
                    { concepto: 'Cliente', index: 'customer.name', valor: response.data.customer.name },
                    { concepto: 'Cuenta de banco', index: 'bank_account.name', valor: response.data.bank_account?.name??""},
                    { concepto: 'Método de pago', index: 'payment_method.name', valor: response.data.payment_method.name },
                    { concepto: 'Divisa', index: 'payment_method.name', valor: currency??"" },
                    { concepto: 'Nota de crédito', index: 'slug', valor:  `${response.data?.credit_note?.folio??""} ${isNaN(parseFloat(""+response.data?.credit_note?.total).toFixed(4)) ? "" : parseFloat(""+response.data?.credit_note?.total).toFixed(4)} ${response.data?.credit_note?.currency??""}` },
                    { concepto: 'Comentario', index: 'slug', valor: response.data.commentary },
                    { concepto: 'Tipo de cambio', index: 'exchange_rate', valor: Utils.currencyFormat(response.data.exchange_rate)},
                    { concepto: 'Esta transacción se usará para pagar un invoice', index: 'folio', valor: response.data.add_invoices ? "Si": "No" },
                    { concepto: 'Observaciones', index: 'folio', valor: observation },
                    
                ];

                //commisions
                if(response.data.commissions == 1){
                    this.items.push(
                        { concepto: 'Incluye el pago en comisiones', index: 'folio', valor: response.data.commissions == "1" ? "Si" : "No" },
                        { concepto: 'Divisa', index: 'folio', valor: response.data.commissions_currency },
                        { concepto: 'Total', index: 'folio', valor: Utils.currencyFormat(response.data.commissions_total, response.data.commissions_currency) },
                        { concepto: 'UUID', index: 'folio', valor: response.data.commissions_uuid??"" },
                    )
                }

                if(response.data.add_invoices){
                    this.balanceables = response.data.invoices.map((x) => {
                        x.folio = x.invoice_number;
                        x.bill_date_emitted = Utils.globalDateFormat(x.date);
                        x.due_date = Utils.globalDateFormat(x.balance_due);
                        x.credit_period = `N/A`;
                        x.total = Utils.currencyFormat(x.pivot.amount,x.currency )
                        // x.total = Utils.currencyFormat(currency == "" ? x.total : currency == "USD" ? x.total_in_usd:x.total_in_mxn,currency??x.currency )
                        return x
                    });
                }else{
                    this.balanceables = response.data.receivable_accounts.map((x) => {
                  
                        x.bill_date_emitted = Utils.globalDateFormat(x.bill_date_emitted);
                        x.due_date = Utils.globalDateFormat(x.due_date);
                        x.credit_period = `${x.credit_period} días`;
                        // x.total = Utils.currencyFormat(x.total,x.currency )
                        x.total = Utils.currencyFormat(currency == "" ? x.total : currency == "USD" ? x.total_in_usd:x.total_in_mxn,currency??x.currency )
                        return x
                    });
                }
               

               
                

                // response.data.taxes_items.forEach(t => {
                //     this.taxesItems.push(
                //         {concepto: t.type, index: 'type', valor: Utils.currencyFormat(t.amount, '')}
                //     );
                // });
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 