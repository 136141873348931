<template>
    <div>
       
        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                ></datePickerForm>
            </v-col>

            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.invoice_number = v; delete serverErrors.invoice_number}" 
                    :valueInput="values.invoice_number" 
                    :rule="[...rules.required]" 
                    label="No. de invoice"
                    :errorMessages="serverErrors.invoice_number"
                ></textFieldForm>
            </v-col>
        </v-row>


       
        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.clients"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.phone_number = v; delete serverErrors.phone_number}" 
                    :valueInput="values.phone_number" 
                    :rule="[...rules.phone]" 
                    label="Teléfono"
                    :errorMessages="serverErrors.phone_number"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Vencimiento (balance due)"
                    :valueInput="values.balance_due"
                    :rule="[]"
                    @valueChange="(v) => {values.balance_due = v; delete serverErrors['balance_due']}"
                    :errorMessages="serverErrors['balance_due']"
                ></datePickerForm>
            </v-col>
            <v-col class="py-2" cols="12" sm="6">
                <textFieldForm 
                    @valueChange="(v) => {values.taxes = v; delete serverErrors.taxes}" 
                    :valueInput="values.taxes" 
                    :rule="[...rules.amount, ...rules.required]" 
                    label="Impuestos"
                    :errorMessages="serverErrors.taxes"
                    :prefix="values.taxes == '' ? '': '%'"
                ></textFieldForm>
            </v-col>
        </v-row>

        
        <v-row>
            <v-col class="py-2" cols="12" >
                <autocompleteOneForm
                    :loading="loadingConcepts"
                    label="Cuenta"
                    :valueInput="values.concept_id"
                    :rule="rules.required"
                    :items="serverItems.concepts"
                    :errorMessages="serverErrors.concept_id"
                    @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
     

        <v-row>
            <v-col class="py-0">
                <h4>Productos o servicios</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="5">
                <textFieldForm 
                    :ref="'descriptionField'"
                    @valueChange="(v) => {description = v; descriptionError = ''; delete serverErrors.description}" 
                    :valueInput="description" 
                    :rule="[]" 
                    label="Producto o servicio (descripción)"
                    :errorMessages="descriptionError"
                    
                ></textFieldForm>
            </v-col>
            <v-col>
                <textFieldForm
                    :ref="'quantityField'"
                    @valueChange="(v) => {quantity = v; quantityError = ''; delete serverErrors.quantity}" 
                    :valueInput="quantity" 
                    :rule="[...rules.number]" 
                    label="Cantidad"
                    :errorMessages="quantityError"
                    
                ></textFieldForm>
            </v-col>
            <v-col>
                <textFieldForm 
                    :ref="'unitPriceField'"
                    @valueChange="(v) => {unitPrice = v; unitPriceError = ''; delete serverErrors.unitPrice}" 
                    :valueInput="unitPrice" 
                    :rule="[...rules.amount]" 
                    label="Precio"
                    :errorMessages="unitPriceError"
                    :prefix="unitPrice == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
             <v-col cols="4" sm="2" class="pl-0" >
                        
                <primary-button :props="addBtnData" class=""></primary-button>
                       
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <table-items  :props="tableProps" @methodHandler="method_handler" :items="values.items">
                    <template v-slot:item.quantity="props">
                        <span class="body-2">{{props.item.quantity}}</span>
                    </template>
                    <template v-slot:item.description="props">
                        <span class="body-2">{{props.item.description}}</span>
                    </template>
                    <template v-slot:item.unit_price="props">
                        <span class="body-2">{{ut.currencyFormat(props.item.unit_price,'')}}</span>
                    </template>
                    <template v-slot:item.total="props">
                        <span class="body-2">{{ut.currencyFormat((props.item.quantity * props.item.unit_price),'')}}</span>
                    </template>
                    
                </table-items>
                <v-input class="warning--text caption" :error-messages="serverErrors.items" v-model="values.items" :rules="rules.items"></v-input>
            </v-col>
        </v-row>

       
    </div>
</template>

<script>
import TableItems from "../incomes/TableItems.vue"
import Utils from "../../helpers/Utils";
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    components: {
       'table-items': TableItems
    },
   
    data(){
        return {
            loadingConcepts: false,
            loadingClients: false,
            ut: Utils,
            descriptionError: "",
            description: "",
            quantityError:"",
            quantity: "",
            unitPriceError: "",
            unitPrice: "",

            addBtnData: {
                text: "Agregar",
                	
                click: this.addPS,
                block:true,
                loading: false,
            },

            rules: {
				required: [
					v => !!v || 'El campo requerido',
                   
				],
                phone: [
                    (v) => !!v || " El Telefono es requerido",
                    // (v) => v.length == 10 || "Debe ser un telefono valido",
                    (v) => {
                        if(v!= null && !v.toString().match("^[0-9]{10}$"))
                            return 'Solo números válidos';
                        return true;
                    }
                ],
                amount: [
                   
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                number: [
                   
                    v => {
                        // if(v === null || v.toString().trim() === '')
                        //     return true;
                        if(v!= null && !v.toString().match("^[0-9]{0,99999}$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                items: [
                    v => {
                        if(v.length == 0)
                            return 'Al menos un producto o servicio';
                        return true;
                    }
                ]
               
				
				
            },
            serverItems: {
                clients: [],
                concepts:[],
            },

            actions: [
				{
					name: 'Borrar',
					icon: {
						color: 'warning',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteTableItem'
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Cantidad',
						align: 'left',
						value: 'quantity',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					{
						text: 'Descripción',
						align: 'left',
						value: 'description',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Precio unitario',
						align: 'left',
						value: 'unit_price',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Valor total',
						align: 'left',
						value: 'total',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '5%',
						class: 'table-b-border black--text'
					}
				],
				items: this.values.items,
				search: '',
				
			},
            

          
        }
    },
   
    mounted(){
        this.getClients();
        this.getConcepts();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        getClients()
        {
            this.loadingClients = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },


        addPS()
        {
            
            if(this.description.trim() === '')
                this.descriptionError = "Campo requerido";
            if(this.quantity.trim() === '')
                this.quantityError = "Campo requerido";
            if(this.unitPrice.trim() === '')
                this.unitPriceError = "Campo requerido";
            if(this.descriptionError != '' || this.quantityError != '' || this.unitPriceError != '')
                return;
            if(this.$refs.descriptionField.$children[0].hasError || this.$refs.quantityField.$children[0].hasError || this.$refs.unitPriceField.$children[0].hasError)
                return;
            let id = new Date().getTime();
            let item = {
                id: id,
                description: this.description,
                quantity: this.quantity,
                unit_price: this.unitPrice,
               
                
                actions : JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: id },
            };

            this.values.items.push(item);

            this.description = this.quantity = this.unitPrice = "";
            
        },
        deleteTableItem(properties)
        {

           
            let pos =  Utils.getIndexArrayObject(this.values.items, 'id', properties.id);
            this.values.items.splice(pos, 1);

        }
       
    }
}
</script>

<style>

</style>