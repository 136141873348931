<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="4" lg="3" xl="2" class="mx-0 px-0 pt-2 pt-md-0 pr-md-1 pb-sm-1">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>
						
					</v-col>
                   

                    <v-col cols="12" md="4" lg="3" xl="2" class="mx-0 px-0 pt-2 pt-md-0 pr-md-1 pb-sm-1">
						<alternativeAutocompleteOne
                            :items="customers"
                            :valueInput="customerId"
                            :loading="customersLoading"
                           
                            @valueChange="(v) => {customerId = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Cliente'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
					<v-spacer></v-spacer>
					<v-col  cols="12" md="4" lg="4" class="mx-0 px-3  mt-2 mt-md-0  ">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2  mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary">
                                        <v-col class="py-1 pb-0 text-sm-center text-center">
                                            <span>SUBTOTAL</span><br>
                                            <span>{{summary.subtotal.mxn}}</span><br>
                                            <span>{{summary.subtotal.usd}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-center text-center">
                                           
                                            <span>IVA</span><br>
                                            <span>{{summary.iva.mxn}}</span><br>
                                            <span>{{summary.iva.usd}}</span>
                                            
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-center text-center">
                                           
                                            <span>TOTAL</span><br>
                                            <span>{{summary.total.mxn}}</span><br>
                                            <span>{{summary.total.usd}}</span>
                                            
                                        </v-col>
                                    </v-row>
                                    
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler"  @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
            customersLoading: false,
            customerId: PageStateManager.getState(this.$router.history.current.path, 'customerId')??"",
            customers:[],
            summary: {subtotal: {mxn: '$0.00', usd: '$0.00'}, iva:{mxn: '$0.00', usd: '$0.00'}, total:{mxn: '$0.00', usd: '$0.00'}},
            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')??new Date().toISOString().split('T')[0]
            },
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Fecha',
						align: 'left',
						value: 'bill_date_emitted',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cliente',
						align: 'left',
						value: 'customer_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Factura',
						align: 'center',
						value: 'folio',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cuenta',
						align: 'left',
						value: 'concept_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Subtotal',
						align: 'right',
						value: 'subtotal',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'IVA',
						align: 'right',
                        
						value: 'iva',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Total',
						align: 'right',
                        
						value: 'total',
						class: 'table-b-border black--text'
					},
                     {
						text: 'Divisa',
						align: 'left',
                        
						value: 'currency',
						class: 'table-b-border black--text'
					},
					
				],
				items: [],
				search: '',
				loading: PageStateManager.getState(this.$router.history.current.path, 'customerId') ? true : false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading:false,
                click: ()=>{this.export();}
            },
            pdfBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },

			
		}
	},

	mounted(){
        this.getCustomers();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
		this.index();
	},

    watch: {
        [`dateRange.start_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
         
        },
        [`customerId`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'customerId', this.customerId);
            
        },
    },

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },

       
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
            if(this.dateRange.start_date == '' || this.dateRange.end_date == '' || this.customerId == "")
                return;
                
			this.tableProps.loading = true;
            this.summary.subtotal.mxn = this.summary.subtotal.usd = Utils.currencyFormat(0, '');
            this.summary.iva.mxn = this.summary.iva.usd = Utils.currencyFormat(0, '');
            this.summary.total.mxn = this.summary.total.usd = Utils.currencyFormat(0, '');
            this.tableProps.items = [];
			//call api
			this.$api.report.reportReceivableAccTimelapse(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date, customer_id: this.customerId},)
				.then((resp) =>{
					//console.log(resp);
                    this.summary.subtotal.mxn = Utils.currencyFormat(resp.data.summary.subtotal.mxn, 'MXN');
                    this.summary.subtotal.usd = Utils.currencyFormat(resp.data.summary.subtotal.usd, 'USD');
                    this.summary.iva.mxn = Utils.currencyFormat(resp.data.summary.iva.mxn, 'MXN');
                    this.summary.iva.usd = Utils.currencyFormat(resp.data.summary.iva.usd, 'USD');
                    this.summary.total.mxn = Utils.currencyFormat(resp.data.summary.total.mxn, 'MXN');
                    this.summary.total.usd = Utils.currencyFormat(resp.data.summary.total.usd, 'USD');
					this.tableProps.items = resp.data.data.map((x) => {
						
						x.subtotal = Utils.currencyFormat(x?.subtotal??0, "");
						x.iva = Utils.currencyFormat(x?.iva??0, "");
						x.total = Utils.currencyFormat(x?.total??0, "");
                        x.bill_date_emitted = Utils.globalDateFormat(x.bill_date_emitted);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getCustomers()
        {
            this.customersLoading = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
                .then((resp) => {
                    this.customers.push({text: "Todos", value: "all"});
                    this.customers.push(...resp.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.customersLoading = false;
                })
            ;
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportReceivableAccTimelapse(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date, customer_id: this.customerId})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `receivable_accounts_timelapse_${this.dateRange.start_date}_${this.dateRange.end_date}.xls`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportReceivableAccTimelapse(this.$store.state.business, {"start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date, customer_id: this.customerId})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `receivable_accounts_timelapse_${this.dateRange.start_date}_${this.dateRange.end_date}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>