<template>
    <div>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta emisora"
                    :loading="loadingAccounts"
                    :valueInput="values.origin_account_id"
                    :rule="rules.required"
                    :items="serverItems.accounts"
                    :errorMessages="serverErrors.origin_account_id"
                    @valueChange="(v) => {values.origin_account_id = v.value; changeLabelAmunt(v); delete serverErrors.origin_account_id}"
                    :disabled="this.edit"
                    :returnObject="true"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta receptora"
                    :valueInput="values.target_account_id"
                    :rule="rules.required"
                    :items="serverItems.accounts"
                    :errorMessages="serverErrors.target_account_id"
                    @valueChange="(v) => {values.target_account_id = v; delete serverErrors.target_account_id}"
                    :disabled="this.edit"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}"
                    :valueInput="values.amount"
                    :rule="[...rules.required, ...rules.currencyNumber]"
                    :label="'Monto en '+currency"
                    :prefix="values.amount == '' ? '': '$'"
                    :errorMessages="serverErrors.amount"
                    :disabled="this.edit"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.exchange_rate = v; delete serverErrors.exchange_rate}"
                    :valueInput="values.exchange_rate"
                    :rule="[...rules.required, ...rules.currencyNumber4]"
                    label="Tipo de cambio"
                    :prefix="values.exchange_rate == '' ? '': '$'"
                    :errorMessages="serverErrors.exchange_rate"
                    :disabled="this.edit"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                ></datePickerForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.reason = v; delete serverErrors.reason}"
                    :valueInput="values.reason"
                    :rule="[...rules.required]"
                    label="Motivo"

                    :errorMessages="serverErrors.reason"
                ></textFieldForm>
            </v-col>
        </v-row>





    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],

    data(){
        return {
            loadingAccounts: false,
            currency: 'MXN',
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                rfc: [
                    (v) => !!v || "El campo requerido",
                    (v) => v.length <= 13 || "Debe de ser entre 12 y 13 caracteres",
                    (v) => v.length >= 12 || "Debe de ser entre 12 y 13 caracteres",
                ],

                currencyNumber: [
					v => /^[0-9]+(\.[0-9]{1,2})?$/.test(v) || 'El precio debe ser numérico'
				],
                currencyNumber4: [
					v => /^[0-9]+(\.[0-9]{1,4})?$/.test(v) || 'El precio debe ser numérico'
				],


            },

            items: {
                currencies: [
                    { text: "MXN", value: "MXN" },
                    { text: "USD", value: "USD" },
                ]
            },

            serverItems:{
                accounts:[]
            }



        }
    },

    mounted(){
        this.tryGetAccounts();
        if(!this.edit)
            this.getExchange();
        if(this.edit){
            this.changeLabelAmunt(this.values.origin_account)
        }
    },
    methods: {
        changeLabelAmunt(selected){
            if(selected == undefined)
                return;
            this.currency = selected.currency;
        },
        tryGetAccounts()
        {
            this.loadingAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.accounts = resp.data;
                   
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingAccounts = false;
                })
            ;
        },
        getExchange()
        {
            this.$api.utils.getExchange()
                .then((resp) => {
                    this.values.exchange_rate =  parseFloat(resp.data.usd_to_mxn).toFixed(4);
                })
                .catch((error) => {
                    console.log(error);
                    this.values.exchange_rate = '';
                })
            ;
        },

    }
}
</script>

<style>

</style>