<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
                    <v-col cols="12" md="3" lg="3" xl="3" class="mx-0 px-0 pr-md-1 py-lg-0" >
                       
                        <alternativeAutocompleteOne
                            :items="constructions"
                            :valueInput="constructionItem"
                            :loading="constructionsLoading"
                            
                            @valueChange="(v) => {constructionItem = v}"
                            @onChange="(v) => {index()}"
                            
                            :label="'Obra'"
                            :getObject="false"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>

                    <v-col cols="12" md="4" lg="3" xl="4" class="mx-0 px-0 pr-md-1 py-lg-0 ">
						<alternativeAutocompleteOne
                            :items="suppliers"
                            :valueInput="supplierId"
                            :loading="supplierLoading"
                           
                            @valueChange="(v) => {supplierId = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Proveedor'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
					
					<v-spacer class="d-none d-lg-flex"></v-spacer>
					<v-col  cols="12" md="5" lg="5" xl="4" class="mx-0 pl-lg-0   mt-0 py-4">
                        <v-row justify="end">
                            <iconButton
                                class="mr-2 mr-md-2 mb-lg-0 flip"
                                :props="{
                                    hasTooltip:true,
                                    tooltip: 'Reporte de pagos por proveedor',
                                    disabled: reportDownloadEnabler,
                                    color:'primary',
                                    icon:'mdi-progress-download',
                                    iconColor:'grey darken-3',
                                    height:'35',
                                    loading: reportLoading,
                                    click: () => {this.exportReport()}
                                }"
                            ></iconButton>
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                             <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary" justify="space-around">
                                        <!-- <v-col cols="12" sm="3" class="py-1 text-sm-start text-center">
                                            <span>TOTALES</span>
                                        </v-col> -->
                                        <v-col cols="6" md="2" class="py-1 px-0 text-center">
                                            <span class="nowrap small-th-always font-weight-bold">MONTOS</span>
                                            <br>
                                            <table class="mx-auto slim-table">
                                                <tr>
                                                    <td class="small-th-always font-weight-bold " align=RIGHT>{{totalAmount.mxn}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr >
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{totalAmount.usd}}</td>
                                                    <!-- <td class="text-subtitle-1 font-weight-bold nowrap" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                        </v-col>
                                        <v-col cols="6" md="2" class="py-1  px-0 text-center">
                                           
                                            <span class="nowrap small-th-always font-weight-bold">PAGOS</span>
                                            <br>
                                            <table class="mx-auto  slim-table">
                                                <tr >
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{payments.mxn}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr>
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{payments.usd}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                            
                                        </v-col>
                                        <v-col cols="6" md="2" class="py-1  px-0 text-center">
                                           
                                            <span class="nowrap small-th-always font-weight-bold">GARANTÍAS</span>
                                            <br>
                                            <table class="mx-auto  slim-table">
                                                <tr >
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{warranties.mxn}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr>
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{warranties.usd}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                            
                                        </v-col>
                                        <v-col cols="6" md="2" class="py-1  px-0 text-center">
                                           
                                            <span class="nowrap small-th-always font-weight-bold">DEDUCTIVAS</span>
                                            <br>
                                            <table class="mx-auto  slim-table">
                                                <tr >
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{reductions.mxn}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr>
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{reductions.usd}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                        </v-col>
                                        <!-- <v-col cols="6" md="2" class="py-1 px-0  text-center">
                                           
                                            <span class="nowrap small-th-always font-weight-bold">ADITIVAS</span>
                                            <br>
                                            <table class="mx-auto  slim-table">
                                                <tr>
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{additions.mxn}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{additions.usd}}</td>
                                                </tr>
                                               
                                            </table>
                                            
                                        </v-col> -->
                                        <v-col cols="6" md="2" class="py-1    px-0 text-center">
                                           
                                          
                                            <span class="nowrap small-th-always font-weight-bold">SALDO</span>
                                            <br>
                                            <table class="mx-auto  slim-table">
                                                <tr >
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{balance.mxn}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>MXN</td> -->
                                                </tr>
                                                <tr>
                                                    <td class="small-th-always font-weight-bold nowrap" align=RIGHT>{{balance.usd}}</td>
                                                    <!-- <td class="small-th-always font-weight-bold nowrap" align=LEFT>USD</td> -->
                                                </tr>
                                               
                                            </table>
                                            
                                        </v-col>

                                        

                                        
                                        

                                        
                                    </v-row>
                                </v-col>
                            </v-card-title>

                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../../assets/pdf_icon_2.svg';
import Utils from '../../../../helpers/Utils';
import PageStateManager from '../../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',

            reportDownloadEnabler: true,
            reportLoading:false,

            constructionsLoading: false,
            supplierLoading: false,
            supplierId: PageStateManager.getState(this.$router.history.current.path, 'supplierId')??'all',
            suppliers: [],
            constructions: [],
            constructionItem: PageStateManager.getState(this.$router.history.current.path, 'constructionItem')??'',

            // additions: {mxn: "$0.00 MXN", usd: "$0.00 USD"},
            totalAmount: {mxn: "$0.00 MXN", usd: "$0.00 USD"},
            balance: {mxn: "$0.00 MXN", usd: "$0.00 USD"},
            reductions: {mxn: "$0.00 MXN", usd: "$0.00 USD"},
            payments: {mxn: "$0.00 MXN", usd: "$0.00 USD"},
            warranties: {mxn: "$0.00 MXN", usd: "$0.00 USD"},

           
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Obra',
						align: 'left',
						value: 'construction_name',
						
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: 'Fecha de\nejecución',
						align: 'left',
						value: 'start_ejecution',
						class: 'table-b-border black--text white-space-normal small-th-always '
					},
                    {
						text: 'Tipo de obra',
						align: 'left',
						value: 'construction_type_name',
                        
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: 'Proveedor',
						align: 'left',
                        
						value: 'provider_name',
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: 'Monto contratado',
						align: 'left',
                        
						value: 'amount',
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: 'Garantias',
						align: 'left',
                        
						value: 'total_warranties',
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: 'Deductivas',
						align: 'left',
                        
						value: 'total_deductivas',
						class: 'table-b-border black--text small-th-always '
					},
                    // {
					// 	text: 'Aditivas',
					// 	align: 'left',
                        
					// 	value: 'total_aditivas',
					// 	class: 'table-b-border black--text small-th-always '
					// },
                   
                    
                    
                    
                    {
						text: 'Pagos',
						align: 'left',
                        
						value: 'total_payments',
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: 'Saldo',
						align: 'left',
                        
						value: 'balance',
						class: 'table-b-border black--text small-th-always '
					},
                    {
						text: '%Pagado',
						align: 'left',
                        
						value: 'payment_percentage',
						class: 'table-b-border black--text small-th-always '
					},
                    
				],
				items: [],
				search: '',
				loading: (PageStateManager.getState(this.$router.history.current.path, 'constructionItem') && PageStateManager.getState(this.$router.history.current.path, 'supplierId')) ? true : false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                disabled: true,
                click: ()=>{this.export();}
            },

            pdfBtnData: {
                disabled: true,
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },


			
		}
	},

    watch: {
        [`constructionItem`]() {
            this.watchConstructionItem();
        },
        [`supplierId`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'supplierId', this.supplierId);
            
        }
    },

	mounted(){
        this.getSuppliers();
		this.getConstructions();
        this.checkButtons();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.watchConstructionItem();
        this.index();
	},

    methods: {
        watchConstructionItem()
        {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'constructionItem', this.constructionItem);
            this.pdfBtnData.disabled = true;
            this.exportBtnData.disabled = true;
            this.reportDownloadEnabler = true;
            if(this.constructionItem != "" && this.constructionItem != null && this.constructionItem != undefined){
                this.pdfBtnData.disabled = false;
                this.exportBtnData.disabled = false;
                this.reportDownloadEnabler = false;
            }
        },
        checkButtons()
        {
            if(this.constructionItem != "" && this.constructionItem != null && this.constructionItem != undefined){
                this.pdfBtnData.disabled = false;
                this.exportBtnData.disabled = false;
                this.reportDownloadEnabler = false;
            }
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
           
            if(this.constructionItem == '' || this.constructionItem == null )
                return;
			this.tableProps.loading = true;
         
            this.tableProps.items = [];
            // this.additions = this.totalAmount = this.balance = this.reductions = this.payments = this.warranties = {mxn: 0, usd: 0};
            // this.additions.mxn = Utils.currencyFormat(0, "MXN");
            // this.additions.usd = Utils.currencyFormat(0, "USD");

            this.totalAmount.mxn = Utils.currencyFormat(0, "MXN");
            this.totalAmount.usd = Utils.currencyFormat(0, "USD");

            this.balance.mxn = Utils.currencyFormat(0, "MXN");
            this.balance.usd = Utils.currencyFormat(0, "USD");

            this.reductions.mxn = Utils.currencyFormat(0, "MXN");
            this.reductions.usd = Utils.currencyFormat(0, "USD");

            this.warranties.mxn = Utils.currencyFormat(0, "MXN");
            this.warranties.usd = Utils.currencyFormat(0, "USD");

            this.payments.mxn = Utils.currencyFormat(0, "MXN");
            this.payments.usd = Utils.currencyFormat(0, "USD");

			//call api
			this.$api.report.reportContractorsBalanceDueBalance(this.$store.state.business, this.constructionItem, this.supplierId)
				.then((resp) =>{
					console.log(resp.data.summary.amount.mxn);
                    // this.additions.mxn = Utils.currencyFormat(resp.data.summary.aditivas.mxn, "MXN");
                    // this.additions.usd = Utils.currencyFormat(resp.data.summary.aditivas.usd, "USD");

                    this.totalAmount.mxn = Utils.currencyFormat(resp.data.summary.amount.mxn, "MXN");
                    this.totalAmount.usd = Utils.currencyFormat(resp.data.summary.amount.usd, "USD");

                    this.balance.mxn = Utils.currencyFormat(resp.data.summary.balance.mxn, "MXN");
                    this.balance.usd = Utils.currencyFormat(resp.data.summary.balance.usd, "USD");

                    this.reductions.mxn = Utils.currencyFormat(resp.data.summary.deductivas.mxn, "MXN");
                    this.reductions.usd = Utils.currencyFormat(resp.data.summary.deductivas.usd, "USD");

                    this.payments.mxn = Utils.currencyFormat(resp.data.summary.payments.mxn, "MXN");
                    this.payments.usd = Utils.currencyFormat(resp.data.summary.payments.usd, "USD");

                    this.warranties.mxn = Utils.currencyFormat(resp.data.summary.warranties.mxn, "MXN");
                    this.warranties.usd = Utils.currencyFormat(resp.data.summary.warranties.usd, "USD");

                    console.log(this.totalAmount)
					this.tableProps.items = resp.data.data.map((x) => {
						
						// x.amount = Utils.currencyFormat(x.amount??0, x.currency);
                        // x.date = Utils.globalDateFormat(x.date);
                        x.amount = Utils.currencyFormat(x.amount, x.currency);
                        x.payments = Utils.currencyFormat(x.payments, x.currency);
                        x.balance = Utils.currencyFormat(x.balance, x.currency);
                        // x.total_aditivas = Utils.currencyFormat(x.total_aditivas, x.currency);
                        x.total_deductivas = Utils.currencyFormat(x.total_deductivas, x.currency);
                        x.total_warranties = Utils.currencyFormat(x.total_warranties, x.currency);
                        x.total_payments = Utils.currencyFormat(x.total_payments, x.currency);
                        x.payment_percentage = `${x.payment_percentage}%`;
						return x;
					});
                    
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getConstructions()
        {
            this.constructionsLoading = true;
            this.$api.construction.getSelect(this.$store.state.business)
                .then((resp) => {
                    console.log(resp.data)
                    this.constructions = resp.data.map((item) => {return {value:item.value, text:item.name}});
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.constructionsLoading = false;
                })
            ;
        },

        getSuppliers()
        {
            this.supplierLoading = true;
            this.$api.supplier.getSelectSuppliers(this.$store.state.business)
                .then((resp) => {
                    this.suppliers.push({text: "Todos", value: "all"});
                    this.suppliers.push(...resp.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.supplierLoading = false;
                })
            ;
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportContractorsBalanceDueBalance(this.$store.state.business, this.constructionItem, this.supplierId)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `contractor_balance_${this.constructionItem}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportContractorsBalanceDueBalance(this.$store.state.business, this.constructionItem, this.supplierId)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `contractor_balance_${this.constructionItem}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        exportReport()
        {
            this.reportLoading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportProvidersPayments(this.$store.state.business, this.constructionItem, this.supplierId)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `contractor_payments_${this.constructionItem}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.reportLoading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

    }
}
</script>

<style lang="scss">
    .width-auto{
        width: auto !important;
    }

    .slim-table tr{
        line-height: 5px;
    }
</style>