<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-8 col-xl-6">
                        <div class="font-weight-bold body-1">
                            Editar invoice
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8" xl="6">
                        <v-form ref="form" @submit.prevent="save">
                            <invoice-form ref="InvoiceForm" :values="form" @save="save" :serverErrors="serverErrors"></invoice-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" xl="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../helpers/Utils';
import InvoiceForm from "./Form.vue";
export default {
    components: {
        'invoice-form': InvoiceForm,
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
            serverErrors: {},
            form: {
                date: "",
                customer_id: "",
                invoice_number: "",
                phone_number: "",
                concept_id:"",
                // payment_made: "",
                balance_due: "",
                taxes: '0',
                items: [],
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Invoices",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            actions: [
				{
					name: 'Borrar',
					icon: {
						color: 'warning',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteTableItem'
				},
			],
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;

			return this.$refs.form.validate() && this.form.items.length>0;
            // return true;
		},
        index()
        {
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.invoice.get(this.$store.state.business, this.slug)
                .then((resp) => {
                    // console.log(this.$refs)
                    this.form = {
                       
                        ...resp.data,
                        items:[],
                        taxes : resp.data.taxes.toString(),
                        customer_id: resp.data.customer.slug,
                        concept_id: resp.data.concept.slug
                    };

                    resp.data.items.forEach((element,index) => {
                        let id = new Date().getTime()+index;
                        this.form.items.push({
                            id: id,
                            description: element.description,
                            quantity: element.quantity,
                            unit_price: element.unit_price,
                            actions : JSON.parse(JSON.stringify(this.actions)),
                            parameters: { id: id },
                        })
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                console.log(form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.invoice.update(this.$store.state.business,this.slug, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'Invoices'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            for (let i = 0; i < data.items.length; i++) {
                delete data.items[i].id;
                delete data.items[i].parameters;
                delete data.items[i].actions;
            }
            return data;
        },
    },
}
</script>

<style>

</style>