<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-7 col-xl-6">
                        <div class="font-weight-bold body-1">
                            Editar Estimacion
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="7" xl="6">
                        <v-form ref="form" @submit.prevent="save">
                            <payment-form ref="ContractorPaymentForm" :values="form" @save="save" :serverErrors="serverErrors" :edit="true" :inputConCXPInfo="conCXPInfo"></payment-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="7" xl="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>

import ContractorPaymentForm from "./Form.vue";
import Utils from '../../../helpers/Utils';
import Object2Form from '../../../helpers/Object2Form';
export default {
    components: {
        'payment-form': ContractorPaymentForm,
    },
	data(){
		return {
            construction: this.$route.params.construction,
            loading: false,
            slug: this.$route.params.slug,
            serverErrors: {},
            conCXPInfo: null,
            form: {
                date:"", //fecha
                customer_id:"", //proveedor, no va al back. Se usa para obtener receivable_account_id
                receivable_account_id:"", //obra
                payment_method_id:"", //forma de pago
                bank_account_id:"", //cuenta bancaria
                concept_id: "", //concepto
                folio: "",
                //factura
                pdf_file:[],
                pdf_file_path:"",
                pdf_file_url: "",

                xml_file:[],
                xml_file_path:"",
                xml_file_url: "",
                
                subtotal: 0,
                total: 0,
                discounts: 0,
                ieps:0,
                iva:0,
                isr_withholdings:0,
                iva_withholdings:0,
                currency:"MXN",
                uuid: "",
                exchange_rate_usd_mxn: 0, //tipo de cambio
                comments: "",


                cxp_list: []
                
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "IncomeConstructionPayments",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;

			return this.$refs.form.validate();
            // return true;
		},
        index()
        {
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.constructionPayment.get(this.$store.state.business, this.construction, this.slug)
                .then((resp) => {
                    this.form = {...this.form, ...resp.data};


                    // this.form.customer_id = this.data.
                    this.form.payment_method_id = resp.data.payment_method.slug;
                    this.form.bank_account_id = resp.data.bank_account.slug;

                    this.form.customer_id = resp.data.construction_receivable_account.customer.slug;
                    this.form.receivable_account_id = resp.data.construction_receivable_account.slug;
                    this.form.concept_id = resp.data?.concept?.slug;

                    //table cxp
                    this.conCXPInfo = {
                        folio: resp.data.construction_receivable_account.folio,
                        amount: resp.data.construction_receivable_account.amount,
                        balance: resp.data.construction_receivable_account.balance,
                        currency: resp.data.construction_receivable_account.currency,
                    }

                    this.form.pdf_file = [];
                    this.form.xml_file = [];
                    
                   
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                console.log(form);

                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.constructionPayment.update(this.$store.state.business, this.construction, this.slug, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'IncomeConstructionPayments'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            data.pdf_file = request.pdf_file;
            data.xml_file = request.xml_file;
            


            if((request.pdf_file_path == '' || request.pdf_file_path == null) && (request.pdf_file instanceof Array))
                data.pdf_file = null;

            if((request.pdf_file_path != '' || request.pdf_file_path != null) && (request.pdf_file instanceof Array))
                delete data.pdf_file;

            if(request.xml_file_path == '' && (request.xml_file instanceof Array))
                data.xml_file = null;

            if(request.xml_file_path != '' && (request.xml_file instanceof Array))
                delete data.xml_file;

            console.log(data);
             return Object2Form.objectToFormData(data,'',[]);
        },
    },
}
</script>

<style>

</style>