<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-7" xl="6">
                        <div class="font-weight-bold body-1">
                            Editar pago
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="7" xl="6">
                        <v-form ref="form" @submit.prevent="save">
                            <ourcome-form :key="componentKey" ref="OutcomeForm" :values="form" @save="save" :serverErrors="serverErrors" :edit="true" :inputCreditNotes="inputCreditNotes"></ourcome-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="7" xl="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../helpers/Utils';
import Object2Form from '../../helpers/Object2Form';
import OutcomeForm from "./Form.vue";


export default {
    components: {
        'ourcome-form': OutcomeForm,
    },
	data(){
		return {
            slug: this.$route.params.slug,
            componentKey: 0,
            loading: false,
            serverErrors: {},
            inputCreditNotes: [],
            form: {
                credit_note_id: "",
                type: "EGRESO",
                date: "",
                concept: "aaaa",
                provider_id: "",
                payment_method_id: "",
                description: "",
                subtotal: "",
                taxes: "",
                total: "",
                uuid: "",
                currency: "",
                bank_account_id: "",
                vaucher_path: "",
                vaucher_file: [],
                commentary: "",
                receivable_account_id: "",
                reference_id: "",
                balanceables: [],
                bank_id: '',
                exchange_rate: 1,
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Outcome",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            actions: [
				{
					name: 'Borrar',
					icon: {
						color: 'warning',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteTableItem'
				},
			],
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
		validate () {
            //console.log(this.serverErrors);
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
            
          
            // this.forceRerender();
			return this.$refs.form.validate() && this.form.balanceables.length>0;
            // return true;
		},
        forceRerender() {
            this.componentKey += 1;
        },
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index()
        {
            console.log(this.form);
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.outcome.get(this.$store.state.business, this.slug)
                .then((resp) => {
                    
                    this.form = {
                        exchange_rate: 1,
                        balanceables:[],
                       
                        ...resp.data,
                        bank_account_id: resp.data?.bank_account?.slug??"", 
                        provider_id: resp.data.provider.slug, 
                        payment_method_id: resp.data.payment_method.slug,
                        vaucher_file:[],
                        currency: resp.data?.currency,
                        credit_note_id: resp.data?.credit_note?.slug??"",
                        
                    };

                    if((this.form.payment_method_id != 'notas-de-credito' || this.form.payment_method_id != 'descuento') && resp.data?.bank_account != null){
                        this.form.currency = resp.data?.bank_account.currency;
                    }

                    this.inputCreditNotes.push({...resp.data.credit_note})
                    resp.data.payable_accounts.forEach(element => {
                        this.form.balanceables.push({
                            folio: element.folio,
                            id: element.slug,
                            amount: {MXN: element.pivot.amount, USD: element.pivot.amount},
                            balance_due: {MXN: element.total - element.balance, USD: element.total - element.balance},
                            total: element.total - element.balance,
                            currency: element.currency, //resp.data.bank_account.currency, //?????
                            current_mxn_per_usd: element.current_mxn_per_usd,
                            actions : JSON.parse(JSON.stringify(this.actions)),
                            parameters: { id: element.slug },
                        })
                    });
                    console.log(this.form, resp.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            console.log(this.form);
            //this.prepareRequest(this.form);
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                //console.log(form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.outcome.update(this.$store.state.business,this.slug, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'Outcome'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                //console.log(99, error.response);
                                if(("error" in error.response.data)){
                                    Object.keys(error.response.data.error).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.error;
                                        error.response.data.error[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            
            data.vaucher_file = request.vaucher_file;
            data.bank_id = data.bank_account_id;

            data.balanceables = request.balanceables.map((x) => {
               
                return  {id: x.id, amount: `${x.amount[request.currency ? request.currency : x.currency]}` };
            });

            if(request.vaucher == '' && (request.vaucher_file instanceof Array))
                data.vaucher_file = null;

            if(request.vaucher != '' && (request.vaucher_file instanceof Array))
                delete data.vaucher_file;

            if(request.vaucher_path == '')
                data.vaucher = null;

            console.log(data)

            delete data.vaucher;

            delete data.payable_accounts;
            delete data.bank_account;
            delete data.customer;
            delete data.payment_method;

            if(data.payment_method_id == 'notas-de-credito' || data.payment_method_id == 'descuento')
                delete data.bank_account_id;


            if(data.payment_method_id != 'notas-de-credito')
                delete data.credit_note_id;

            if(data.payment_method_id != "descuento")
                delete data.currency;

         

            return Object2Form.objectToFormData(data,'',[]);
            // return data;
        }
    },
}
</script>

<style>

</style>