<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="5" lg="3" xl="4" class="mx-0 px-0 mr-md-2 py-lg-0">
						
                        <alternativeAutocompleteOne
                            :items="businesses"
                            :valueInput="businessId"
                            :loading="businessLoading"
                           
                            @valueChange="(v) => {businessId = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Empresa'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
                    
                    
					<v-spacer></v-spacer>
					<v-col  cols="12" md="3" xl="2" class="mx-0 py-lg-0 ">
                        <v-row justify="end">
                            <alternativeTextField2
                                @onChanged="(v) => {trySearch(v);}"
                                @onClear="(v) => {trySearch(v);}"
                                :valueInput="tableProps.search"
                                :rule="[]"
                                :placeholder="'Buscar'"
                                :label="''"
                                :clearable="true"
                                :prependInnerIcon="'mdi-magnify'"
                                class="pick-3 full-width"
                            ></alternativeTextField2>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';

export default {
    data(){
		return {
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            businessLoading: false,
            businessId: PageStateManager.getState(this.$router.history.current.path, 'businessId')??'',
            businesses: [],
            lastPage: false,
            customersPage: 1,

            timeoutID: null,
            lockSearch: false,

         
          
			
            actions: [
                {
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'rrhh_paysheet.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'rrhh_paysheet.update'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left small-th-payroll',
						value: 'name',
						
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Puesto',
						align: 'left small-th-payroll',
						value: 'position_name',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Periodo',
						align: 'left small-th-payroll',
						value: 'payroll_type',
                        
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Sueldo\ncontratado',
						align: 'left small-th-payroll',
                        
						value: 'signed_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Costo\nintegradora',
						align: 'left small-th-payroll',
                        
						value: 'integradora_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    
                    {
						text: 'Sueldo\nfiscal',
						align: 'left small-th-payroll',
                        
						value: 'fiscal_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Sueldo\nintegradora',
						align: 'left small-th-payroll',
                        
						value: 'integrated_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    

                    {
						text: 'Costo\nIMSS',
						align: 'left small-th-payroll',
                        
						value: 'ims_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
						text: 'Costo\ninfonavit',
						align: 'left small-th-payroll',
                        
						value: 'infonavit_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
						text: 'ISN',
						align: 'left small-th-payroll',
                        
						value: 'isn',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Costo total\nsueldo',
						align: 'left small-th-payroll',
                        
						value: 'total_cost_salary',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},

                    {
						text: 'Costo\nmensual',
						align: 'left small-th-payroll',
                        
						value: 'monthly_cost',
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
                    {
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
                        fixed: true,
						class: 'table-b-border black--text white-space-normal small-th-payroll'
					},
					
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: true,
                elevation: '0'
			},
			
			
           





			
		}
	},

    watch: {
        /**
         * Reset values on "type" change
         */
        [`businessId`]: function () {
            console.log(this.businessId)
            PageStateManager.setState(this.$router.history.current.path, 'businessId', this.businessId)
        },
       
        
        // prevHeight: function(v){
        //     if(v != 0)
        //         this.prevHeight = 0;
        // }
    },
    
   

	mounted(){
		this.getBusiness();
        
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        onResize () {
            
            // if(window.innerWidth <= 960){
            //     this.exportBtnData.block = true;
            //     this.pdfBtnData.block = true;

               
            // }
            // else{
                
            //     this.exportBtnData.block = false;
            //     this.pdfBtnData.block = false;
            // }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.options.search = val;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            // this.index();
        },
		index() {
            // // console.log(this.$router)
            if(this.businessId == '' || this.businessId == null || this.businessId == undefined)
                return;
			this.tableProps.loading = true;
           
            this.tableProps.items = [];
           
			//call api
			this.$api.payroll.index(this.businessId)
				.then((resp) =>{
					console.log(resp);
                   
					this.tableProps.items = resp.data.map((x) => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                       
                        x.actions[0].action = { name: 'PayrollEmployeesDetails', params: { id: x.employee_id, } };
                        x.actions[1].action = { name: 'PayrollEmployeesEdit', params: { id: x.employee_id, } };

                        x.signed_salary = Utils.currencyFormat(x.signed_salary??0, "");
                        x.integradora_cost = Utils.currencyFormat(x.integradora_cost??0, "");
                        x.total_cost_salary = Utils.currencyFormat(x.total_cost_salary??0, "");
                        x.fiscal_salary = Utils.currencyFormat(x.fiscal_salary??0, "");
                        x.integrated_salary = Utils.currencyFormat(x.integrated_salary??0, "");
                        x.monthly_cost = Utils.currencyFormat(x.monthly_cost??0, "");
                        x.ims_cost = Utils.currencyFormat(x.ims_cost??0, "");
                        x.infonavit_cost = Utils.currencyFormat(x.infonavit_cost??0, "");
                        x.isn = Utils.currencyFormat(x.isn??0, "");
                      

						return x;
					});
                    
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
                   
				})
			;
           
		},

        getBusiness()
        {
            
            this.businessLoading = true;
            this.$api.business.getSelectTradeName()
                .then((resp) => {
                    this.businesses = resp.data;

                    if(this.businessId == "" && this.businesses.length > 0){
                        this.businessId = this.businesses[0].value??"";
                    }
                    this.index();

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.businessLoading = false;
                })
            ;
        },
        

        

    }
}
</script>


<style lang="scss">
    .table-line-height{
        line-height: 1.2rem !important;
    }

    .small-th-payroll{
        font-size: 0.66rem !important;
        padding: 4px 8px !important;
        @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
            font-size: 0.60rem !important;
            padding: 5px 5px !important;
        }
    }

  
</style>
