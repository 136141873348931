<template>
	<div>
        <v-row>
            <v-col  cols="12" md="6" xl="4">
                <chartbarType1
                    :loading="loadingSales"
                    title="Cuentas por cobrar"
                    color="#D40016"
                    :chartDataInput="salesData"
                    :loadData="loadSales"
                    :lastPage="lastPageSales"
                    @makeRequest="getSalesData"
                ></chartbarType1>
            </v-col>

            <!-- NUEVA -->
            <v-col  cols="12" md="6" xl="4">
                <chartbarType1
                    :loading="loadingPurchases"
                    title="Cuentas por pagar"
                    color="#D40016"
                    :chartDataInput="purchasesData"
                    :loadData="loadPurchases"
                    :lastPage="lastPagePurchases"
                    @makeRequest="getPurchasesData"
                ></chartbarType1>
            </v-col>

            <v-col cols="12" md="6" xl="4">
                <chartbarType1
                    :loading="loadingIncome"
                    title="Ingreso"
                    color="#528DE2"
                    :chartDataInput="incomeData"
                    :loadData="loadIncome"
                    :lastPage="lastPageIncomes"
                    @makeRequest="getIncomeData"
                ></chartbarType1>
            </v-col>
           

            <!-- NUEVA -->
            <v-col  cols="12" md="6" xl="4">
                <chartbarType1
                    :loading="loadingExpenses"
                    title="Egresos"
                    color="#D40016"
                    :chartDataInput="expensesData"
                    :loadData="loadExpenses"
                    :lastPage="lastPageExpenses"
                    @makeRequest="getExpensesData"
                ></chartbarType1>
            </v-col>

            
            <!-- <v-col  cols="12" md="6" xl="4">
                <chartDoughnutType1
                    title="Egresos por concepto"
                    :loading="loadingOutcome"
                    :chartDataInput="outcomeData"
                    :loadData="loadOutCome"
                    :lastPage="lastPageOut"
                    @makeRequest="getOutcomeData"
                ></chartDoughnutType1>
            </v-col> -->
        </v-row>
		
	</div>
</template>
<script>
import Utils from '../../helpers/Utils';
export default {
    data(){
        return {
            lastPageIncomes: 9999,
            lastPageSales: 999,
            lastPageOut: 999,
            lastPagePurchases: 999,
            lastPageExpenses: 999,

            currentPagePurchases: 999,

            controlKey:1,
            loadIncome:true,
            loadSales:true,
            loadOutCome: true,
            loadPurchases: true,
            loadExpenses: true,

            loadingIncome:false,
            loadingSales:false,
            loadingOutcome:false,
            loadingPurchases:false,
            loadingExpenses:false,

            chartData: {
                labels: this.labels,
                datasets: [
                    {
                        
                        backgroundColor: this.color,
                        data: this.values
                    }
                ]
            },
            incomeData:{
                labels:[
                    
                ],
                datasets:[
                    {
                        
                        backgroundColor: "#528DE2",
                        data: []
                    }
                   
                ]
            },
            salesData:{
                labels:[
                    
                ],
                datasets:[
                    {
                        
                        backgroundColor: "#D40016",
                        data: []
                    }
                   
                ]
            },
            outcomeData:{
                labels: [
                    // 'Activo',
                    // 'Clientes',
                    // 'Costos',
                    // 'Gastos de administración',
                    
                ],
                datasets:[
                    {
                        
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            purchasesData:{
                labels:[
                    
                ],
                datasets:[
                    {
                        
                        backgroundColor: "#45bf08",
                        data: []
                    }
                   
                ]
            },
            expensesData:{
                labels:[
                    
                ],
                datasets:[
                    {
                        
                        backgroundColor: "#9542f5",
                        data: []
                    }
                   
                ]
            },
            colors:[
                "#528DE2",
                "#EFFF59",
                "#D40016",
                "#A000D9",
                "#33D64E",
                "#F49333",
                "#F500A5",
                "#5300D7",
                "#5DE3E9",
                "#BD6BE7",
            ]

            
            
        }
    },

	mounted(){
		
	},

    methods: {
        
        getIncomeData(item)
        {
            if(item.page > this.lastPageIncomes)
                return;
            //dashByCustomer
            this.loadingIncome = true;
            this.$api.dashboard.incomeByCustomer(this.$store.state.business, item.dates, item.page, item.currency.toUpperCase())
				.then((resp) =>{
                    console.log(this.incomeData)
                    this.lastPageIncomes = parseInt(resp.data.last_page);
                    this.incomeData.labels = []
                    this.incomeData.datasets[0].data = []
					resp.data.data.forEach(income => {
                        this.incomeData.labels.push(Utils.arrayWord(income.customer_name));
                        this.incomeData.datasets[0].data.push(income.total);
                    });
                    
                    
                })
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.loadingIncome = false;
                    this.loadIncome = false;
                    //this.controlKey = this.controlKey + 1;
				})
			;
        },
        getSalesData(item)
        {
            if(item.page > this.lastPageSales)
                return;
            //dashByCustomer
            this.loadingSales = true;
            this.$api.dashboard.salesByCustomer(this.$store.state.business, item.dates, item.page, item.currency.toUpperCase())
				.then((resp) =>{
                    console.log(this.salesData)
                    this.lastPageSales = parseInt(resp.data.last_page);
                    this.salesData.labels = []
                    this.salesData.datasets[0].data = []
					resp.data.data.forEach(sale => {
                        this.salesData.labels.push(Utils.arrayWord(sale.customer_name));
                        this.salesData.datasets[0].data.push(sale.total);
                    });
                    
                    
                })
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.loadingSales = false;
                    this.loadSales = false;
                    //this.controlKey = this.controlKey + 1;
				})
			;
        },

        getOutcomeData(item)
        {
            if(item.page > this.lastPageOut)
                return;
            //dashByCustomer
            this.loadingOutcome = true;
            this.$api.dashboard.outcomeByConcept(this.$store.state.business, item.dates, item.page, item.currency.toUpperCase())
				.then((resp) =>{
                    console.log(resp.data)
                    this.lastPageOut = parseInt(resp.data.last_page);
                    this.outcomeData.labels = []
                    this.outcomeData.datasets[0].data = []
                    this.outcomeData.datasets[0].backgroundColor = []
					resp.data.data.forEach((out, i) => {
                        this.outcomeData.labels.push(Utils.arrayWord2(out.concept_name, 3, 2));
                        this.outcomeData.datasets[0].data.push(out.total);
                        this.outcomeData.datasets[0].backgroundColor.push(this.colors[i]);
                    });
                    console.log(this.outcomeData)
                    
                })
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.loadingOutcome = false;
                    this.loadOutCome = false;
                    //this.controlKey = this.controlKey + 1;
				})
			;
        },

        getPurchasesData(item)
        {
           
            if(item.page > this.lastPagePurchases)
                return;
            //dashByCustomer
            this.loadingPurchases = true;
            this.$api.dashboard.purchasesBySupplier(this.$store.state.business, item.dates, item.page, item.currency.toUpperCase())
				.then((resp) =>{
                    // console.log(resp.data)
                    // console.log(this.incomeData)
                    this.lastPagePurchases = parseInt(resp.data.last_page);
                    this.currentPagePurchases = parseInt(resp.data.current_page);
                    this.purchasesData.labels = []
                    this.purchasesData.datasets[0].data = []
					resp.data.data.forEach((income,index) => {
                        if(index >= 0)
                       { this.purchasesData.labels.push(Utils.arrayWord(income.customer_name));
                        this.purchasesData.datasets[0].data.push(income.total);}
                    });

                    // console.log(this.purchasesData, "data")
                    
                    
                })
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.loadingPurchases = false;
                    this.loadPurchases = false;
                    //this.controlKey = this.controlKey + 1;
				})
			;
        },

        getExpensesData(item)
        {
           
            if(item.page > this.lastPageExpenses)
                return;
            //dashByCustomer
            this.loadingExpenses = true;
            this.$api.dashboard.expensesBySupplier(this.$store.state.business, item.dates, item.page, item.currency.toUpperCase())
				.then((resp) =>{
                    console.log(resp.data)
                    // console.log(this.incomeData)
                    this.lastPageExpenses = parseInt(resp.data.last_page);
                    this.currentPagePurchases = parseInt(resp.data.current_page);
                    this.expensesData.labels = []
                    this.expensesData.datasets[0].data = []
					resp.data.data.forEach((income,index) => {
                        if(index >= 0)
                       { this.expensesData.labels.push(Utils.arrayWord(income.provider_name));
                        this.expensesData.datasets[0].data.push(income.total);}
                    });

                    console.log(this.expensesData, "data")
                    
                    
                })
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.loadingExpenses = false;
                    this.loadExpenses = false;
                    //this.controlKey = this.controlKey + 1;
				})
			;
        },

    }
}
</script>