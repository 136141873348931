<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingCustomers"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; this.getAccounts(v); delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Obra"
                    :loading="loadingContractorCXP"
                    :valueInput="values.receivable_account_id"
                    :rule="rules.required"
                    :items="serverItems.contractorCXPs"
                    :errorMessages="serverErrors.receivable_account_id"
                    @valueChange="(v) => {values.receivable_account_id = v; this.getAccountInfo(v); delete serverErrors.receivable_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Forma de pago"
                    :loading="loadingMethods"
                    :valueInput="values.payment_method_id"
                    :rule="rules.required"
                    :items="serverItems.methods"
                    :errorMessages="serverErrors.payment_method_id"
                    @valueChange="(v) => {values.payment_method_id = v;paymentMethodChanged(v); delete serverErrors.payment_method_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta bancaria"
                    :loading="loadingBankAccounts"
                    :valueInput="values.bank_account_id"
                    :rule="rules.required"
                    :items="serverItems.bankAccounts"
                    :errorMessages="serverErrors.bank_account_id"
                    @valueChange="(v) => {values.bank_account_id = v; delete serverErrors.bank_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :loading="loadingConcepts"
                    label="Cuenta"
                    :valueInput="values.concept_id"
                    :rule="rules.required"
                    :items="serverItems.concepts"
                    :errorMessages="serverErrors.concept_id"
                    @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.folio = v; delete serverErrors.folio}" 
                    :valueInput="values.folio" 
                    :rule="rules.required" 
                    label="Folio"
                    :errorMessages="serverErrors.folio"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF'"
                    label="Factura"
                    :multiple="false"
                    :valueInput="values.pdf_file"
                    :url="values.pdf_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.pdf_file = v; delete serverErrors.pdf_file}"
                    :errorMessages="serverErrors.pdf_file"
                    v-on:update:url="values.pdf_file_path = $event; values.pdf_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'text/xml'"
                    label="Factura XML"
                    :multiple="false"
                    :valueInput="values.xml_file"
                    :url="values.xml_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.xml_file = v; delete serverErrors.xml_file; this.readXML(v)}"
                    :errorMessages="serverErrors.xml_file"
                    v-on:update:url="values.xml_file_path = $event; values.xml_file_url = $event;"
                    @cleared:file="clearInputs"
                ></fileInputForm>
            </v-col>
        </v-row>


      

        <!-- <v-row>
            <v-col class="py-2">
                <fileInputForm
                    
                    label="Factura XML"
                    :multiple="false"
                    :valueInput="xml_file"
                    
                    :accept="'text/xml'"
                    @valueChange="(v) => {values.xml_file = v;delete serverErrors.xml_file; this.readXML(v)}"
                    :errorMessages="serverErrors.xml_file"
                    @cleared:file="clearInputs"
                ></fileInputForm>
            </v-col>
        </v-row> -->


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.subtotal = v; this.total();delete serverErrors.subtotal}" 
                    :valueInput="values.subtotal" 
                    :rule="rules.amount" 
                    label="Subtotal"
                    :errorMessages="serverErrors.subtotal"
                    :prefix="values.subtotal === '' ? '' : '$'"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.total = v;delete serverErrors.total}" 
                    :valueInput="values.total" 
                    :rule="[]" 
                    label="Total"
                    :errorMessages="serverErrors.total"
                    :prefix="values.total === '' ? '' : '$'"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.discounts = v; this.total();delete serverErrors.discounts}" 
                    :valueInput="values.discounts" 
                    :rule="rules.amount" 
                    label="Descuentos"
                    :errorMessages="serverErrors.discounts"
                    :prefix="values.discounts === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.ieps = v; this.total();delete serverErrors.ieps}" 
                    :valueInput="values.ieps" 
                    :rule="rules.amount" 
                    label="IEPS"
                    :errorMessages="serverErrors.ieps"
                    :prefix="values.ieps === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva = v; this.total();delete serverErrors.iva}" 
                    :valueInput="values.iva" 
                    :rule="rules.amount" 
                    label="IVA"
                    :errorMessages="serverErrors.iva"
                    :prefix="values.iva === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.isr_withholdings = v; this.total();delete serverErrors.isr_withholdings}" 
                    :valueInput="values.isr_withholdings" 
                    :rule="rules.amount" 
                    label="Retenciones ISR"
                    :errorMessages="serverErrors.isr_withholdings"
                    :prefix="values.isr_withholdings === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.iva_withholdings = v; this.total();delete serverErrors.iva_withholdings}" 
                    :valueInput="values.iva_withholdings" 
                    :rule="rules.amount" 
                    label="Retenciones IVA"
                    :errorMessages="serverErrors.iva_withholdings"
                    :prefix="values.iva_withholdings === '' ? '' : '$'"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Divisa"
                    :valueInput="values.currency"
                    :rule="rules.required"
                    :items="items.currency"
                    :errorMessages="serverErrors.currency"
                    @valueChange="(v) => {values.currency = v; this.updateItem(); delete serverErrors.currency}"
                    :disabled="xml === 1"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.uuid = v; this.total();delete serverErrors.uuid}" 
                    :valueInput="values.uuid" 
                    :rule="[]" 
                    label="UUID"
                    :errorMessages="serverErrors.uuid"
                    :disabled="xml === 1"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.exchange_rate_usd_mxn = v; this.updateItem(); delete serverErrors.exchange_rate_usd_mxn}" 
                    :valueInput="values.exchange_rate_usd_mxn" 
                    :rule="rules.amount" 
                    label="Tipo de cambio"
                    :errorMessages="serverErrors.exchange_rate_usd_mxn"
                    :prefix="values.exchange_rate_usd_mxn === '' ? '' : '$'"
                ></textFieldForm>
            </v-col>
        </v-row>

        
      
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Conceptos"
                    :errorMessages="serverErrors.comments"
                    
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
                <table-items  :props="tableProps"  :items="values.cxp_list">
                   
                </table-items>
                <v-input class="warning--text caption" :error-messages="serverErrors.balanceables" v-model="values.balanceables" :rules="rules.balanceables"></v-input>
            </v-col>
        </v-row>
        
                
                
            
    </div>
</template>

<script>
import Utils from '../../../helpers/Utils';
import TableItems from "../../incomes/TableItems.vue";
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit', 'inputConCXPInfo'],
    components: {
       'table-items': TableItems
    },
    data(){
        return {
            loadingConcepts: false,
            loadingCustomers: false,
            loadingContractorCXP: false,
            loadingMethods: false,
            loadingBankAccounts: false,
            loadingContractorCXPInfo: false,

            ut: Utils,
            file: [],
            xml: 0,
            tableProps: {
				headers: [
                     {
						text: 'Obra',
						align: 'left',
						value: 'folio',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					{
						text: 'Monto contratado',
						align: 'left',
						value: 'total_text',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo antes del pago',
						align: 'left',
						value: 'balance_text',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo post pago',
						align: 'left',
						value: 'balance_due',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
				],
				items: this.values.cxp_list,
				search: '',
				loading: false,
                keyValue: 0,
			},
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
			
                
               
             
                current_mxn_per_usd: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido';
                        if(v === 0 || v === '0' || v<=0)
                            return 'El campo debe ser máyor a 1';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                
               
                
				
            },
            items:{
                types:[ 
                    {text: "PAGO", value: "PAGO"},
                    {text: "ANTICIPO", value: "ANTICIPO"},
                    {text: "GARANTÍA", value: "GARANTÍA"}
                ],
                currency: [
                    {text: "MXN", value: "MXN"},
                    {text: "USD", value: "USD"}
                ],
                
               
             
            },
            serverItems: {
                customers: [],
                contractorCXPs: [],
               bankAccounts:[],
                methods: [],
                concepts: [],
                conCXPInfo:null,

            },


          
        }
    },
    watch: {
        values: function (vv) {
            //console.log(this.values, vv);
        }
    },
    mounted(){
        
       // this.getAccounts();
        this.getPaymentMethods();
        this.getCustomers();
        this.getConcepts();
        // this.getAccounts();
        this.getBankAccounts();

        if(this.edit){
            this.getAccounts(this.values.customer_id);
            this.loadCXPTable(this.inputConCXPInfo);
        }else{
            this.getExchange();

        }
    },
    methods: {


        paymentMethodChanged(value)
        {
            if(value === 'notas-de-credito' || value === 'descuento')
            {
                this.values.bank_account_id = '';
            }
        },

        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },

        getPaymentMethods()
        {
           this.loadingMethods = true;
            this.$api.paymentMethod.getSelect()
                .then((resp) => {
                    this.serverItems.methods = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingMethods = false;
                })
            ;
        },

        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },


      
        getCustomers()
        {
            this.loadingCustomers = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCustomers = false;
				})
			;
        },

        getAccounts(customer)
        {
            console.log(customer, "entoro");
            if(customer == "" || customer == undefined)
                return;

            this.loadingContractorCXP = true;
            this.$api.customer.constructionReceivableAccount(this.$store.state.business,this.$route.params.construction, customer)
                .then((resp) => {
                    this.serverItems.contractorCXPs = resp.data.map(item => {
                        return {value: item.value, text: item.text}
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingContractorCXP = false;
                })
            ;
        },

        loadCXPTable(valueInput)
        {
            if(valueInput == null || valueInput == undefined)
                return;

            this.serverItems.conCXPInfo = valueInput;
            console.log(this.serverItems.conCXPInfo, "si entra")
            this.updateItem();
        },

        getAccountInfo(account)
        {
            if(account == "" || account == undefined)
                return;

            this.loadingContractorCXPInfo = true;
            this.$api.constructionCXC.get(this.$store.state.business, this.$route.params.construction, account)
                .then((resp) => {
                    


                    this.serverItems.conCXPInfo = resp.data;

                    
                    this.updateItem();

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingContractorCXPInfo = false;
                })
            ;
        },

        updateItem()
        {


            //clear table
            this.tableProps.items = [];
            this.values.cxp_list = [];

            if(this.serverItems.conCXPInfo == null || this.serverItems.conCXPInfo == undefined)
                return;

            let final_balance = this.caculateItem(this.serverItems.conCXPInfo, this.values.total, this.values.currency);

            // console.log(balance_due)
            //set table info
            let item = {
                folio: this.serverItems.conCXPInfo.folio,
                total: this.serverItems.conCXPInfo.amount,
                balance: this.serverItems.conCXPInfo.balance,
                currency: this.serverItems.conCXPInfo.currency,
                

                total_text: Utils.currencyFormat(this.serverItems.conCXPInfo.amount, this.serverItems.conCXPInfo.currency),
                balance_text: Utils.currencyFormat(this.serverItems.conCXPInfo.balance, this.serverItems.conCXPInfo.currency),
                balance_due: Utils.currencyFormat(final_balance, this.serverItems.conCXPInfo.currency)

            }
            this.values.cxp_list.push(item);
        },


        caculateItem(item, total, totalCurrency){
            //mxn & usd total
            const formTotal = this.getBalanceRes(total, totalCurrency, this.values.exchange_rate_usd_mxn);


            const final = item.balance - formTotal[`${item.currency}`]

            
            return final
        },

        getBalanceRes(total, item_currency, current_mxn_per_usd){
            total = parseFloat(total);
            let mxn = total;
            let usd = total;
            if(item_currency === "USD"){
                usd = (total).toFixed(4);
                mxn = (total * current_mxn_per_usd).toFixed(4);
            }
            if(item_currency === "MXN"){
                usd = (total / current_mxn_per_usd).toFixed(4);
                mxn = (total).toFixed(4);
            }
            const amount = {MXN: mxn, USD: usd};
            return amount;
        },

        getExchange()
        {
            this.$api.utils.getExchange()
                .then((resp) => {
                    this.values.exchange_rate_usd_mxn =  parseFloat(resp.data.usd_to_mxn).toFixed(4);
                })
                .catch((error) => {
                    console.log(error);
                    this.values.exchange_rate_usd_mxn = '';
                })
            ;
        },

       
        total() {
            let taxes =  parseFloat((this.values.iva??"0")) + 
                                                parseFloat((this.values.ieps??"0")) -
                                                parseFloat((this.values.iva_withholdings??"0"))+
                                                parseFloat((this.values.isr_withholdings??"0"))+
                                                parseFloat((this.values.discounts??"0"));
            console.log(taxes);
            if(isNaN(taxes))
                taxes = 0;
            if(taxes < 0)
                taxes = 0;
            this.values.total = parseFloat(parseFloat(this.values.subtotal)+taxes).toFixed(4);
            
            if(isNaN(this.values.total))
                this.values.total = 0;
            if(this.values.total < 0)
                this.values.total = 0;

            // this.values.total = parseFloat(""+this.values.total).toFixed(4);
            // const taxes = ((parseFloat(this.values.iva) + parseFloat(this.values.ieps)) - (parseFloat(this.values.iva_withholdings)  + parseFloat(this.values.isr_withholdings) + parseFloat(this.values.discounts)));
            // this.values.total =  parseFloat(this.values.subtotal) + parseFloat(taxes);

            this.updateItem();
            delete this.serverErrors.total;
        },

        readXML(file) {
            if (file !== null) {
                let formdata = new FormData();
                formdata.append("xml_archive", file);
                this.$api.balance.readXML(formdata)
                .then((response) => {
                    // this.values.taxes = response.data.taxes;
                    this.values.discounts = response.data.discounts;
                    this.values.ieps = response.data.ieps;
                    this.values.iva = response.data.iva;
                    this.values.iva_withholdings = response.data.iva_withholdings;
                    this.values.isr_withholdings = response.data.isr_withholdings;
                    this.values.subtotal = response.data.subtotal;
                    this.values.total = response.data.total;
                    this.values.currency = response.data.currency;
                    this.values.uuid = response.data.uuid;
                    this.values.taxes_items = response.data.taxes_items;
                    this.xml = 1;


                    this.updateItem();
                })
                .catch((error) => {
                    alert(error);
                });
            }
        },

        clearInputs() {
            this.xml = 0;
            // this.values.taxes = 0;
            this.values.subtotal = 0;
            this.values.total = 0;

            this.values.discounts = 0;
            this.values.ieps = 0;
            this.values.iva = 0;
            this.values.iva_withholdings = 0;
            this.values.isr_withholdings = 0;

            this.values.currency = "";
            this.values.uuid = "";

            console.log(this.values)
        },
    }
}
</script>

<style>

</style>