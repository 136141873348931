<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-8 col-xl-5">
                        <div class="font-weight-bold body-1">
                            Nuevo rol
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8" xl="5">
                        <v-form ref="form" @submit.prevent="save">
                            <rol-form ref="RolForm" :values="form" @save="save" :serverErrors="serverErrors"></rol-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import RolForm from "./Form.vue";
import modules from './modules';
import permissions from "./permission";
export default {
    components: {
        'rol-form': RolForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            form: {
                name: "",
                permissions: permissions,
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Roles",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.init();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        init()
        {
            for (const property in this.form.permissions) {
                this.form.permissions[property] = {};
            }
        },
        save(){
            
            // console.log(this.form);
            // this.prepareRequest(this.form);
            if(this.validate()){
                
                let form = this.prepareRequest(this.form)
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.rol.store(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Roles'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            data.permissions['catalogs'].businessAll = false;
            for (const property in data.permissions) {
                //console.log(property)
                
                if(property != 'receivable_accounts' ){
                    if(property != 'payable_accounts')
                        delete data.permissions[property].cancel;
                }

                if(property == 'users'){
                    if(data.permissions['users'].create == true || data.permissions['users'].update == true)
                        data.permissions['catalogs'].businessAll = true;
                }

                if(property == 'rrhh_paysheet' || property == "rrhh_payroll_payment"){
                    if(data.permissions['rrhh_paysheet'].show || data.permissions['rrhh_payroll_payment'].show)
                        data.permissions['catalogs'].businessAll = true;
                }

                // if(property == 'rrhh_paysheet'){
                //     if(data.permissions['rrhh_paysheet'].show)
                //         data.permissions['catalogs'].businessAll = true;
                // }


                if(property == 'rrhh_payroll_payment'){
                    if(data.permissions['rrhh_payroll_payment'].create == true){
                        data.permissions['rrhh_payroll_payment'].save = true;
                    }else{
                        data.permissions['rrhh_payroll_payment'].save = false;
                    }
                    delete data.permissions['rrhh_payroll_payment'].create;
                }


                
               
            }

            for (const indexModule in modules) {
                for(const submoduleIndex in modules[indexModule].submodules){
                    if("notIn" in modules[indexModule].submodules[submoduleIndex]) {
                        for(const notInIndex in modules[indexModule].submodules[submoduleIndex].notIn)
                            delete data.permissions[modules[indexModule].submodules[submoduleIndex].key][modules[indexModule].submodules[submoduleIndex].notIn[notInIndex]]
                    }
                }
            }
            console.log(data);
            return data;
        }
    },
}
</script>

<style>

</style>