import { render, staticRenderFns } from "./CXCByFolio.vue?vue&type=template&id=de1b4c04&"
import script from "./CXCByFolio.vue?vue&type=script&lang=js&"
export * from "./CXCByFolio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports