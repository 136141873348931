<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proveedor"
                    :loading="loadingProvider"
                    :valueInput="values.provider_id"
                    :rule="rules.required"
                    :items="serverItems.providers"
                    :errorMessages="serverErrors.provider_id"
                    @valueChange="(v) => {values.provider_id = v; delete serverErrors.provider_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <!-- <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Obra"
                    :loading="loadingReferences"
                    :valueInput="values.reference_id"
                    :rule="rules.required"
                    :items="serverItems.references"
                    :errorMessages="serverErrors.reference_id"
                    @valueChange="(v) => {values.reference_id = v; delete serverErrors.reference_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row> -->











       
        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Inicio de ejecución"
                    :valueInput="values.start_ejecution"
                    :rule="rules.required"
                    @valueChange="(v) => {values.start_ejecution = v; delete serverErrors['start_ejecution']}"
                    :errorMessages="serverErrors['start_ejecution']"
                ></datePickerForm>
            </v-col>

            <v-col class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Fin de ejecución"
                    :valueInput="values.end_ejecution"
                    :rule="rules.required"
                    @valueChange="(v) => {values.end_ejecution = v; delete serverErrors['end_ejecution']}"
                    :errorMessages="serverErrors['end_ejecution']"
                ></datePickerForm>
            </v-col>
        </v-row>


       
        <v-row>
           
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.construction_area = v; delete serverErrors.construction_area}" 
                    :valueInput="values.construction_area" 
                    :rule="[...rules.required]" 
                    label="Area de construcción (M2)"
                    :errorMessages="serverErrors.construction_area"
                ></textFieldForm>
            </v-col>
        </v-row>

       
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tipo de obra"
                    :loading="loadingConstructionTypes"
                    :valueInput="values.construction_type_id"
                    :rule="rules.required"
                    :items="serverItems.constructionTypes"
                    :errorMessages="serverErrors.construction_type_id"
                    @valueChange="(v) => {values.construction_type_id = v; delete serverErrors.construction_type_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


       <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="[...rules.amount, ...rules.required]" 
                    label="Monto contratado"
                    :errorMessages="serverErrors.amount"
                    :prefix="values.amount == '' ? '': '$'"
                ></textFieldForm>
            </v-col>
       </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Divisa"
                    :valueInput="values.currency"
                    :rule="rules.required"
                    :items="items.currencies"
                    :errorMessages="serverErrors.currency"
                    @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xla,.xlw,.xlr,.csv,.ods'"
                    label="Cotización"
                    :multiple="false"
                    :valueInput="values.quotation_file"
                    :url="values.quotation_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.quotation_file = v; delete serverErrors.quotation_file}"
                    :errorMessages="serverErrors.quotation_file"
                    v-on:update:url="values.quotation_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>




        
       
    </div>
</template>

<script>
import Utils from '../../../helpers/Utils';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    
   
    data(){
        return {
            loadingProvider: false,
            loadingReferences: false,
            loadingConstructionTypes:false,
            ut: Utils,
            descriptionError: "",
            description: "",
            quantityError:"",
            quantity: "",
            unitPriceError: "",
            unitPrice: "",

            addBtnData: {
                text: "Agregar",
                	
                click: this.addPS,
                block:true,
                loading: false,
            },

            rules: {
				required: [
					v => !!v || 'El campo requerido',
                   
				],
                phone: [
                    (v) => !!v || " El Telefono es requerido",
                    // (v) => v.length == 10 || "Debe ser un telefono valido",
                    (v) => {
                        if(v!= null && !v.toString().match("^[0-9]{10}$"))
                            return 'Solo números válidos';
                        return true;
                    }
                ],
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                number: [
                    v => {
                        // if(v === null || v.toString().trim() === '')
                        //     return true;
                        if(v!= null && !v.toString().match("^[0-9]{0,99999}$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                items: [
                    v => {
                        if(v.length == 0)
                            return 'Al menos un producto o servicio';
                        return true;
                    }
                ]
               
				
				
            },

            items: {
                currencies: [
                    { text: "MXN", value: "MXN" },
                    { text: "USD", value: "USD" },
                ]
            },

            serverItems: {
                providers: [],
                references: [],
                constructionTypes: []
            },

            

          
        }
    },
   
    mounted(){
        this.getProviders();
        // this.getReferences();
        this.getConstructionTypes();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        getProviders()
        {
            this.loadingProvider = true;
            this.$api.supplier.getSelectSuppliers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.providers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingProvider = false;
				})
			;
        },

        getReferences()
        {
            this.loadingReferences = true;
            this.$api.reference.getSelectReferences(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.references = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingReferences = false;
                })
            ;
        },


        getConstructionTypes()
        {
            this.loadingConstructionTypes = true;
            this.$api.constructionType.getSelect(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.constructionTypes = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConstructionTypes = false;
                })
            ;
        },
       
       
    }
}
</script>

<style>

</style>