<template>
  <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar préstamo
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <loan-form ref="LoanForm" :values="form" @save="save" :serverErrors="serverErrors" :edit="true"></loan-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../helpers/Utils';
import LoanForm from "./Form.vue";
import Object2Form from "../../helpers/Object2Form";
export default {
    components: {
        'loan-form': LoanForm,
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
            serverErrors: {},
            form: {
                date: "",
                creditor_id: "",
                creditor_bank_account_id: "",
                creditor_reference_id: "",
                debtor_id: "",
                debtor_bank_account_id: "",
                debtor_reference_id: "",
                amount: "",
                concept_id: "",
                comments: "",

                file: [],
                file_path: "",
                file_url: "",

                creditor_is_construction: false,
                debtor_is_construction: false,
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "LoansOutcome",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index(){
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.loanOutCome.get(this.$store.state.business, this.slug)
                .then((resp) => {
                    const data = resp.data;

                    this.form = {
                        date: data.date,
                        creditor_id: data.creditorable?.slug,
                        creditor_bank_account_id: data.creditor_bank_account?.slug,
                        creditor_reference_id: data?.credito_reference?.slug??"",
                        debtor_id: data.debtorable?.slug,
                        debtor_bank_account_id: data.debtor_bank_account?.slug??"",
                        debtor_reference_id: data?.debtor_reference?.slug??"",
                        amount: data.amount,
                        concept_id: data?.concept?.slug??"",
                        comments: data.comments,

                        file: [],
                        file_path: data.file_path??"",
                        file_url: data.file_url??"",

                        creditor_is_construction: data.creditor_is_construction,
                        debtor_is_construction: data.debtor_is_construction
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                const form = this.processRequest(this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.loanOutCome.update(this.$store.state.business, this.slug, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'LoansOutcome'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        processRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            data.file = request.file;
            
            if(request.file_url == '' && (request.file instanceof Array)){
                data.file = null;
                data.file_path = "";
            }

            if(request.file_url != '' && (request.file instanceof Array))
                delete data.file;


            data.creditor_is_construction = data.creditor_is_construction ? 1 : 0;
            data.debtor_is_construction = data.debtor_is_construction ? 1 : 0;


            return Object2Form.objectToFormData(data, '', [])
        }
    },
}
</script>

<style>

</style>