<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-7" xl="6">
                        <div class="font-weight-bold body-1">
                            Nuevo cobro
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="7" xl="6">
                        <v-form ref="form" @submit.prevent="save">
                            <income-form :key="componentKey" ref="IncomeForm" :values="form" @save="save" :serverErrors="serverErrors"></income-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="7" xl="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../helpers/Utils';
import Object2Form from '../../helpers/Object2Form';
import IncomeForm from "./Form.vue";


export default {
    components: {
        'income-form': IncomeForm,
    },
	data(){
		return {
            componentKey: 0,
            loading: false,
            serverErrors: {},
            form: {
                type: "INGRESO",
                date: "",
                concept: "",
                customer_id: "",
                payment_method_id: "",
                description: "",
                subtotal: "",
                taxes: "",
                total: "",
                uuid: "",
                currency: "",
                bank_account_id: "",
                vaucher: null,
                vaucher_file: [],
                commentary: "",
                receivable_account_id: "",
                reference_id: "",
                balanceables: [],
                bank_id: '',
                exchange_rate: 1,
                credit_note_id: '',


                commissions: 0,
                commissions_total: 0,
                commissions_uuid: "", 
                commissions_currency: "",

                invoice_pdf_file:[],
                invoice_pdf_path:"",

                add_invoices:0,
                invoices:[],
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Incomes",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
		validate () {
            //console.log(this.serverErrors);
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
            // if(this.form.balanceables.length==0){
            //     this.serverErrors['balanceables'] = 'Al menos una cuenta por cobrar';
            // }
          
            // this.forceRerender();
			return this.$refs.form.validate() && this.form.balanceables.length>0;
            // return true;
		},
        forceRerender() {
            this.componentKey += 1;
        },
         onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        save(){
            
            console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                //console.log(form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.income.store(this.$store.state.business,form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Incomes'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                //console.log(99, error.response);
                                if(("error" in error.response.data)){
                                    Object.keys(error.response.data.error).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.error;
                                        error.response.data.error[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));

            data.commissions = data.commissions ? "1" : "0";
            
            data.vaucher_file = request.vaucher_file;
            data.bank_id = data.bank_account_id;

            if(data.payment_method_id == 'notas-de-credito' || data.payment_method_id == 'descuento')
                delete data.bank_account_id;

            if(data.payment_method_id != 'notas-de-credito')
                delete data.credit_note_id;

            data.balanceables = request.balanceables.map((x) => {
                let finalItem = {id: x.id, amount: `${x.amount[request.currency ? request.currency : x.currency]}` }
                return finalItem;
            });

            if(request.add_invoices){
                data.invoices = JSON.parse(JSON.stringify(data.balanceables));
                data.balanceables = [];
                data.add_invoices = "1";
            }else
            {
                data.add_invoices = 0;
            }

            if(data.payment_method_id != "descuento")
                delete data.currency;
            

            return Object2Form.objectToFormData(data,'',[]);
            // return data;
        }
    },
}
</script>

<style>

</style>