<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}"
                            @onClear="(v) => {trySearch(v);}"
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            :clearable="true"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'accounts.create'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                        <v-row justify="end">
                            <primaryButton :props="createBtnData"></primaryButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                        </datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager'
export default {
    data(){
		return {
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
			search: '',
            selectedID: '',
			actions: [
				
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'accounts.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'accounts.delete'
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Clave',
						align: 'left',
						value: 'key',
                        
						class: 'table-b-border black--text'
					},
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						
						class: 'table-b-border black--text'
					},
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: true
			},
			
			
            createBtnData: {
                text: "Nueva cuenta",
                icon: "mdi-plus",
				to: "AccountsCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			console.log(object.options, "optionss");
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.search = val;
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            // this.index();
        },
		index() {
			this.tableProps.loading = true;
           
			//call api
			this.$api.account.list(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[0].action = { name: 'AccountsEdit', params: { slug: x.slug } }
						

						x['parameters'] = { id: x.slug, name: x.name};
                       
                       
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

       

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.account.delete(this.$store.state.business, this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>