<template>
    <div>

        <v-row>
            
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingCustomers"
                    :valueInput="values.customer_id"
                    :rule="rules.required"
                    :items="serverItems.customers"
                    :errorMessages="serverErrors.customer_id"
                    @valueChange="(v) => {values.customer_id = v; this.getAccounts(v); delete serverErrors.customer_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Obra"
                    :loading="loadingContractorCXP"
                    :valueInput="values.receivable_account_id"
                    :rule="rules.required"
                    :items="serverItems.contractorCXPs"
                    :errorMessages="serverErrors.receivable_account_id"
                    @valueChange="(v) => {values.receivable_account_id = v; this.calculateAmount(); delete serverErrors.receivable_account_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>












       
        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Inicio de ejecución"
                    :valueInput="values.start_ejecution"
                    :rule="rules.required"
                    @valueChange="(v) => {values.start_ejecution = v; delete serverErrors['start_ejecution']}"
                    :errorMessages="serverErrors['start_ejecution']"
                ></datePickerForm>
            </v-col>

            <v-col class="py-2" cols="12" sm="6">
                <datePickerForm
                    label="Fin de ejecución"
                    :valueInput="values.end_ejecution"
                    :rule="rules.required"
                    @valueChange="(v) => {values.end_ejecution = v; delete serverErrors['end_ejecution']}"
                    :errorMessages="serverErrors['end_ejecution']"
                ></datePickerForm>
            </v-col>
        </v-row>


       
        <v-row>
           
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.percentage = v; this.calculateAmount() ;delete serverErrors.percentage}" 
                    :valueInput="values.percentage" 
                    :rule="[...rules.required]" 
                    label="Porcentaje de retención"
                    :suffix="values.percentage == '' ? '': '%'"
                    :errorMessages="serverErrors.percentage"
                ></textFieldForm>
            </v-col>
        </v-row>


        <!-- <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tipo de obra"
                    :loading="loadingConstructionTypes"
                    :valueInput="values.construction_type_id"
                    :rule="rules.required"
                    :items="serverItems.constructionTypes"
                    :errorMessages="serverErrors.construction_type_id"
                    @valueChange="(v) => {values.construction_type_id = v; delete serverErrors.construction_type_id}"
                ></autocompleteOneForm>
            </v-col>
        </v-row> -->

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {paAmount = v;}" 
                    :valueInput="paAmount" 
                    :rule="[]" 
                    label="Monto de obra"
                    :prefix="paAmount == '' ? '': '$'"
                    :disabled="true"
                ></textFieldForm>
            </v-col>
       </v-row>



       <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; this.calculatePercent(), delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="[...rules.amount, ...rules.required]" 
                    label="Monto retenido"
                    :errorMessages="serverErrors.amount"
                    :prefix="values.amount == '' ? '': '$'"
                    
                ></textFieldForm>
            </v-col>
       </v-row>


        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xla,.xlw,.xlr,.csv,.ods'"
                    label="Cotización"
                    :multiple="false"
                    :valueInput="values.quotation_file"
                    :url="values.quotation_file_url"
                    :rule="[]"
                    @valueChange="(v) => {values.quotation_file = v; delete serverErrors.quotation_file}"
                    :errorMessages="serverErrors.quotation_file"
                    v-on:update:url="values.quotation_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Observaciones"
                    :errorMessages="serverErrors.comments"
                ></textFieldForm>
            </v-col>
       </v-row>
       



        
       
    </div>
</template>

<script>
// import Utils from '../../../helpers/Utils';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
    
   
    data(){
        return {
            loadingCustomers: false,
            loadingContractorCXP: false,
            payableAccounts:[],
            paAmount: "0.0000",

            rules: {
				required: [
					v => !!v || 'El campo requerido',
                   
				],
                
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                number: [
                    v => {
                        // if(v === null || v.toString().trim() === '')
                        //     return true;
                        if(v!= null && !v.toString().match("^[0-9]{0,99999}$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                
               
				
				
            },
            serverItems: {
                customers: [],
                PAs: [],
            },

            

          
        }
    },
   
    mounted(){
        this.getCustomers();
        if(this.edit){
            this.getAccounts(this.values.customer_id);
            this.paAmount = this.values.paAmount;
        }
        // this.getPayables();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},

        getCustomers()
        {
            this.loadingCustomers = true;
            this.$api.customer.getSelectCustomers(this.$store.state.business)
				.then((resp) =>{
					//console.log(resp, "desde form");
					this.serverItems.customers = resp.data;
				})
				.catch((error) =>{
					console.log(error, "error");
				})
				.finally(() => {
                    this.loadingCustomers = false;
				})
			;
        },

       
        getAccounts(customer)
        {
            console.log(customer, "entoro");
            if(customer == "" || customer == undefined)
                return;

            this.loadingContractorCXP = true;
            this.$api.customer.constructionReceivableAccount(this.$store.state.business, this.$route.params.construction, customer)
                .then((resp) => {
                    this.payableAccounts = resp.data;
                    this.serverItems.contractorCXPs = resp.data.map(item => {
                        return {value: item.value, text: item.text}
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingContractorCXP = false;
                })
            ;
        },


        calculateAmount()
        {
            let found = this.payableAccounts.find(item => item.value == this.values.receivable_account_id);
            console.log(found);

            if(found == undefined)
                return;
            let perce = this.values.percentage;
            if(isNaN(perce))
                perce = 0;
            if(perce < 0)
                perce = 0;

            //% * n /100
            this.paAmount = found.amount;
            this.values.amount  = ((perce * found.amount) / 100).toFixed(4);
            // return ((perce * found.amount) / 100).toFixed(2);
        },

        calculatePercent()
        {
            let found = this.payableAccounts.find(item => item.folio == this.values.receivable_account_id);

            if(found == undefined)
                return;
            let amount = this.values.amount;
            if(isNaN(amount))
                amount = 0;
            if(amount < 0)
                amount = 0;

            //% * n /100
            this.paAmount = found.amount;
            // this.values.amount  = ((perce * found.amount) / 100).toFixed(4);
            this.values.percentage = ((amount * 100) / this.paAmount).toFixed(4);
            // return ((perce * found.amount) / 100).toFixed(2);
        }
       
    }
}
</script>

<style>

</style>