<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
                    <v-col cols="12" md="3" xl="3" class="mx-0 pr-0 pr-sm-1 px-0 py-lg-0 py-1" >
                        <alternativeAutocompleteOne
                            :items="concepts"
                            :valueInput="conceptId"
                            :loading="conceptLoading"
                            
                            @valueChange="(v) => {conceptId = v}"
                            @onChange="(v) => {index()}"
                            
                            :label="'Cuenta'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>
					<v-col cols="12" md="3" lg="2"  xl="2" class="mx-0  px-0 py-lg-0 mb-0">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>
						
					</v-col>
                    
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5"  xl="4" class="mx-0  mt-0 py-4">
                        <v-row justify="end">
                            <iconButton :props="pdfBtnData" class="mr-2 mr-md-2 mb-3 mb-md-0">
                                <template slot="imgIcon">
                                    <img :src="pdfIcon" width="24" :class="pdfBtnData.disabled ? `filter-grey` : `filter-grey-darken-3`">
                                </template>
                            </iconButton>
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card">
                            <v-card-title class="body-1 font-weight-bold">
                                <v-col>
                                    <v-row class="primary">
                                        <v-col cols="12" lg="6" class="py-1 ">
                                           <v-row>
                                                <v-col class="text-lg-start text-center">
                                                    <span>TOTAL (MXN):</span>
                                                </v-col>
                                                <v-col class="text-lg-end text-center">
                                                    <span>{{mxnTotalReport}}</span>
                                                </v-col>
                                           </v-row>
                                        </v-col>
                                        <v-col cols="12" lg="6" class="py-1">
                                           <v-row>
                                                <v-col class="text-lg-start text-center">
                                                    <span>TOTAL (USD):</span>
                                                </v-col>
                                                <v-col class="text-lg-end text-center">
                                                    <span>{{usdTotalReport}}</span>
                                                </v-col>
                                           </v-row>
                                        </v-col>
                                       
                                      
                                    </v-row>
                                </v-col>
                            </v-card-title>
                            <v-card-text >
                                <datatable :options="options" :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import pdfIcon from '../../../assets/pdf_icon_2.svg';
import Utils from '../../../helpers/Utils';
import PageStateManager from '../../../helpers/PageStateManager';
export default {
    data(){
		return {
            pdfIcon: pdfIcon,
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
            mxnTotalReport: '$0.00',
            usdTotalReport: '$0.00',

            conceptLoading: false,
            concepts: [],
            conceptId: PageStateManager.getState(this.$router.history.current.path, 'conceptId')??'',

            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')??new Date().toISOString().split('T')[0]
            },
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						
						class: 'table-b-border black--text'
					},
					{
						text: 'Cliente / Proveedor',
						align: 'left',
						value: 'customer_supplier',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Movimiento',
						align: 'center',
						value: 'type',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Folio',
						align: 'center',
						value: 'folio_int',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'Referencia',
						align: 'center',
                        
						value: 'reference_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'right',
                        
						value: 'amount',
						class: 'table-b-border black--text'
					},
                    
				],
				items: [],
				search: '',
				loading: PageStateManager.getState(this.$router.history.current.path, 'conceptId') ? true : false,
                elevation: '0'
			},
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				block: false,
                loading: false,
                disabled:true,
                click: ()=>{this.export();}
            },

            pdfBtnData: {
                disabled:true,
                hasTooltip:true,
                tooltip: 'Exportar PDF',
                customIcon:true,
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-file-pdf-box",
                iconClass: "",
				to: "",
				block: false,
                loading: false,
                click: ()=>{this.pdf();}
            },

			
		}
	},

    watch: {
        [`conceptId`]() {
           this.watchConceptId();
        },
        [`dateRange.start_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'start_date', this.dateRange.start_date);
            
        },
        [`dateRange.end_date`]() {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'end_date', this.dateRange.end_date);
         
        }
    },

	mounted(){
		this.getConcepts();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.watchConceptId();
        this.index();
	},

    methods: {
        watchConceptId()
        {
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'conceptId', this.conceptId);
            this.pdfBtnData.disabled = true;
            this.exportBtnData.disabled = true;
            if(this.conceptId != "" && this.conceptId != null && this.conceptId != undefined){
                this.pdfBtnData.disabled = false;
                this.exportBtnData.disabled = false;
            }
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                this.pdfBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
                this.pdfBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
            if(this.conceptId == '' || this.conceptId == null || this.dateRange.start_date == '' || this.dateRange.end_date == '')
                return;

			this.tableProps.loading = true;
            this.mxnTotalReport = Utils.currencyFormat(0, 'MXN');
            this.usdTotalReport = Utils.currencyFormat(0, 'USD');
            this.tableProps.items = [];
			//call api
			this.$api.report.reportPaymentConcept(this.$store.state.business, {"concept_id":this.conceptId, "start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
				.then((resp) =>{
					//console.log(resp);
                   
                    this.mxnTotalReport = Utils.currencyFormat(resp.data.total_mxn, 'MXN');
                    this.subtotalReport = Utils.currencyFormat(resp.data.total_usd, 'USD');
					this.tableProps.items = resp.data.data.map((x) => {
						
						x.amount = Utils.currencyFormat(x.amount??0, x.currency);
                        x.date = Utils.globalDateFormat(x.date);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getConcepts()
        {
            this.conceptLoading = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.conceptLoading = false;
                })
            ;
        },

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.exportReportPaymentConcept(this.$store.state.business, {"concept_id":this.conceptId, "start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `payments_by_concept.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        pdf()
        {
            this.pdfBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.report.pdfReportPaymentConcept(this.$store.state.business, {"concept_id":this.conceptId, "start_date": this.dateRange.start_date, "end_date": this.dateRange.end_date})
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `payments_by_concept.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.pdfBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        }

    }
}
</script>