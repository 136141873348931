<template>
    <div>
       
    
        <v-row>
            <v-col>
                <table-items v-for="(item, index) in modules" :key="index" :props="item.props" @methodHandler="method_handler" :items="item.submodules">
                    
                   
                    <template v-slot:item.show="props">
                        <div class="table-item business-permission-table-check-input">
                            <v-checkbox
                                
                                v-model="values.menu[props.item.key]"
                            ></v-checkbox>
                        </div>
                    </template>
                    
                </table-items>
                
            </v-col>
        </v-row>


    </div>
</template>

<script>
import TableItems from '../../incomes/TableItems.vue';
import modules from './modules';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
    components: {
       'table-items': TableItems
    },
    computed: {
       
       
       
    },
    data(){
        return {
            modules:modules,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                 permissions: [
                    (value) =>
                    (value && value.length > 0) || "Debes agregar permisos al rol",
                ],
               
				
				
            },
          
        }
    },
   
    mounted(){
        
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
       
       
    }
}
</script>

<style lang="scss">
    .business-permission-table-check-input{
        display: flex;
        justify-content: center;
    }
</style>