<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalle de venta
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../components/DetailsItem.vue';
import Utils from "../../helpers/Utils";
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
           
            items: [
            ],
            
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                click: () => {this.backPage()}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.receivableAccount.get(this.$store.state.business, this.slug)
			.then((response) => {
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                this.items= [
                    { concepto: 'Clave', index: 'slug', valor: response.data.slug },
                    { concepto: 'Fecha', index: 'due_date', valor: Utils.globalDateFormat(response.data.due_date)},
                    { concepto: 'UUID', index: 'slug', valor: response.data.uuid },
                    { concepto: 'Folio', index: 'slug', valor: response.data.folio },
                    { concepto: 'Tipo de cuenta', index: 'slug', valor: response.data.type },
                    { concepto: 'Cliente', index: 'slug', valor: response.data.customer.name },
                    { concepto: 'Sub Cuenta', index: 'slug', valor: response.data.concept.name },
                    { concepto: 'Referencia', index: 'slug', valor: response.data.reference.name },
                    { concepto: 'Concepto', index: 'slug', valor: response.data.comments },
                    { concepto: 'Estatus', index: 'slug', valor: response.data.status },
                    { concepto: 'Periodo del crédito', index: 'slug', valor: `${response.data.credit_period} días` },
                    { concepto: 'Subtotal', index: 'slug', valor: Utils.currencyFormat(response.data.subtotal, '') },
                    { concepto: 'Total en MXN', index: 'slug', valor: Utils.currencyFormat(response.data.total_in_mxn, '') },
                    { concepto: 'Total en USD', index: 'slug', valor: Utils.currencyFormat(response.data.total_in_usd, '') },
                    { concepto: 'Descuentos', index: 'slug', valor: Utils.currencyFormat(response.data.discounts, '') },
                    { concepto: 'IEPS', index: 'slug', valor: Utils.currencyFormat(response.data.ieps, '') },
                    { concepto: 'IVA', index: 'slug', valor: Utils.currencyFormat(response.data.iva, '') },
                    { concepto: 'Retenciones ISR', index: 'slug', valor: Utils.currencyFormat(response.data.isr_withholdings, '') },
                    { concepto: 'Retenciones IVA', index: 'slug', valor: Utils.currencyFormat(response.data.iva_withholdings, '') },
                   
                ];

			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        backPage()
        {
            try {
                const last = this.$router.history.current.meta.prevRoute;

                if(last.name == 'ClientBalance')
                    this.$router.push({name: 'ClientBalance'});
                else
                    this.$router.push({name: 'CXC'});
            } catch (error) {
                this.$router.push({name: 'CXC'});
            }
          
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 