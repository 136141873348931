<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalle cuenta de gastos
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from "../../../helpers/Utils";
export default {
    components: {
        DetailsItem
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
            values: null,
            items: [
            ],
            
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                click: () => {this.backPage()}
            },
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.agentPayableAccount.get(this.$store.state.business, this.slug)
			.then((response) => {
                this.values = response.data;
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
                this.items= [
                    { concepto: 'Agente aduanal', index: 'slug', valor: response.data.customs_agent?.legal_name??"" },
                    { concepto: 'Fecha', index: 'due_date', valor: Utils.globalDateFormat(response.data.bill_date_emitted)},
                    { concepto: 'Trafico', index: 'slug', valor: response.data.traffic_number },
                    { concepto: 'Pedimento', index: 'slug', valor: response.data.pedimento },
                    { concepto: 'Clave', index: 'slug', valor: response.data.pedimento_key },
                    { concepto: 'Contenedor', index: 'slug', valor: response.data.container_number??"" },
                    { concepto: 'Folio factura', index: 'slug', valor: response.data.folio??"" },
                    { concepto: 'Se paga con aticipos', index: 'slug', valor: response.data.has_advances == 0 ? "No" : "Si" },
                    { concepto: 'Cliente', index: 'slug', valor: response.data.customer?.name??'' },
                    { concepto: 'Cuenta', index: 'slug', valor: response.data?.concept?.name??'' },
                    { concepto: 'Honorarios', index: 'slug', valor: Utils.currencyFormat(response.data.fee, response.data.currency??"") },
                    { concepto: 'IVA Honorarios', index: 'slug', valor: Utils.currencyFormat(response.data.iva_fee, response.data.currency??"") },
                    { concepto: 'Compensación', index: 'slug', valor: Utils.currencyFormat(response.data.considerations, response.data.currency??"") },
                    { concepto: 'IVA Compensación', index: 'slug', valor: Utils.currencyFormat(response.data.iva_considerations, response.data.currency??"") },
                    { concepto: 'Validación', index: 'slug', valor: Utils.currencyFormat(response.data.validation, response.data.currency??"") },
                    { concepto: 'IVA Validación', index: 'slug', valor: Utils.currencyFormat(response.data.iva_validation, response.data.currency??"") },
                    { concepto: 'Total honorarios', index: 'slug', valor: Utils.currencyFormat(this.totalTotalConsideration(response.data), response.data.currency??"") },
                    { concepto: 'Divisa', index: 'slug', valor: response.data.currency??"" },
                    { concepto: 'UUID', index: 'slug', valor: response.data.uuid??"" },
                    { concepto: 'Candado fiscal', index: 'slug', valor: Utils.currencyFormat(response.data.fiscal_padlock, response.data.currency??"") },
                    { concepto: 'Servicio extra', index: 'slug', valor: Utils.currencyFormat(response.data.extra_services, response.data.currency??"") },
                    { concepto: 'Reconocimiento aduanero', index: 'slug', valor: Utils.currencyFormat(response.data.customs_examination, response.data.currency??"") },
                    { concepto: 'Impuestos por servicios adicionales', index: 'slug', valor: Utils.currencyFormat(response.data.taxes_additional_services, response.data.currency??"") },
                    { concepto: 'Cuenta americana', index: 'slug', valor: response.data.american_account??"" },
                    { concepto: 'Impuestos pedimentos', index: 'slug', valor: Utils.currencyFormat(response.data.taxes_pedimento, response.data.currency??"") },
                    { concepto: 'Daños a contenedores', index: 'slug', valor: Utils.currencyFormat(response.data.damages_in_containers, response.data.currency??"") },
                    { concepto: 'Maniobras', index: 'slug', valor: Utils.currencyFormat(response.data.manoeuvres, response.data.currency??"") },
                    { concepto: 'IVA Maniobras', index: 'slug', valor: Utils.currencyFormat(response.data.iva_manoeuvres, response.data.currency??"") },
                    
                    
                   



                ];


                if(response.data.has_advances == 1){
                        
                    if(response.data.advance != null){
                        this.items.push(
                            { concepto: 'Anticipos', index: 'slug', valor: `${response.data.advance?.traffic_number??""} - $${response.data.advance.amount}` },
                    
                        )

                    }
                    if(response.data.compensation != null){
                        this.items.push(
                            { concepto: 'Aplicación de saldo a favor', index: 'slug', valor: `${response.data.compensation?.container_number??""} - $${parseFloat(response.data.compensation?.used_amount_as_compensation??"0", 4)}` }
                        
                        )
                    }
                }

                if(response.data.demora != null){
                    this.items.push(
                        { concepto: 'Demoras', index: 'slug', valor: `${response.data.demora?.traffic_number??""} - $${parseFloat(response.data.demora?.amount??"0", 4)}`},
                    );

                }
                if(response.data.warranty != null){
                    this.items.push(
                        { concepto: 'Garantías', index: 'slug', valor: `${response.data.warranty?.traffic_number??""} - $${parseFloat(response.data.warranty?.amount??"0", 4)}`},
                   
                    );
                }


                this.items.push(
                    { concepto: 'Monto de las garantías', index: 'slug', valor: Utils.currencyFormat(response.data.warranties??"0", response.data.currency??"") },
                    { concepto: 'Monto de las demoras', index: 'slug', valor: Utils.currencyFormat(response.data.demoras??"0", response.data.currency??"") },
                    { concepto: 'Total CG', index: 'slug', valor: Utils.currencyFormat(this.totalTotalCG(response.data), response.data.currency??"") },
                    { concepto: 'Total a pagar', index: 'slug', valor: Utils.currencyFormat(this.totalTotal(response.data), response.data.currency??"") },
                    { concepto: 'Días de crédito', index: 'slug', valor: `${response.data.credit_period} días` },
                    { concepto: 'Tipo de cambio', index: 'slug', valor: Utils.currencyFormat(response.data.current_mxn_per_usd??"0", response.data.currency??"") },
                    { concepto: 'Comentarios', index: 'slug', valor: `${response.data.comments}` },


                )

                //files

                if(response.data.files != null && response.data.files.length > 0){

                    response.data.files.forEach((fileItem, index) => {
                        this.items.push(
                            {concepto: `Adjunto ${index+1}`, index: 'vaucher', valor: fileItem.file_url, isFile:true}
                        );
                    });
                    
                }

              
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},


        totalTotalConsideration()
        {
            
            this.values.total_fee =  parseFloat((this.values.fee??"0")) + 
                                                parseFloat((this.values.validation??"0"))+
                                                parseFloat((this.values.considerations??"0"))+
                                                parseFloat((this.values.iva_fee??"0"))+
                                                parseFloat((this.values.iva_validation??"0"))+
                                                parseFloat((this.values.iva_considerations??"0"));
            //delete this.serverErrors.total;
            // console.log(this.values.total_fee, "FEEEEEEEEEEEEEEEEEEEE")
             if(isNaN(this.values.total_fee))
                this.values.total_fee = 0;
            if(this.values.total_fee < 0)
                this.values.total_fee = 0;
            this.values.total_fee = parseFloat(''+this.values.total_fee).toFixed(4);
            return this.values.total_fee;
        },


        totalTotalCG()
        {
            
            this.values.total_cg = parseFloat((this.values.fiscal_padlock??"0")) +
                                    parseFloat((this.values.extra_services??"0")) +
                                    parseFloat((this.values.customs_examination??"0")) +
                                    parseFloat((this.values.taxes_additional_services??"0")) +
                                    parseFloat((this.values.taxes_pedimento??"0")) +
                                    parseFloat((this.values.damages_in_containers??"0")) +
                                    parseFloat((this.values.manoeuvres??"0")) +
                                    parseFloat((this.values.iva_manoeuvres??"0")) +
                                    parseFloat((this.values.total_fee??"0")) -
                                    parseFloat((this.values.warranties??"0")) -
                                    parseFloat((this.values.demoras??"0")); 

            
            //console.log(this.values.total_cg, "aquiaquiauiauaiuaiua")
            if(isNaN(this.values.total_cg))
                this.values.total_cg = 0;
            if(this.values.total_cg < 0)
                this.values.total_cg = 0;
            this.values.total_cg = parseFloat(""+this.values.total_cg).toFixed(4);
            return this.values.total_cg;
        },

        totalTotal()
        {
            let advancesCompen = 0;
            let result = parseFloat((this.values.total_cg??"0")) - 0;
            if(this.values.has_advances){
                let advances = this.values.advance?.amount??0;
                let compensation = this.values.compensation?.used_amount_as_compensation??0;
                advances = isNaN(advances) ? 0 : advances;
                compensation = isNaN(compensation) ? 0 : compensation;

                advancesCompen = advances + compensation;
               // console.log(advancesCompen)
            }
            let finalResult =  result - advancesCompen;
            if(finalResult < 0)
                finalResult = 0;
            
            this.values.total = finalResult;
            this.values.total = parseFloat(""+this.values.total).toFixed(4);
            return this.values.total;
        },

        backPage()
        {
            
            this.$router.push({name: 'PayableAccounts'});
            
          
        }
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 