<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar Nota de crédito
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <ncincome-form ref="NCIncomeForm" :values="form" @save="save" :serverErrors="serverErrors"></ncincome-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../helpers/Utils';
import NCIncomeForm from "./Form.vue";
import Object2Form from '../../helpers/Object2Form';
export default {
    components: {
        'ncincome-form': NCIncomeForm,
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: true,
            serverErrors: {},
            form: {
                date: "",
                customer: "",
                folio: "",
                description: undefined,
                taxes: "",
                discounts:0,
                ieps:0,
                iva:0,
                iva_withholdings:0,
                isr_withholdings:0,
                subtotal: 0,
                total: 0,
                currency: "",
                
                uuid: "",
                credit_period: "",

                balanceable_id: "",

                customer_id: "",
                reference_id: "",
                concept_id: "",
                account_id: "",
                sub_account_id: "",
                //sub_sub_account_id: "",
                //aux_id: "",
                comments: "",
               
                
                taxes_items: [ ],
               

                invoice_file: [],
                invoice_file_url: "",
             
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "NCIncomes",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index()
        {
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.ncIncome.get(this.$store.state.business, this.slug)
                .then((resp) => {
                    this.form = resp.data;
                    this.form.customer_id = resp.data.customer.slug;
                    this.form.concept_id = resp.data.concept.slug;
                    this.form.reference_id = resp.data.reference.slug;
                    this.form.invoice_file = [];
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                //let form = Object2Form.objectToFormData(this.form, '', [])
                // let form = this.form;
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.ncIncome.update(this.$store.state.business, this.slug, this.prepareRequest(this.form))
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'NCIncomes'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                //console.log(99, error.response);
                                if(("error" in error.response.data)){
                                    Object.keys(error.response.data.error).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.error;
                                        error.response.data.error[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }

                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            
            data.invoice_file = request.invoice_file;
            


            if(request.invoice_file_url == '' && (request.invoice_file instanceof Array)){
                data.invoice_file = null;
                delete data.invoice_file_path;
            }
            if(request.invoice_file_url != '' && (request.invoice_file instanceof Array))
                delete data.invoice_file;

            console.log(data);
            return Object2Form.objectToFormData(data,'',[]);
        }
       
    },
}
</script>

<style>

</style>